import React from "react";
import { useTranslation } from "react-i18next";
import { Skeleton, Flex, Stack, Text, Icon } from "@chakra-ui/react";

import FunderTypeFilter from "components/Filters/FunderTypeFilter";
import YearFilter from "components/Filters/YearFilter";
import { filters } from "hooks/useFilter";
import useYearPeriodFilter from "hooks/useFilter/useYearPeriodFilter";
import { formatDate, yearMonthDay } from "utils/dateFormats";
import TableContainer from "components/Table/TableContainer";
import { useFiscalYear } from "hooks/useFiscalYear";

import { Cell, Table } from "components/_new/theme/Table";
import { Link } from "react-router-dom";
import { pages } from "routes/pages";
import { graphql } from "generated/graphql/v2";
import { useOrganizationId } from "hooks/_new/useOrganization";
import { useQuery } from "@apollo/client";
import MedalIcon from "settings/theme/ChakraTheme/icons/MedalIcon";
import { useSearchParam } from "react-use";
import { FunderTypeEnum } from "generated/graphql/v2/graphql";
import formatCurrency from "utils/formatCurrency";

const transpose = <T,>(table: (T | undefined)[][]) =>
  Array.from({ length: Math.max(...table.map((row) => row.length)) }, (_, i) =>
    table.map((row) => row[i]),
  );

const getLargestFunders = graphql(`
  query getLargestFunders(
    $organizationId: Int!
    $startAt: Date!
    $endAt: Date!
    $type: FunderTypeEnum
  ) {
    getOrganization(id: $organizationId) {
      id
      oldestAward {
        id
        receivementDate
      }
      programs {
        id
        name
      }
      largestFunders(startAt: $startAt, endAt: $endAt, type: $type) {
        overall {
          id
          name
          totalAwardAmount(startAt: $startAt, endAt: $endAt)
        }
        programsFunders {
          funders {
            funder {
              id
              name
            }
            awardedAmount
          }
          program {
            id
            name
          }
        }
      }
    }
  }
`);

const Component: React.FC = () => {
  const [t] = useTranslation();
  const { startAt: sa } = useYearPeriodFilter(filters.largestFunderYear);
  const { currentFiscalYearStart = "", currentFiscalYearEnd = "" } =
    useFiscalYear();
  const type =
    (useSearchParam("largestFunderType") as FunderTypeEnum) || undefined;

  //todo: maybe it makes sense to add the fiscal year filter to the backend?
  const dateToStart = new Date(sa ? `${sa} 00:00:00` : "");
  const cfyStartDate = new Date(currentFiscalYearStart);
  const cfyEndDate = new Date(currentFiscalYearEnd);
  const startingDate = new Date(
    dateToStart.getFullYear() - 1,
    cfyStartDate.getMonth(),
    cfyStartDate.getDate(),
  );
  const endingDate = new Date(
    dateToStart.getFullYear(),
    cfyEndDate.getMonth(),
    cfyEndDate.getDate(),
  );

  const startAt = formatDate(startingDate, yearMonthDay);
  const endAt = formatDate(endingDate, yearMonthDay);
  const organizationId = useOrganizationId() ?? 0;

  const { data, error } = useQuery(getLargestFunders, {
    variables: { startAt, endAt, organizationId, type },
  });
  if(error){
    if(error.message == 'unauthorized'){
      window.location.reload();
    }
  }

  const {
    oldestAward,
    largestFunders = {
      overall: [],
      programsFunders: [],
    },
  } = data?.getOrganization ?? {};

  return (
    <Skeleton
      isLoaded={Boolean(data?.getOrganization)}
      as={TableContainer}
      height="full"
      display="flex"
      flexDirection="column"
    >
      <Flex alignItems="center" justifyContent="space-between" marginBottom={8}>
        <Flex alignItems="center" width="full">
          <Text
            color="primary.200"
            fontFamily="heading"
            fontSize={{ base: "xs", md: "xl", lg: "xl" }}
            textTransform="uppercase"
          >
            {t("largest_funders.title")}
          </Text>
        </Flex>

        <Stack justify="flex-end" align="center" width="full" isInline>
          <FunderTypeFilter filter={filters.largestFunderType} />
          <YearFilter
            startDate={new Date(oldestAward?.receivementDate ?? new Date())}
            filter={filters.largestFunderYear}
          />
        </Stack>
      </Flex>

      {largestFunders.programsFunders && (
        <Table
          headers={largestFunders.programsFunders.map((p) => p.program.name)}
          data={transpose(
            largestFunders.programsFunders.map((pf) => pf.funders),
          )}
          rowMapper={(funders) => (
            <>
              {funders.map((fa, i) => (
                <Cell key={i}>
                  {fa && (
                    <Link to={pages.funderProfile.pathWithParams(fa.funder.id)}>
                      {fa.funder.name} ({formatCurrency(fa.awardedAmount)})
                    </Link>
                  )}
                </Cell>
              ))}
            </>
          )}
        />
      )}

      <Flex marginTop={4} flexDirection="column">
        <Text
          marginBottom={2}
          fontFamily="heading"
          fontSize={{ base: "xxs", md: "xs", lg: "xs" }}
        >
          {t("largest_funders.overall_ranking")}
        </Text>

        <Flex
          flexDir={{ base: "column", sm: "row" }}
          justify="space-between"
          flexWrap={{ base: "wrap", sm: "nowrap" }}
        >
          {largestFunders.overall.slice(0, 5).map((funder) => (
            <Flex
              direction="row"
              alignItems="center"
              gap={2}
              as={Link}
              key={funder.id}
              to={pages.funderProfile.pathWithParams(funder.id)}
            >
              <Icon as={MedalIcon} color="status.success" />
              <Text textColor="black" fontSize="14px">
                {funder.name} ({formatCurrency(funder.totalAwardAmount ?? 0)})
              </Text>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Skeleton>
  );
};

export default Component;

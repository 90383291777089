import React, { useCallback, useEffect, useMemo } from "react";
import { Box, Text } from "@chakra-ui/react";
import { ButtonMenu } from "react-rainbow-components";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";

import {
  PeriodsEnum,
  periodsSelectOptions,
  PeriodOptionProps,
} from "constants/periods";
import useFilter, { filters } from "hooks/useFilter";
import {
  formattedMonthOptions,
  MonthOptionProps,
} from "utils/formattedMonthOptions";
import useGetOrganization from "hooks/useGetOrganization";
import {
  useGetOrganizationFiscalYearQuery,
  useGetImpersonatedOrganizationFiscalYearQuery,
} from "generated/graphql";
import { displayMonthDayYear, formatDate, yearMask } from "utils/dateFormats";
import { useFiscalYear } from "hooks/useFiscalYear";
import fiscalYearRange from "utils/fiscalYearRange";
import { format, parseISO } from "date-fns";

import Label from "../Label";
import MenuItem from "./MenuItem";

const MonthSelector: React.FC = () => {
  const [t] = useTranslation();

  const [submissionMonth, setSubmissionMonth] = useFilter(
    filters.submissionMonth,
  );
  const [submissionYear, setSubmissionYear] = useFilter(filters.submissionYear);

  const { currentFiscalYearStart, currentFiscalYearEnd } = useFiscalYear();

  const monthsOptions = formattedMonthOptions(submissionYear as string);

  const [organization, { loading }] = useGetOrganization(
    useGetOrganizationFiscalYearQuery,
    useGetImpersonatedOrganizationFiscalYearQuery,
  );

  const handleSelect = useCallback(
    (data: MonthOptionProps | PeriodOptionProps) => () => {
      if (data.value === PeriodsEnum.All) {
        setSubmissionMonth(PeriodsEnum.All);

        setSubmissionYear(PeriodsEnum.All);

        return;
      }

      if (
        data.value === PeriodsEnum.Year &&
        submissionYear === PeriodsEnum.All
      ) {
        setSubmissionMonth(data.value);

        setSubmissionYear(organization?.currentFiscalYear);

        return;
      }

      const isYearDefined = !periodsSelectOptions.some(
        (period) => period.value === submissionYear,
      );

      if (!isYearDefined) {
        setSubmissionYear(organization?.currentFiscalYear);

        return;
      }

      setSubmissionMonth(data.value);

      setSubmissionYear(organization?.currentFiscalYear);
    },
    [submissionYear, setSubmissionYear, setSubmissionMonth, organization],
  );

  useEffect(() => {
    if (submissionMonth === PeriodsEnum.Year) {
      setSubmissionYear(organization?.currentFiscalYear);
    }
  }, [organization, submissionMonth, setSubmissionYear]);

  const selectedOptionFontFamily = useCallback(
    (value: string) => (submissionMonth === value ? "heading" : ""),
    [submissionMonth],
  );

  const fiscalPeriodStartDate = formatDate(
    currentFiscalYearStart,
    displayMonthDayYear,
  );

  const fiscalPeriodEndDate = formatDate(
    currentFiscalYearEnd,
    displayMonthDayYear,
  );

  const labelText = useMemo(() => {
    if (!organization) {
      return "";
    }

    const fiscalYearStart = formatDate(fiscalPeriodStartDate, yearMask);

    const fiscalYearEnd = formatDate(fiscalPeriodEndDate, yearMask);

    if (submissionMonth === PeriodsEnum.All) {
      return submissionMonth;
    }

    if (submissionMonth === PeriodsEnum.Year) {
      if (currentFiscalYearStart != undefined) {
        const { start, end } = fiscalYearRange(
          parseISO(currentFiscalYearStart),
          Number(submissionYear)
        );
        const yearStart = formatDate(start, yearMask);
        const yearEnd = formatDate(end, yearMask);

        return yearStart == yearEnd
          ? `${t("periods.year")} ${yearStart}`
          : `${t("periods.year")} ${yearStart} - ${yearEnd}`;
      }

      return fiscalYearStart === fiscalYearEnd
        ? `${t("periods.year")} ${fiscalYearStart}`
        : `${t("periods.year")} ${fiscalYearStart} - ${fiscalYearEnd}`;
    }

    return `${submissionMonth} ${submissionYear}`;
  }, [
    t,
    organization,
    submissionYear,
    submissionMonth,
    fiscalPeriodStartDate,
    fiscalPeriodEndDate,
  ]);

  const shouldRenderTooltip = submissionMonth === PeriodsEnum.Year;

  return (
    <>
      {shouldRenderTooltip && (
        <ReactTooltip place="top" type="dark" effect="float">
          <Text>{`${fiscalPeriodStartDate} - ${fiscalPeriodEndDate}`}</Text>
        </ReactTooltip>
      )}

      <Box as="a" data-tip>
        <ButtonMenu
          menuSize="x-small"
          isLoading={loading}
          buttonVariant="base"
          menuAlignment="center"
          label={<Label labelText={labelText} width="190px" />}
        >
          {periodsSelectOptions.map((periodOption) => (
            <MenuItem
              key={periodOption?.label}
              menuItem={periodOption}
              handleSelect={handleSelect}
              fontFamily={selectedOptionFontFamily(periodOption.value)}
            />
          ))}

          {monthsOptions.map((monthOption) => (
            <MenuItem
              key={monthOption?.label}
              menuItem={monthOption}
              handleSelect={handleSelect}
              fontFamily={selectedOptionFontFamily(monthOption.value)}
            />
          ))}
        </ButtonMenu>
      </Box>
    </>
  );
};

export default MonthSelector;

import i18n from "translations/i18n";
import elementIds from "views/Dashboard/elementIds";

import { BoardReportOption } from "./types";

const options: BoardReportOption[] = [
  {
    name: "fundraisngTracker",
    title: "Fundraising Tracker",
    elementId: elementIds.fundraisngTracker,
  },
  {
    name: "comparisonByYear",
    title: i18n.t("board_report.options.comparison_by_year"),
    elementId: elementIds.comparisonByYear,
  },
  {
    name: "fundingByType",
    title: i18n.t("board_report.options.funding_by_type"),
    elementId: elementIds.fundingByType,
  },
  {
    name: "grantsAwarded",
    title: "Grants Awarded",
    elementId: elementIds.grantsAwarded,
  },
  {
    name: "grantsPending",
    title: "Grants Pending",
    elementId: elementIds.grantsPending,
  },
  {
    name: "applicationSent",
    title: "Application Sent",
    elementId: elementIds.applicationSent,
  },
];

export default options;

import React, { useCallback } from "react";
import { Flex, ModalBody, ModalFooter, VStack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import { RadioGroup } from "react-rainbow-components";

import Button from "components/Button";

import deleteApplicationSchema, {
  DeleteApplicationSchema,
} from "settings/yup/schemas/deleteApplicationSchema";

import { useDeleteApplicationMutation } from "generated/graphql";
import { useToast } from "contexts/toast";
import capitalizeStringFirstLetter from "utils/capitalizeStringFirstLetter";

import { EditGrantFormProps } from "./types";
import { deleteOptions } from "../../constants/submitOptions";
import getErrorMessage from "../../utils/getErrorMessage";
import { ComfirmDeleteEnum } from "./types";

const DeleteGrantForm: React.FC<EditGrantFormProps> = ({
  hideModal,
  buttonLabel,
  grantData,
}) => {
  const grantId = grantData?.getApplication?.id;

  const { addToast } = useToast();

  const [t] = useTranslation();

  const { handleSubmit, errors, control } = useForm<DeleteApplicationSchema>({
    resolver: yupResolver(deleteApplicationSchema),
    mode: "onChange",
    defaultValues: {
      applicationId: grantId,
      comfirmDelete: ComfirmDeleteEnum.No,
    },
  });

  const [deleteApplication, { loading: deleteGrantLoading }] =
    useDeleteApplicationMutation({
      refetchQueries: ["GetApplications", "GetFunderProfile"],
    });

  const onSubmit = useCallback(
    (values: DeleteApplicationSchema) => {
      if (!grantId || !values) {
        return;
      }
      if (values?.comfirmDelete === ComfirmDeleteEnum.No) {
        hideModal();
        return;
      }

      deleteApplication({
        variables: {
          id: grantId,
        },
      })
        .then(() => {
          addToast({
            title: t("actions.application_has_been_deleted"),
            type: "success",
          });

          hideModal();
        })
        .catch((error) => {
          addToast({
            title: capitalizeStringFirstLetter(error.message),
            type: "error",
          });
        });
    },
    [t, grantId, addToast, hideModal, deleteApplication],
  );

  return (
    <>
      <ModalBody as="form" width="full">
        <VStack spacing={6}>
          <Flex
            width="full"
            color={"darkred"}
            flexDirection={"column"}
            fontWeight={"bold"}
            gap={"3"}
            marginTop={"2.5"}
          >
            <h4 style={{ fontSize: "18px" }}>
              {t("modals.delete_grant.are_you_sure_that_you_want")}
            </h4>
            <h6 style={{ fontSize: "16px" }}>
              <b>{t("modals.delete_grant.cannot_undo")}</b>
            </h6>
          </Flex>

          <Flex width="full">
            <Controller
              as={RadioGroup}
              control={control}
              name="comfirmDelete"
              label={""}
              error={getErrorMessage("comfirmDelete", errors)}
              orientation="horizontal"
              options={deleteOptions}
              labelAlignment="left"
            />
          </Flex>
        </VStack>
      </ModalBody>

      <ModalFooter width="full">
        <Button
          onClick={handleSubmit(onSubmit)}
          width="full"
          backgroundColor="secondary.200"
          isLoading={deleteGrantLoading}
        >
          {buttonLabel}
        </Button>
      </ModalFooter>
    </>
  );
};

export default DeleteGrantForm;

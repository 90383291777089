import { Stack } from "@chakra-ui/react";
import React from "react";

export const PageWrapper: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => (
  <Stack spacing={8} padding={8}>
    {children}
  </Stack>
);

import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";

import useWizardStore from "components/Wizard/store";
import Button from "components/Button";
import { useToast } from "contexts/toast";
import { CreateFunderSchema } from "settings/yup/schemas/createFunderSchema";

import { FormWizardFooterProps } from "../types";

const FormWizardFooter: React.FC<FormWizardFooterProps> = ({
  continueButtonDisabled,
  goBackButtonDisabled,
  submitButtonDisabled,
  showContinueButton,
  showGoBackButton,
  showSubmitButton,
  colorScheme = "primary",
  isLoading,
  onContinue,
  onSubmit,
}) => {
  const { addToast } = useToast();
  const [t] = useTranslation();

  const {
    navigateToPreviousStep,
    navigateToNextStep,
    getIsFirstStep,
    getIsLastStep,
    getIsLoaded,
  } = useWizardStore((store) => ({
    navigateToPreviousStep: store.navigateToPreviousStep,
    navigateToNextStep: store.navigateToNextStep,
    getIsFirstStep: store.getIsFirstStep,
    getIsLastStep: store.getIsLastStep,
    getIsLoaded: store.getIsLoaded,
    stepIndex: store.stepIndex,
  }));

  const { trigger } = useFormContext<CreateFunderSchema>();

  const handleGoToNextStep = useCallback(() => {
    onContinue?.();

    navigateToNextStep();
  }, [navigateToNextStep, onContinue]);

  const handleValidate = useCallback(
    (callback?: () => void) => {
      if (!trigger) {
        callback?.();

        return;
      }

      trigger()
        .then((isValid) => {
          if (!isValid) {
            addToast({
              type: "error",
              title: t("errors.please_fill_all_fields"),
            });

            return;
          }

          callback?.();
        })
        .catch(() => {
          addToast({
            type: "error",
            title: t("errors.please_fill_all_fields"),
          });
        });
    },
    [t, trigger, addToast],
  );

  const handleOnSubmit = useCallback(() => {
    handleValidate(onSubmit);
  }, [onSubmit, handleValidate]);

  const handleOnContinue = useCallback(() => {
    handleValidate(handleGoToNextStep);
  }, [handleGoToNextStep, handleValidate]);

  const handleGoBack = useCallback(() => {
    navigateToPreviousStep();
  }, [navigateToPreviousStep]);

  const isFirstStep = getIsFirstStep();
  const isLastStep = getIsLastStep();
  const isLoaded = getIsLoaded();

  const shouldShowContinueButton =
    showContinueButton && isLoaded && !isLastStep;

  const shouldShowGoBackButton = showGoBackButton && isLoaded && !isFirstStep;

  const shouldShowSubmitButton = showSubmitButton && isLoaded && isLastStep;

  return (
    <Stack justifyContent="center" alignItems="center" isInline w="full">
      {shouldShowGoBackButton && (
        <Button
          disabled={isLoading || goBackButtonDisabled}
          bgColor={`${colorScheme}.200`}
          onClick={handleGoBack}
          fontSize="sm"
        >
          {t("buttons.go_back")}
        </Button>
      )}

      {shouldShowContinueButton && (
        <Button
          disabled={continueButtonDisabled}
          bgColor={`${colorScheme}.200`}
          onClick={handleOnContinue}
          isLoading={isLoading}
          fontSize="sm"
        >
          {t("buttons.continue")}
        </Button>
      )}

      {shouldShowSubmitButton && (
        <Button
          disabled={submitButtonDisabled}
          bgColor={`${colorScheme}.200`}
          onClick={handleOnSubmit}
          isLoading={isLoading}
          fontSize="sm"
        >
          {t("buttons.submit")}
        </Button>
      )}
    </Stack>
  );
};

export default FormWizardFooter;

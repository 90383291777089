import React, { useCallback } from "react";
import { Flex, Stack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Picklist, RadioGroup, Option } from "react-rainbow-components";
import { Controller, useFormContext, useWatch } from "react-hook-form";

import { CreateGrantSchema } from "settings/yup/schemas/createGrantSchema";
import getErrorMessage from "utils/getErrorMessage";
import Button from "components/Button";
import { useModal } from "contexts/modal";
import CreateFunderForm from "components/CreateFunderForm";
import { CreateFunderMutation } from "generated/graphql";
import { useCreateProgramModal } from "modals/CreateProgram";
import CreateGrantForm from "components/CreateGrantForm";

import type { GrantFrequencyEnum } from "generated/graphql/v2/graphql";
import { graphql } from "generated/graphql/v2";
import { useOrganizationId } from "hooks/_new/useOrganization";
import { useQuery } from "@apollo/client";

const ListProgramAndFunderOptionsQuery = graphql(`
  query ListProgramAndFunderOptions($organizationId: Int!) {
    listFunders(filters: { organizationId: $organizationId }) {
      id
      name
    }

    listPrograms(organizationId: $organizationId) {
      id
      name
    }
  }
`);

const Funder: React.FC = () => {
  const [t] = useTranslation();

  const [showModal] = useModal();

  const organizationId = useOrganizationId();
  const { data } = useQuery(ListProgramAndFunderOptionsQuery, {
    variables: { organizationId },
  });

  const { errors, control } = useFormContext<CreateGrantSchema>();
  const frequency = useWatch<GrantFrequencyEnum>({ name: "frequency" });

  const openCreateNewGrantModal = useCallback(
    (selectedFunder: CreateFunderMutation["createFunder"]) => {
      showModal({
        title: t("create_grant.title"),
        component: CreateGrantForm,
        closeOnOverlayClick: false,
        componentProps: { selectedFunder },
      });
    },
    [t, showModal],
  );

  const handleCreateNewFunder = useCallback(() => {
    showModal({
      title: t("create_funder.title"),
      component: CreateFunderForm,
      closeOnOverlayClick: false,
      componentProps: {
        successCallback: openCreateNewGrantModal,
      },
    });
  }, [t, showModal, openCreateNewGrantModal]);

  const handleCreateNewProgram = useCreateProgramModal();

  return (
    <Stack
      as="form"
      spacing={6}
      marginBottom={8}
      paddingBottom={4}
      width="full"
    >
      <Flex flexDirection="column">
        <Controller
          required
          as={Picklist}
          name="funder"
          control={control}
          labelAlignment="left"
          error={getErrorMessage("funder", errors)}
          label={t("create_grant.steps.funder.funder")}
          placeholder={t("create_grant.steps.funder.select_a_funder")}
        >
          {data?.listFunders?.map((funder) => (
            <Option
              name={funder.id}
              value={funder.id}
              label={funder.name}
              key={funder.id}
            />
          ))}
        </Controller>

        <Button
          color="primary.100"
          _hover={{ color: "primary.100" }}
          _active={{ color: "primary.100" }}
          _focus={{
            color: "primary.100",
            boxShadow: "none",
          }}
          padding={0}
          margin={0}
          alignSelf="flex-end"
          fontSize="xxs"
          onClick={handleCreateNewFunder}
        >
          {t("buttons.add_new_funder")}
        </Button>
      </Flex>

      <Flex flexDirection="column">
        <Controller
          as={Picklist}
          control={control}
          name="program"
          error={getErrorMessage("program", errors)}
          label={t("create_grant.steps.funder.program_being_funded")}
          placeholder={t("create_grant.steps.funder.select_a_program")}
          labelAlignment="left"
          required
        >
          {data?.listPrograms?.map((program) => (
            <Option
              name={program.id}
              value={program.id}
              label={program.name}
              key={program.id}
            />
          ))}
        </Controller>

        <Button
          color="primary.100"
          _hover={{ color: "primary.100" }}
          _active={{ color: "primary.100" }}
          _focus={{
            color: "primary.100",
            boxShadow: "none",
          }}
          padding={0}
          margin={0}
          alignSelf="flex-end"
          fontSize="xxs"
          onClick={handleCreateNewProgram}
        >
          {t("buttons.add_new_program")}
        </Button>
      </Flex>

      <Controller
        as={RadioGroup}
        control={control}
        name="frequency"
        defaultValue={null}
        label={t("create_grant.steps.funder.grant_frequency")}
        error={getErrorMessage("frequency", errors)}
        orientation="horizontal"
        required
        options={[
          { value: "ONE_TIME", label: t("frequencies.one_time") },
          { value: "RECURRING", label: t("frequencies.recurring") },
        ]}
      />

      <Controller
        as={Picklist}
        control={control}
        name="recurrenceCount"
        label={t("create_grant.steps.funder.grant_recurrence")}
        labelAlignment="left"
        error={getErrorMessage("recurrenceCount", errors)}
        required={frequency === "RECURRING"}
        disabled={frequency !== "RECURRING"}
      >
        {[2, 3, 4, 5, 6, 7, 8, 9, 10].map((count) => (
          <Option
            name={String(count)}
            value={count}
            label={String(count)}
            key={count}
          />
        ))}
      </Controller>
    </Stack>
  );
};

export default Funder;

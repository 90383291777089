import { ComfirmDeleteEnum } from "components/DeleteGrantForm/types";
import yup from "settings/yup";

const deleteApplicationSchema = yup.object().shape(
  {
    applicationId: yup.number(),
    comfirmDelete: yup.string().required(),
  },
  [],
);

export interface DeleteApplicationSchema {
  applicationId: number;
  comfirmDelete: ComfirmDeleteEnum;
}

export default deleteApplicationSchema;

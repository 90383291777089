import { endOfDay } from "date-fns";
import { IsSubmittedEnum } from "components/EditGrantForm/types";
import { ApplicationStatusEnum, DeadlineTypeEnum, ReportUrgencyEnum } from "generated/graphql";
import yup from "settings/yup";
import type { Schema } from "yup";
import i18n from "translations/i18n";
import { PicklistProps } from "react-rainbow-components/components/Picklist";

import buildCurrencyTest from "../tests/currencyFieldTest";

const requiredPastDate = yup
  .date()
  .max(endOfDay(new Date()).toDateString(), i18n.t("yup.string_max"))
  .required();

const requiredCurrencyString = yup
  .string()
  .test(buildCurrencyTest())
  .required();

const reportSchema = yup.object().shape({
  urgency: yup.string().required(),
  deadline: yup.date().required(),
});

const picklistValue = <T>(type: Schema<T>) =>
  yup.object().shape({
    label: yup.string(),
    value: type,
  });

type PicklistValue<T> = {
  label: string,
  value: T;
};

const grantUpdateSchema = yup.object().shape(
  {
    deadline: yup.date().required(),
    isSubmitted: yup.string(),
    status: yup.string(),
    reports: yup.array().of(reportSchema),
    dateSubmitted: yup.date().when("isSubmitted", {
      is: (value) => value === IsSubmittedEnum.Yes,
      then: requiredPastDate,
      otherwise: yup.date(),
    }),
    amountRequested: yup.string().when("isSubmitted", {
      is: (value) => value === IsSubmittedEnum.Yes,
      then: requiredCurrencyString,
      otherwise: yup.string(),
    }),
    // https://github.com/orgs/Grantflow2/projects/1/views/1?pane=issue&itemId=60759301
    // receivementDate: yup.date().when("status", {
    //   is: (value) => value === ApplicationStatusEnum.Awarded,
    //   then: requiredPastDate,
    //   otherwise: yup.date(),
    // }),
    receivementDate: yup.date().when(["frequency", "applicationDeadline", "status"], {
      is: (frequency, applicationDeadline, status: ApplicationStatusEnum) => {
        if (frequency === "RECURRING" && applicationDeadline && status === "AWARDED") {
          return applicationDeadline.getFullYear() > (new Date()).getFullYear();
        }
        return false;
      },
      then: requiredPastDate,
      otherwise: yup.date(),
    }),
    amountAwarded: yup.string().when("status", {
      is: (value) => value === ApplicationStatusEnum.Awarded,
      then: requiredCurrencyString,
      otherwise: yup.string(),
    }),
    declinedReason: yup.string(),
    declinedDate: yup.date().when("status", {
      is: (value) => value === ApplicationStatusEnum.Declined,
      then: requiredPastDate,
      otherwise: yup.date(),
    }),
    program: picklistValue<number>(yup.number()),
    deadlineType: picklistValue<DeadlineTypeEnum>(yup.string()),
  },
  [["reportType", "reportDeadline"]],
);

interface ReportSchema {
  urgency: ReportUrgencyEnum;
  deadline: Date;
}

export interface GrantUpdateSchema {
  isSubmitted?: IsSubmittedEnum;
  dateSubmitted: Date;
  amountRequested: string;
  deadline: Date;
  status: string;
  receivementDate?: Date;
  amountAwarded: string;
  reports?: ReportSchema[];
  declinedDate?: Date;
  declinedReason: string;
  ineligibleReason?: string;
  program: PicklistValue<number>;
  deadlineType: PicklistValue<DeadlineTypeEnum>;
}

export default grantUpdateSchema;

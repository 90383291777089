import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

import updateFunderSchema, {
  defaultUpdateFunderValues,
  UpdateFunderSchema,
  UpdateFunderSchemaWSum,
} from "settings/yup/schemas/updateFunderSchema";
import { funderTypes } from "components/CreateFunderForm/Steps/Personal/options";
import formatUSPhoneNumber from "utils/formatUSPhoneNumber";
import { UpdateFunderRefetchQueries } from "graphql/mutations/updateFunderMutation";

import CardContainer from "../CardContainer";
import ContactInformationFields from "./ContactInformationFields";
import CardHeaderWithEdit from "../CardHeaderWithEdit";
import { FragmentType, graphql, useFragment } from "generated/graphql/v2";
import { useMutation } from "@apollo/client";
import { useToastWrapper } from "components/_new/hooks/useToastWrapper";

const FunderContactInformationFragment = graphql(`
  fragment FunderContactInformationFragment on Funder {
    id
    name
    email
    phone
    type
    website
    complexityScore
    keyContacts
  }
`);

const UpdateFunderContactInformationMutation = graphql(`
  mutation UpdateFunderMutation($id: Int!, $params: UpdateFunderParams!) {
    updateFunder(id: $id, params: $params) {
      ...FunderContactInformationFragment
    }
  }
`);

const FunderLocationInformationFragment = graphql(`
  fragment FunderLocationInformationFragment on Funder {
    location {
      id
      city
      complement
      state
      streetAddress
      zipcode
    }
  }
`);

const ContactInformationCard: React.FC<{
  funder: FragmentType<typeof FunderContactInformationFragment>;
  funderLocation:
  | FragmentType<typeof FunderLocationInformationFragment>
  | undefined
  | null;
}> = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [t] = useTranslation();

  const [updateFunder, { loading: isLoading }] = useMutation(
    UpdateFunderContactInformationMutation,
    { refetchQueries: UpdateFunderRefetchQueries },
  );

  const funderData = useFragment(
    FunderContactInformationFragment,
    props.funder,
  );

  const locationData = useFragment(
    FunderLocationInformationFragment,
    props.funderLocation,
  );

  const keyContactsCount = funderData?.keyContacts?.split(";").length;

  const { control, reset, handleSubmit, errors, formState } =
    useForm<UpdateFunderSchemaWSum>({
      resolver: yupResolver(updateFunderSchema),
      mode: "all",
      defaultValues: updateFunderSchema.cast(),
    });

  useEffect(() => {
    if (funderData) {

      reset({
        name: funderData?.name ?? defaultUpdateFunderValues?.name,
        email: funderData?.email ?? defaultUpdateFunderValues?.email,
        phone:
          formatUSPhoneNumber(funderData?.phone ?? undefined) ??
          defaultUpdateFunderValues?.phone,
        type: {
          value: funderData?.type ?? defaultUpdateFunderValues.type.value,
          name: funderData?.type ?? defaultUpdateFunderValues.type.name,
          label:
            funderTypes[funderData?.type].label ??
            defaultUpdateFunderValues?.type?.label,
        },
        website: funderData?.website ?? defaultUpdateFunderValues?.website,
        complexityScore: funderData?.complexityScore ?? defaultUpdateFunderValues?.complexityScore,
        keyContacts:
          funderData?.keyContacts ?? defaultUpdateFunderValues?.keyContacts,

        street_address: locationData?.location?.streetAddress ?? "",
        city: locationData?.location?.city ?? "",
        state: locationData?.location?.state ?? "",
        zipcode: locationData?.location?.zipcode ?? "",
        complement: "",
      });

    }
  }, [reset, funderData, locationData]);

  const submitWrapper = useToastWrapper(
    t("contact_information_card.funder_contact_information_have_been_updated"),
  );
  const onSubmit = useCallback(
    (values: UpdateFunderSchema) => {
      if (!funderData?.id || !formState.isDirty) {
        return;
      }
      
      const locationValues = { ...values } as unknown as Record<string, string>;

      submitWrapper(() =>
        updateFunder({
          variables: {
            id: funderData.id,
            params: {
              name: values.name,
              email: values.email,
              phone: values.phone,
              type: values.type.value,
              website: values.website,
              complexityScore: values.complexityScore?.trim()??"",
              keyContacts: values.keyContacts,
              location: {
                streetAddress: locationValues.street_address,
                city: locationValues.city,
                state: locationValues.state,
                zipcode: locationValues.zipcode.substring(0, 10),
                complement: "",
              },
            },
          },
        }),
      );
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
    [submitWrapper, funderData, locationData, updateFunder, formState.isDirty],
  );

  const isDisabled = !formState.isValid && isEditing && formState.isDirty;



  return (
    <CardContainer>
      <CardHeaderWithEdit
        isEditing={isEditing}
        handleSubmit={handleSubmit(onSubmit)}
        setIsEditing={setIsEditing}
        isDisabled={isDisabled}
        isLoading={isLoading}
        title={`${t("contact_information_card.contact_information")}:`}
      />

      <ContactInformationFields
        control={control}
        errors={errors}
        isEditing={isEditing}
        keyContactsCount={keyContactsCount}
        isLoaded={!!funderData}
        complexityScore={funderData?.complexityScore}
      />
    </CardContainer>
  );
};

export default ContactInformationCard;

const toastAnimation = {
  from: {
    transform: "translateX(120%)",
    opacity: 0,
    zIndex: 10000,
  },
  enter: {
    transform: "translateX(0%)",
    opacity: 1,
    zIndex: 10000,
  },
  leave: {
    transform: "translateX(120%)",
    opacity: 0,
    zIndex: 10000,
  },
};

export default toastAnimation;

import React from "react";
import { useTranslation } from "react-i18next";
import { Input } from "react-rainbow-components";
import { Controller, useFormContext } from "react-hook-form";

import { CreateFunderSchema } from "settings/yup/schemas/createFunderSchema";
import getErrorMessage from "utils/getErrorMessage";
import { SelectFunderType } from "components/_new/theme/inputs/SelectFunderType";
import { FormStepContainer } from "components/_new/theme/forms/FormStepContainer";
import MaskedInput from "components/FormComponents/MaskedInput";
import { PHONE_MASK } from "constants/masks";
import ExternalLinkInput from "components/CustomInputs/ExternalLinkInput";

const Personal: React.FC = () => {
  const [t] = useTranslation();
  const { errors, control } = useFormContext<CreateFunderSchema>();

  return (
    <FormStepContainer>
      <Controller
        as={Input}
        control={control}
        name="name"
        type="text"
        error={getErrorMessage("name", errors)}
        label={t("create_funder.steps.personal.name")}
        placeholder={t("create_funder.steps.personal.enter_funder_name")}
        labelAlignment="left"
        required
      />
      <Controller
        as={SelectFunderType}
        control={control}
        name="type"
        error={getErrorMessage("type", errors)}
        required
      />
      <Controller
        as={Input}
        control={control}
        name="keyContacts"
        label={t("contact_information_card.key_contact", {
          count: 0,
        })}
        error={getErrorMessage("keyContacts", errors)}
        labelAlignment="left"
      />
      <Controller
        as={Input}
        control={control}
        name="email"
        label={t("contact_information_card.email_address")}
        error={getErrorMessage("email", errors)}
        labelAlignment="left"
      />
      <Controller
        as={MaskedInput}
        name="phone"
        type="text"
        control={control}
        mask={PHONE_MASK}
        title={t("contact_information_card.phone_number")}
        errors={errors}
      />
      <Controller
        as={ExternalLinkInput}
        control={control}
        name="website"
        label={t("contact_information_card.website")}
        error={getErrorMessage("website", errors)}
        labelAlignment="left"
      />
      <Controller
        as={Input}
        control={control}
        name="complexityScore"
        label={t("contact_information_card.complexit_score")}
        error={getErrorMessage("complexityScore", errors)}
        labelAlignment="left"
        min={0}
        max={10}
        type="number"
      />
    </FormStepContainer>
  );
};

export default Personal;

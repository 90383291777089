import { Link } from "@chakra-ui/react";
import React from "react";

import ExternalLinkIcon from "settings/theme/ChakraTheme/icons/ExternalLinkIcon";
import formatUrl, { isPresent } from "utils/formatUrl";

import { ExternalLinkProps } from "./types";

const ExternalLink: React.FC<ExternalLinkProps> = ({ url, ...props }) => (
  <div className="external-link">
    {isPresent(url) ? (
      <Link href={formatUrl(url as string)} isExternal {...props}>
        <span>{url}</span>

        <ExternalLinkIcon />
      </Link>
    ) : null}
  </div>
);

export default ExternalLink;

import React, { useEffect, useMemo } from "react";
import { Divider, Flex, Stack } from "@chakra-ui/react";

import Progress from "components/Progress";

import useWizardStore, { INITIAL_STORE_STEP_INDEX } from "./store";
import WizardHeader from "./WizardHeader";
import { WizardProps } from "./types";

const Wizard: React.FC<WizardProps> = ({
  colorScheme = "secondary",
  initialStepIndex = 0,
  showDivider = false,
  steps,
}) => {
  const stepIndex = useWizardStore((store) => store.stepIndex);

  const step = useMemo(() => steps[stepIndex], [stepIndex, steps]);

  /**
   * Reset the store on component unmount
   */
  useEffect(
    () => () => {
      useWizardStore.setState({
        stepIndex: INITIAL_STORE_STEP_INDEX,
        steps: [],
      });
    },
    [],
  );

  useEffect(() => {
    if (stepIndex !== INITIAL_STORE_STEP_INDEX) {
      return;
    }

    useWizardStore.setState({
      stepIndex: initialStepIndex,
      steps,
    });
  });

  if (!step?.id && step?.id !== 0) {
    return null;
  }

  const progressValue = ((stepIndex + 1) * 100) / steps.length;

  const Component = step.component;

  return (
    <Stack spacing={8}>
      {steps.length > 1 && <Progress value={progressValue} />}

      <Flex justify="space-between" align="center" gap="4">
        <WizardHeader colorScheme={colorScheme} step={step} />
      </Flex>

      {showDivider && <Divider bgColor="gray.100" />}

      <Component />
    </Stack>
  );
};

export default Wizard;

import { ReactNode } from "react";

import { GetApplicationQuery } from "generated/graphql";

export interface EditGrantFormProps {
  buttonLabel: string | ReactNode;
  grantData?: GetApplicationQuery;
  hideModal: () => void;
  onClickDeletGrantBtn?: () => void;
}

export enum ComfirmDeleteEnum {
  Yes = "YES",
  No = "NO",
}

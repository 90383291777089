import { Link, LinkProps, Text } from "@chakra-ui/react";
import React from "react";

import ExternalLinkIcon from "settings/theme/ChakraTheme/icons/ExternalLinkIcon";
import formatUrl, { isPresent } from "utils/formatUrl";

const ExternalLink: React.FC<
  LinkProps & {
    url?: string;
  }
> = ({ url, ...props }) =>
  isPresent(url) ? (
    <Link href={formatUrl(url)} isExternal {...props}>
      <Text display="inline" marginRight={2}>
        {url}
      </Text>

      <ExternalLinkIcon />
    </Link>
  ) : null;

export default ExternalLink;

/**
 * Receives a string and returns it capitalized
 * if it receives anything than a string
 * returns undefined.
 * @param status status string
 */

export default function formatStatus(status: string): string {
  return status.charAt(0).toUpperCase() + status.toLowerCase().slice(1);
}

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-rainbow-components";

import CustomCard from "components/CustomCard";
import useSession from "hooks/useSession";
import { useToast } from "contexts/toast";
import { pages } from "routes/pages";
import { useQueryParam, StringParam } from "use-query-params";
import getReactAppBackendURL from "utils/env";

const backendUrl = getReactAppBackendURL(); //process?.env?.REACT_APP_BACKEND_URL;

const Verify: React.FC = () => {
  const [token, setToken] = useQueryParam("token", StringParam);
  const [successMessage, setSuccessMessage] = useQueryParam("success_message", StringParam);

  const [createSession] = useSession();
  const [t] = useTranslation();
  const { addToast } = useToast();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasRun, setHasRun] = useState<boolean>(false);

  const sendVerificationToken = async () => {
    setIsLoading(true);
    const xhr = new XMLHttpRequest();
    const endPoint = `/verify`;
    const link = backendUrl?.replace("/graphql", endPoint) ?? endPoint;
    xhr.open('POST', link, true);
    xhr.setRequestHeader("Content-type", "application/json; charset=UTF-8");
    xhr.responseType = 'json';
    xhr.onload = () => {
      setIsLoading(false);
      if (xhr.status >= 200 && xhr.status < 300) {
        const resp = typeof xhr.response == "string" ? JSON.parse(xhr.response) : xhr.response;
        if (resp.status === 500) {
          addToast({
            title: resp.error,
            type: "error",
          });
        } else {

          addToast({
            title: "Your email was successfuly verified. Welcome!",
            type: "success",
          });
          createSession(resp.data??"", pages.dashboard.pathWithParams());
        }
      } else {
        addToast({
          title: xhr.statusText,
          type: "error",
        });
      }
    };

    xhr.onerror = () => {
      const errorMessage = `Error sending token: ${xhr.statusText}`;
      addToast({
        title: errorMessage,
        type: "error",
      });
    };

    const dataToSend = JSON.stringify({
      token: token
    });

    xhr.send(dataToSend);
  }

  useEffect(() => {
    if (hasRun === false) {
      setHasRun(true);
      setTimeout(() => {
        sendVerificationToken();
      }, 1000);
    }
  }, [token]);

  return (
    <form className="login-wrapper">
      <CustomCard
        title={"Account Verification"}
        shouldAnimate
        shouldShowLogo
      >
        <Button
          style={{ width: "100%" }}
          label={t("login.login")}
          variant="brand"
          type="button"
          isLoading={isLoading}
          disabled={true}
        />
      </CustomCard>
    </form>
  );
};

export default Verify;

import React, { ErrorInfo } from "react";
import * as Sentry from "@sentry/browser";

import { ErrorBoundaryProps, State } from "./types";

class ErrorBoundary extends React.Component<ErrorBoundaryProps, State> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({
      hasError: true,
    });

    Sentry.withScope((scope) => {
      (Object.keys(errorInfo) as (keyof ErrorInfo)[]).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });
  }

  showReportDialog = (): void => {
    if (process?.env?.NODE_ENV === "production") {
      Sentry.showReportDialog();
    }
  };

  render(): React.ReactNode {
    if (this.state.hasError) {
      return <span onClick={this.showReportDialog}>Report feedback</span>;
    }

    // when there are no errors, render children untouched
    return this.props.children;
  }
}

export default ErrorBoundary;

import { ReactNode } from "react";
import { UseFieldArrayMethods, UseFormMethods } from "react-hook-form";
import { StackProps } from "@chakra-ui/react";

import { GetApplicationQuery } from "generated/graphql";

export interface EditGrantFormProps {
  buttonLabel: string | ReactNode;
  grantData?: GetApplicationQuery;
  hideModal: (success: boolean) => void;
  onClickDeletGrantBtn?: () => void;
  program?: { id: number, name: string }
}

export enum IsSubmittedEnum {
  Yes = "YES",
  No = "NO",
  Ineligible = "INELIGIBLE",
}

export interface EditGrantFormFieldsProps extends StackProps {
  control: UseFormMethods["control"];
  errors: UseFormMethods["errors"];
  register: UseFormMethods["register"];
  isSubmitted: IsSubmittedEnum | undefined;
  isAwarded: boolean;
  isDeclined: boolean;
  isIneligible?: boolean;
  shouldShowDeadlineField?: boolean;
  reportsFieldArray: UseFieldArrayMethods<Record<string, unknown>, "id">;
  ineligibleReason?: string;
  listPrograms?: Array<{ id: number, name: string }> | null | undefined;
}


import React from "react";
import { format } from "date-fns";
import {
  Box,
  Table,
  Tbody,
  Text,
  Thead,
  Tr,
  Td as Tdc,
  Th as Thc,
} from "@chakra-ui/react";

import formatCurrency from "utils/formatCurrency";

import { ListApplicationDeadlinesQuery } from "generated/graphql/v2/graphql";
import formatGrantFlowTableData from "utils/formatGrantFlowTableData";
import {
  FilterApplicationStatusEnum,
  ListApplicationsQuery,
} from "generated/graphql";

const Td = (props: Parameters<typeof Tdc>[0]) => (
  <Tdc
    paddingX="2px"
    paddingY="6px"
    borderWidth="1px"
    borderColor="#ddd"
    {...props}
  />
);
const Th = (props: Parameters<typeof Thc>[0]) => (
  <Thc
    paddingX="2px"
    paddingY="6px"
    borderWidth="1px"
    borderColor="#ddd"
    fontSize="11px"
    {...props}
  />
);


const PrintableDetails = (props: {
  title: string;
  grantStatus: FilterApplicationStatusEnum;
  data: ListApplicationsQuery,
  id?: string
}) => {

  const formattedTableData = formatGrantFlowTableData(
    props.data as ListApplicationDeadlinesQuery,
  );
  const sortKey =
    props.grantStatus === FilterApplicationStatusEnum.Awarded
      ? "dateReceived"
      : "applicationDeadline";
  const sortedTableData = formattedTableData
    .filter((d) => {
      if (props.grantStatus === FilterApplicationStatusEnum.Submitted) {
        return true;
      }
      return d.status === props.grantStatus;
    })
    .sort(
      (a, b) =>
        new Date(a[sortKey] as string).getTime() -
        new Date(b[sortKey] as string).getTime(),
    );

  return (
    <Box display="none" id={props.id} css={{ "@media print": { display: "block" } }}>
      <Text mt={4} w="full" fontSize="18px" textAlign="center">
        {props.title}
      </Text>

      <Table className="printable-detail-table">
        <Thead>
          <Tr>
            <Th>Program</Th>
            <Th>Funder</Th>
            <Th>Amount</Th>
            <Th>Date</Th>
          </Tr>
        </Thead>
        <Tbody>
          {sortedTableData.map((item) => {
            const rowItem: any = { ... item};
            let date = "";
            if(rowItem.status === FilterApplicationStatusEnum.Awarded){
              if(rowItem.award){
                date = rowItem.award.receivementDate;
              }else if(rowItem.dateReceived){
                date = rowItem.dateReceived;
              }
            }else if(rowItem.status === FilterApplicationStatusEnum.Declined){
              if(rowItem.declinedDetails){
                date = rowItem.declinedDetails?.declinedDate;
              }else if(rowItem.declinedDate){
                date = rowItem.declinedDate;
              }
            }
            if(date == ""){
              if(rowItem.submission){
                date = rowItem.submission.submissionDate;
              }else{
                date = rowItem.dateSubmitted;
              }
            }
            
            return (
              <Tr key={item.id}>
                <Td>{item.program}</Td>
                <Td>{item.funder}</Td>
                <Td>
                  {formatCurrency(
                    props.grantStatus === FilterApplicationStatusEnum.Awarded ? item?.amountAwarded ?? "0" : item?.amountRequested ?? "0"
                  )}
                </Td>
                <Td>{format(new Date(date), "P")}</Td>
              </Tr>
            )
          }
          )}
        </Tbody>
      </Table>
    </Box>
  );
};

export default PrintableDetails;

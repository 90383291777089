import React from "react";
import { Heading } from "@chakra-ui/react";

const asStyles = {
  h1: {
    fontSize: { base: "sm", md: "xl", lg: "xl" },
    textTransform: "uppercase",
    color: "primary.200",
  },
  h2: {
    fontSize: "xs",
    fontWeight: "medium",
  },
} as const;

export const Header: React.FC<{
  children: string;
  as: keyof typeof asStyles;
}> = ({ children, as }) => (
  <Heading as={as} {...asStyles[as]}>
    {children}
  </Heading>
);

import React, { useCallback, useMemo } from "react";
import { Box, Tooltip } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import ArrowUpIcon from "settings/theme/ChakraTheme/icons/ArrowUpIcon";
import { useModal } from "contexts/modal";
import UpdateGrantModal from "components/Modal/UpdateGrantModal";
import UpdateReportModal from "components/Modal/UpdateReportModal";
import DeleteGrantModal from "components/Modal/DeleteGrantModal";
import { TableTypeEnum } from "constants/types";
import type { Program } from "generated/graphql/v2/graphql";

const UpdateButton: React.FC<{
  id: number;
  type: "grant" | "report" | string;
  color?: string;
  Icon?: React.FC;
  label?: string;
  program: {id: number, name: string}
}> = ({ id, type, label, color = "primary.100", Icon = ArrowUpIcon, program }) => {
  const [t] = useTranslation();
  const [showModal] = useModal();

  const deleteModal = useMemo(() => {
    const currentModal = {
      title: t("modals.delete_grant.title"),
      component: DeleteGrantModal,
      componentProps: {
        buttonLabel: t("actions.delete_grant"),
        id,
        program: { id: 0, name: "" }
      },
    };
    return currentModal;
  }, [t, id]);


  const deleteReportModal = useMemo(() => {
    const currentModal = {
      title: t("modals.delete.title.delete_report"),
      component: DeleteGrantModal,
      componentProps: {
        buttonLabel: t("actions.delete_report"),
        id,
        program: { id: 0, name: "" }
      },
    };
    return currentModal;
  }, [t, id]);


  const updateModal = useMemo(() => {
    const currentModal = {
      title: t("modals.update_grant.update_grant"),
      component: UpdateGrantModal,
      componentProps: {
        buttonLabel: t("actions.update_information"),
        id,
        onClickDelete: () => {
          if (type === TableTypeEnum.Report) {
            showModal(deleteReportModal);
          }else{
            showModal(deleteModal);
          }
        },
        program: program
      },
    };

    if (type === TableTypeEnum.Report) {
      currentModal.component = UpdateReportModal;
      currentModal.title = t("modals.update_report.update_report");
    }

    return currentModal;
  }, [t, id, showModal, deleteModal, deleteReportModal, type]);

  const handleClick = useCallback(() => {
    showModal(updateModal);
  }, [showModal, updateModal]);

  return (
    <Tooltip fontSize={14} label={label ?? t("actions.update_information")}>
      <Box
        as="button"
        outline="none"
        width="32px"
        onClick={handleClick}
        _active={{
          transform: "scale(0.8)",
          transition: "transform 0.1s",
        }}
      >
        <Icon color={color} cursor="pointer" boxSize={4} />
      </Box>
    </Tooltip>
  );
};

export default UpdateButton;

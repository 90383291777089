import yup from "settings/yup";

const boardReportSchema = yup.object().shape({
  applicationSent: yup.boolean().required(),
  grantsAwarded: yup.boolean().required(),
  grantsPending: yup.boolean().required(),
  fundraisngTracker: yup.boolean().required(),
  comparisonByYear: yup.boolean().required(),
  fundingByType: yup.boolean().required(),
});

export interface BoardReportSchema {
  applicationSent: boolean;
  grantsAwarded: boolean;
  grantsPending: boolean;
  fundraisngTracker: boolean;
  comparisonByYear: boolean;
  fundingByType: boolean;
}

export default boardReportSchema;

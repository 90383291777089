import React from "react";
import { Flex, FlexProps } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Input } from "react-rainbow-components";

import LoupeIcon from "settings/theme/ChakraTheme/icons/LoupeIcon";

export const SearchInput: React.FC<
  {
    isDisabled?: boolean;
    search?: string;
    onChange: (search: string) => void;
    ref?: React.Ref<HTMLInputElement>;
  } & Omit<FlexProps, "onChange">
> = ({ isDisabled = false, search = "", onChange, ref, ...props }) => {
  const [t] = useTranslation();

  return (
    <Flex {...props}>
      <Input
        disabled={isDisabled}
        iconPosition="right"
        onChange={(e) => {
          e.preventDefault();
          onChange(e.currentTarget.value ?? "");
        }}
        value={search}
        placeholder={t("filters.search_filter.placeholder")}
        icon={<LoupeIcon color="gray" />}
        type="text"
        ref={ref}
        className="search-filter-container"
      />
    </Flex>
  );
};

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Flex, Stack } from "@chakra-ui/react";
import { ButtonIcon, Option, Picklist } from "react-rainbow-components";

import CloseIcon from "settings/theme/ChakraTheme/icons/CloseIcon";

import useAdminImpersonationStore from "./store";
import Loading from "components/Loading";
import { xhr } from "utils/xhr";
import { useCurrentUser } from "contexts/currentUser";
import getReactAppBackendURL from "utils/env";


const backendUrl = getReactAppBackendURL(); //process?.env?.REACT_APP_BACKEND_URL ?? "";

/**
 * Renders the selector for the managet to select a organization to impersonate.
 * Updates the store with the select result.
 */
const ManagerImpersonationSelect: React.FC = () => {
  const [user] = useCurrentUser();
  const [t] = useTranslation();
  const aiStore = useAdminImpersonationStore();
  const currentOrgId = aiStore.getOrganizationId(0);

  // const options = data.listOrganizations.map((org) => ({
  //   label: `${org.name} (${org.id})`,
  //   value: org,
  //   isDisabled: org.id === currentOrgId,
  // }));
  const empty: any[] = [];
  const [options, setOptions] = useState(empty);
  const [isLoading, setIsLoading] = useState(false);

  const loadOrganizations = () => {
    const endPoint = `/managers/${user?.id}`;
    const link = backendUrl?.replace("/graphql", endPoint) ?? endPoint;
    xhr({
      method: "GET",
      endPoint: link,
      onSuccess: (results: any) => {
        setIsLoading(false);

        const list = [... results.data];
        list.sort(function (a, b) {
          const aName = a.organization.name.toLowerCase();
          const bName = b.organization.name.toLowerCase();
          if (aName < bName) {
            return -1;
          }
          if (aName > bName) {
            return 1;
          }
          return 0;
        });

        const data = list.map((member: any) => ({
          label: `${member.organization.name} (${member.organization.id})`,
          value: member.organization,
          isDisabled: false, // member.organization.id === currentOrgId, for managers lets not disable anything
        }));
        setOptions(data);
      },
      onError: (error: any) => {
        setIsLoading(false);
        console.log("Error Loading managers organisations list");
        console.log(error);
      }
    });
  }

  const setOrganization = (option: any) => {
    const org = option?.value;
    if (org) {
      aiStore.setOrganization(org);
    }
  }

  useEffect(() => {
    loadOrganizations();
  }, []);

  return (
    <Flex alignItems="center">
      {isLoading && <Loading />}
      {!isLoading &&
        <Stack
          isInline
          w="320px"
          minWidth="320px"
          padding={3}
          width="full"
          alignItems="center"
        >
          <Picklist
            className="admin-impersonation-picklist"
            onChange={(option) => setOrganization(option)}
            value={options.find((option) => option.value.id === currentOrgId)}
            placeholder={t("admin_impersonation.view_as_organization")}
            variant="bare"
          >
            {options.map((option) => (
              <Option
                name={option.label}
                label={option.label}
                value={option.value}
                disabled={option.isDisabled}
                key={option.value.id}
              />
            ))}
          </Picklist>

          {currentOrgId && (
            <ButtonIcon
              tooltip={t("actions.clear_impersonation")}
              icon={<CloseIcon />}
              onClick={() => aiStore.setOrganization(undefined)}
            />
          )}
        </Stack>
      }
    </Flex>
  );
};

export default ManagerImpersonationSelect;

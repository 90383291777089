import React from "react";
import classNames from "classnames";
import { Card } from "react-rainbow-components";
import { Box, Stack, Text } from "@chakra-ui/react";

import ColorfulLogoIcon from "settings/theme/ChakraTheme/icons/ColorfulLogoIcon";

import { CustomCardProps } from "./types";

const CustomCard: React.FC<CustomCardProps> = ({
  title,
  subtitle,
  isLoading = false,
  children,
  className,
  shouldAnimate = false,
  shouldShowLogo = false,
  ...props
}) => {
  const cardStyles = classNames("custom-card", {
    className,
    slideIn: shouldAnimate,
  });

  return (
    <Box
      as="section"
      className="custom-card-wrapper"
      width="full"
      maxWidth="md"
    >
      <Stack className="custom-card-header">
        {shouldShowLogo && <ColorfulLogoIcon boxSize="130px" />}

        <Text as="h3">{title}</Text>

        {subtitle}
      </Stack>

      <Card isLoading={isLoading} className={cardStyles} {...props}>
        {children}
      </Card>
    </Box>
  );
};

export default CustomCard;

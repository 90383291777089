import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Chart, Dataset } from "react-rainbow-components";
import datalabels from "chartjs-plugin-datalabels";
import { LabelOptions } from "chartjs-plugin-datalabels/types/options";
import { Text } from "@chakra-ui/react";

import formatCurrency from "utils/formatCurrency";
import CardContainer from "components/Cards/CardContainer";
import CardHeader from "components/Cards/CardHeader";
import { getFloat } from "utils/getFloat";
import { FragmentType, graphql, useFragment } from "generated/graphql/v2";

const datalabelsConfigs: LabelOptions = {
  color: "black",
  anchor: "end",
  align: "right",
  offset: 0,
  padding: 2,
  formatter(value) {
    return `${formatCurrency(value)}`;
  },
};

const options = {
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        ticks: {
          maxTicksLimit: 4,
        },
        gridLines: {
          display: false,
        },
      },
    ],
  },
};

const ComparisonChartFragment = graphql(`
  fragment ComparisonChartFragment on AwardByYear {
    awarded
    year
  }
`);

const ComparisonByYear = (props: {
  awardsByYear: FragmentType<typeof ComparisonChartFragment>[];
  SelectYear: React.ReactElement;
}) => {
  const [t] = useTranslation();
  const awardsByYear = useFragment(ComparisonChartFragment, props.awardsByYear);

  const labels = awardsByYear.map((award) => `FY ${award.year}`);
  const values = awardsByYear.map(
    (award) => getFloat(award.awarded ?? "0") ?? 0,
  );

  return (
    <CardContainer height="full" display="flex" flexDirection="column">
      <CardHeader
        title={t("dashboard.get_awards_by_year.awards_comparison_by_year")}
      >
        <Text fontFamily="semibold" fontSize="xxs">
          {`${t("dashboard.get_awards_by_year.year")}:`}
        </Text>
        {props.SelectYear}
      </CardHeader>

      <Chart
        type="horizontalBar"
        plugins={[datalabels]}
        labels={labels}
        datalabels={datalabelsConfigs}
        disableXAxisGridLines
        disableYAxisGridLines
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        options={options}
      >
        <Dataset values={values} backgroundColor="#4949ff" />
      </Chart>
    </CardContainer>
  );
};

export default memo(ComparisonByYear);

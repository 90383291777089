import React, { useRef, useState, useEffect, useCallback } from "react";
import { VStack } from "@chakra-ui/react";
import { Flex, ModalBody, ModalFooter } from "@chakra-ui/react";
import Loading from "components/Loading";
import { Editor } from '@tinymce/tinymce-react';
import { CreateOrganizationModalProps } from "./types";
import { useCurrentUser } from "contexts/currentUser";
import getReactAppBackendURL from "utils/env";
import Checkbox from "components/FormComponents/Checkbox";
import {
  formatDate,
  displayMonthDayYear,
  yearMonthDay,
} from "utils/dateFormats";
import { useToast } from "contexts/toast";

import UserNameIcon from "settings/theme/ChakraTheme/icons/UserNameIcon";
import MailIcon from "settings/theme/ChakraTheme/icons/MailIcon";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { Input, Button, DatePicker, Textarea } from "react-rainbow-components";
import createOrganizationSchema, { CreateOrganizationSchema } from "settings/yup/schemas/createOrganizationSchema";
import createSupportSchema, { CreateSupportSchema } from "settings/yup/schemas/createSupportSchema";
import { useTranslation } from "react-i18next";
import getErrorMessage from "utils/getErrorMessage";

const backendUrl = getReactAppBackendURL();

const CreateSupportForm: React.FC<CreateOrganizationModalProps> = ({
  hideModal,
  componentProps: { },
}) => {
  const { addToast } = useToast();

  const [user] = useCurrentUser();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [t] = useTranslation();

  const { errors, handleSubmit, control } = useForm<CreateSupportSchema>({
    resolver: yupResolver(createSupportSchema),
    mode: "onBlur",
    defaultValues: {
      name: "",
      organization: "",
      email: "",
      message: ""
    },
  });

  const onSubmit = useCallback(
    (values) => {
      setIsLoading(true);
      const xhr = new XMLHttpRequest();
      const endPoint = `/support`;
      const link = backendUrl?.replace("/graphql", endPoint) ?? endPoint;
      xhr.open('POST', link, true);
      xhr.setRequestHeader("Content-type", "application/json; charset=UTF-8");
      xhr.responseType = 'json';

      xhr.onload = () => {
        setIsLoading(false);
        if (xhr.status >= 200 && xhr.status < 300) {
          const resp = typeof xhr.response == "string" ? JSON.parse(xhr.response) : xhr.response;
          if (resp.status === 500) {
            addToast({
              title: resp.error,
              type: "error",
            });
          } else {
            setTimeout(() => {
              window.location.reload();
            }, 1000);

            // display a sucess toast
            addToast({
              title: `Support ticket was sent succesfully`,
              type: "success",
            });

            hideModal()
          }
        } else {
          addToast({
            title: xhr.statusText,
            type: "error",
          });
        }
      };

      xhr.onerror = () => {
        setIsLoading(false);
        addToast({
          title: "Error submitting support ticket",
          type: "error",
        });
      };

      const dataToSend = JSON.stringify({
        name: values?.name??"",
        organization: values?.organization??"",
        email: values?.email??"",
        message: values?.message??"",
        user_id: user?.id ?? 0
      });

      xhr.send(dataToSend);

    },
    [t, addToast],
  );


  return (
    <VStack  >
      <ModalBody as="form" width="full" >
        <Flex flexDirection={"column"} gap={4}  >

          <p style={{ fontSize: "14px", paddingBottom: "20px" }}>
            If you have any questions or need additional support please reach out to our customer support team by filling out the form below:
          </p>

          <Controller
            as={Input}
            control={control}
            name="name"
            type="text"
            required
            icon={<UserNameIcon />}
            label={"Name"}
            placeholder={"Enter your name"}
            className="sign-up-input-field"
            error={getErrorMessage("name", errors)}
            iconPosition="right"
            labelAlignment="left"
          />

          <Controller
            as={Input}
            control={control}
            name="organization"
            type="text"
            required
            icon={<UserNameIcon />}
            label={"Organization"}
            placeholder={"Enter organization name"}
            className="sign-up-input-field"
            error={getErrorMessage("organization", errors)}
            iconPosition="right"
            labelAlignment="left"
          />

          <Controller
            as={Input}
            control={control}
            name="email"
            type="email"
            required
            icon={<MailIcon />}
            label={"Email"}
            placeholder={"Provide an email to receive feedback"}
            className="sign-up-input-field"
            error={getErrorMessage("email", errors)}
            iconPosition="right"
            labelAlignment="left"
          />

          <Controller
            as={Textarea}
            control={control}
            name="message"
            required
            label={"Message"}
            placeholder={"Provide details of the issue you are facing"}
            className="sign-up-input-field"
            error={getErrorMessage("message", errors)}
            labelAlignment="left"
          />

          <div className="sign-up-actions-container" style={{ width: "100%", marginTop: "30px" }}>
            <Button
              label={"Submit"}
              variant="brand"
              type="submit"
              onClick={handleSubmit(onSubmit)}
              isLoading={isLoading}
              style={{ width: "100%" }}
            />
          </div>
        </Flex>
      </ModalBody>
    </VStack>
  );
};

export default CreateSupportForm;

import { useEffect } from "react";
import { UseFormMethods } from "react-hook-form";

import { BoardReportSchema } from "settings/yup/schemas/boardReportSchema";

import options from "../options";

const cssClass = "hide-on-print-selected";

/**
 * Handles the update function for a given board report field.
 * @param name The field name.
 * @param isChecked The field value.
 */
const useUpdateHandler = (name: string, isChecked: boolean): void => {
  useEffect(() => {
    const option = options.find((item) => item.name === name);

    const element =
      option && document.getElementById(`for-print-${option.elementId}`);

    if (!element) {
      return;
    }

    if (isChecked) {
      element.classList.remove(cssClass);
    } else {
      element.classList.add(cssClass);
    }
  }, [isChecked, name]);
};

/**
 * Validates the checkboxes at the board report modal, watching for changes.
 *
 * When one checkbox changes, this hook will automatically inject a css class
 * into the respective element to hide or show it during the print dialog,
 * according to its state.
 *
 * @param form The board report form.
 */
const useBoardReportValidation = (
  form: UseFormMethods<BoardReportSchema>,
): void => {
  const formState = form.watch();

  useUpdateHandler("fundraisngTracker", formState.fundraisngTracker);
  useUpdateHandler("comparisonByYear", formState.comparisonByYear);
  useUpdateHandler("fundingByType", formState.fundingByType);
  useUpdateHandler("grantsAwarded", formState.grantsAwarded);
  useUpdateHandler("grantsPending", formState.grantsPending);
  useUpdateHandler("applicationSent", formState.applicationSent);
};

export default useBoardReportValidation;

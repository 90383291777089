import yup from "settings/yup";

const createOrganizationSchema = yup.object().shape({
  organizationName: yup.string().required(),
  fiscalYearStart: yup.date().required(),
});

export interface CreateOrganizationSchema {
  organizationName: string;
  fiscalYearStart: Date;
}

export default createOrganizationSchema;

import React, { useCallback } from "react";
import { ModalBody, ModalFooter, Flex, VStack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  DatePicker,
  Picklist,
  RadioGroup,
  Option,
} from "react-rainbow-components";

import Button from "components/Button";
import getErrorMessage from "utils/getErrorMessage";
import {
  ReportUrgencyEnum,
  UpdateReportParams,
  useUpdateReportMutation,
} from "generated/graphql";
import {
  formatDate,
  displayMonthDayYear,
  yearMonthDay,
} from "utils/dateFormats";
import { useToast } from "contexts/toast";
import reportUpdateSchema, {
  ReportUpdateSchema,
} from "settings/yup/schemas/reportUpdateSchema";
import { updateReportRefetchQueries } from "graphql/mutations/updateReportMutation";
import { submitOptions } from "constants/submitOptions";
import { urgencies, urgenciesSelectOptions } from "constants/urgenciesOptions";
import capitalizeStringFirstLetter from "utils/capitalizeStringFirstLetter";

import { EditGrantFormProps, IsSubmittedEnum } from "./types";

const EditReportForm: React.FC<EditGrantFormProps> = ({
  hideModal,
  buttonLabel,
  reportData,
  onClickDeletReportBtn
}) => {
  const reportId = reportData?.getReport?.id;

  const initialFormValues = reportData?.getReport;
  const { urgency = ReportUrgencyEnum.Courtesy } = initialFormValues || {};

  const { addToast } = useToast();

  const [t] = useTranslation();

  const { errors, handleSubmit, control, watch } = useForm<ReportUpdateSchema>({
    resolver: yupResolver(reportUpdateSchema),
    mode: "onChange",
    defaultValues: {
      isSubmitted: initialFormValues?.submitted
        ? IsSubmittedEnum.Yes
        : IsSubmittedEnum.No,
      urgency: urgencies[urgency],
      deadline: formatDate(initialFormValues?.deadline, displayMonthDayYear),
      submissionDate:
        initialFormValues?.submissionDate &&
        formatDate(initialFormValues?.submissionDate, displayMonthDayYear),
    },
  });

  const [updateReport, { loading: updateReportLoading }] =
    useUpdateReportMutation({
      refetchQueries: updateReportRefetchQueries,
      awaitRefetchQueries: true,
    });

  const onSubmit = useCallback(
    (values: ReportUpdateSchema) => {
      if (!reportId || !values?.urgency?.value) {
        return;
      }

      const params: UpdateReportParams = {
        urgency: values?.urgency?.value,
        deadline: formatDate(values?.deadline, yearMonthDay),
      };

      if (values?.submissionDate) {
        params.submissionDate = formatDate(
          values?.submissionDate,
          yearMonthDay,
        );
      }

      updateReport({
        variables: {
          id: reportId,
          params,
        },
      })
        .then(() => {
          addToast({
            title: t("actions.report_has_been_updated"),
            type: "success",
          });

          hideModal();
        })
        .catch((error) =>
          addToast({
            title: capitalizeStringFirstLetter(error.message),
            type: "error",
          }),
        );
    },
    [t, reportId, addToast, hideModal, updateReport],
  );

  const isSubmitted = watch("isSubmitted") === IsSubmittedEnum.Yes;

  return (
    <>
      <ModalBody as="form" width="full">
        <VStack spacing={6}>

          <Flex width="full" flexDirection="column" alignItems="end"  >
            <Button
              onClick={onClickDeletReportBtn}
              backgroundColor={"transparent"}
              color="primary.100"
              _hover={{ color: "primary.80" }}
              _active={{ color: "primary.100" }}
              _focus={{ color: "primary.100" }}
              isLoading={updateReportLoading}
              border={"2px"}
              borderRadius={"full"}
              width="fit-content"
            >
              {t("modals.delete.title.delete_report")}
            </Button>
          </Flex>

          <Flex width="full" flexDirection="column">
            <Controller
              as={Picklist}
              control={control}
              name="urgency"
              label={t("modals.update_report.report_urgency")}
              error={getErrorMessage("urgency", errors)}
              required
              labelAlignment="left"
            >
              {urgenciesSelectOptions.map((option) => (
                <Option
                  name={option.value}
                  value={option.value}
                  label={option.label}
                  key={option.label}
                />
              ))}
            </Controller>
          </Flex>

          <Controller
            as={DatePicker}
            control={control}
            name="deadline"
            label={t("modals.update_report.report_deadline")}
            error={getErrorMessage("deadline", errors)}
            required
            labelAlignment="left"
          />

          <Flex width="full" flexDirection="column">
            <Controller
              as={RadioGroup}
              control={control}
              name="isSubmitted"
              label={t("modals.update_report.report_submitted")}
              error={getErrorMessage("isSubmitted", errors)}
              orientation="horizontal"
              options={submitOptions}
              labelAlignment="left"
            />
          </Flex>

          {isSubmitted && (
            <Flex width="full" flexDirection="column">
              <Controller
                as={DatePicker}
                control={control}
                name="submissionDate"
                label={t("modals.update_report.date_submitted")}
                error={getErrorMessage("submissionDate", errors)}
                required
                labelAlignment="left"
              />
            </Flex>
          )}
        </VStack>
      </ModalBody>

      <ModalFooter width="full">
        <Button
          onClick={handleSubmit(onSubmit)}
          width="full"
          backgroundColor="secondary.200"
          isLoading={updateReportLoading}
        >
          {buttonLabel}
        </Button>
      </ModalFooter>
    </>
  );
};

export default EditReportForm;

import React from "react";
import { useTranslation } from "react-i18next";
import { Flex, Stack } from "@chakra-ui/react";
import { ButtonIcon, Option, Picklist } from "react-rainbow-components";

import CloseIcon from "settings/theme/ChakraTheme/icons/CloseIcon";

import useAdminImpersonationStore from "./store";
import { graphql } from "generated/graphql/v2";
import { useQuery } from "@apollo/client";
import Loading from "components/Loading";

const ListImpersonableOrganizations = graphql(`
  query ListImpersonableOrganizations {
    listOrganizations {
      id
      name
      ...AdminImpersonationFragment
    }
  }
`);

/**
 * Renders the selector for the admin to select a organization to impersonate.
 * Updates the store with the select result.
 */
const AdminImpersonationSelect: React.FC = () => {
  const [t] = useTranslation();
  const { data } = useQuery(ListImpersonableOrganizations);
  const aiStore = useAdminImpersonationStore();

  const currentOrgId = aiStore.getOrganizationId(0);

  if (!data?.listOrganizations) {
    return <Loading />;
  }

  const list = [... data.listOrganizations];
  list.sort(function (a, b) {
    const aName = a.name.toLowerCase();
    const bName = b.name.toLowerCase();
    if (aName < bName) {
      return -1;
    }
    if (aName > bName) {
      return 1;
    }
    return 0;
  });

  const options = list.map((org) => ({
    label: `${org.name} (${org.id})`,
    value: org,
    isDisabled: org.id === currentOrgId,
  }));
  

  return (
    <Flex alignItems="center">
      <Stack
        isInline
        w="320px"
        minWidth="320px"
        padding={3}
        width="full"
        alignItems="center"
      >
        <Picklist
          className="admin-impersonation-picklist"
          onChange={(option) => aiStore.setOrganization(option?.value)}
          value={options.find((option) => option.value.id === currentOrgId)}
          placeholder={t("admin_impersonation.view_as_organization")}
          variant="bare"
        >
          {options.map((option) => (
            <Option
              name={option.label}
              label={option.label}
              value={option.value}
              disabled={option.isDisabled}
              key={option.value.id}
            />
          ))}
        </Picklist>

        {currentOrgId && (
          <ButtonIcon
            tooltip={t("actions.clear_impersonation")}
            icon={<CloseIcon />}
            onClick={() => aiStore.setOrganization(undefined)}
          />
        )}
      </Stack>
    </Flex>
  );
};

export default AdminImpersonationSelect;

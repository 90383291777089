import * as React from "react";

import { ToastContextData } from "./types";

const ToastContext = React.createContext<ToastContextData>(
  {} as ToastContextData,
);

export const ToastProvider = ToastContext.Provider;

/**
 * Exposes two functions that handles Toasts visibility
 */
export function useToast(): ToastContextData {
  const context = React.useContext(ToastContext);

  if (!context) {
    throw new Error("useToast must be used within an ToastProvider");
  }

  return context;
}

import { Flex, FlexProps } from "@chakra-ui/react";
import React from "react";

import LogoIcon from "settings/theme/ChakraTheme/icons/LogoIcon";

const PrintableHeader: React.FC<FlexProps> = ({ ...props }) => (
  <Flex
    className="show-on-print"
    css={{
      maxHeight: "60px",
      marginBottom: "16px",
    }}
    {...props}
  >
    <LogoIcon boxSize="60px" color="black" />
  </Flex>
);

export default PrintableHeader;

import React from "react";
import { Stack, StackProps, Text } from "@chakra-ui/react";
import { ResponsiveValue } from "@chakra-ui/system";
import type { Property } from "csstype";

type TitleSize = "sm" | "md" | "lg";

export const titleFontSize = (size: TitleSize): ResponsiveValue<Property.FontSize> => {
  switch (size) {
    case "sm":
      return { base: "xx-small", md: "md", lg: "md" };
    case "md":
      return { base: "xs", md: "lg", lg: "lg" };
    case "lg":
    default:
      return { base: "sm", md: "xl", lg: "xl" };
  }
};

const TableHeader: React.FC<
  StackProps & {
    title: string;
    titleSize?: TitleSize;
  }
> = ({ title, titleSize = "lg", children, ...rest }) => (
  <Stack
    width="full"
    display="flex"
    flexDirection={{ base: "column", sm: "row" }}
    justifyContent="space-between"
    alignItems="center"
    marginBottom={8}
    {...rest}
  >
    <Text
      color="primary.200"
      fontFamily="heading"
      fontSize={titleFontSize(titleSize)}
      textTransform="uppercase"
    >
      {title}
    </Text>

    {children}
  </Stack>
);

export default TableHeader;

import * as React from "react";
import { IconProps } from "@chakra-ui/icons";
import { Icon } from "@chakra-ui/react";

const ColorfulLogoIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} viewBox="0 0 780 500">
    <path
      d="M66.83 400.263H110.64V425.963H26.82V352.883H110.64V360.933H136.79C136.79 349.733 134.89 342.169 131.09 338.243C127.29 334.316 119.653 332.339 108.18 332.313H28.61C17.73 332.313 10.2433 334.176 6.15 337.903C2.05667 341.629 0.00666667 348.856 0 359.583V420.383C0 431.103 2.05 438.329 6.15 442.063C10.25 445.796 17.7367 447.659 28.61 447.653H108.18C119.06 447.653 126.55 445.789 130.65 442.063C134.75 438.336 136.797 431.109 136.79 420.383V380.593H66.79L66.83 400.263Z"
      fill="#35ADE2"
    />
    <path
      d="M304.39 447.653H265.92L219.39 404.063H193.95V447.653H167.58V332.313H266.39C283.67 332.313 292.313 339.763 292.32 354.663V381.663C292.32 392.829 287.85 399.759 278.91 402.453C275.483 403.493 266.243 404.013 251.19 404.013L304.39 447.653ZM266.39 375.233V361.373C266.39 357.503 265.27 354.963 263.04 353.773C261.4 352.733 258.493 352.213 254.32 352.213H193.95V384.393H254.3C258.467 384.393 261.373 383.873 263.02 382.833C265.25 381.643 266.39 379.103 266.39 375.233V375.233Z"
      fill="#35ADE2"
    />
    <path
      d="M473.79 447.653H443.62L429.76 422.393H356.67L343.26 447.653H316.44L381.7 332.313H407.63L473.79 447.653ZM418.58 401.653L393.77 354.713L368.07 401.653H418.58Z"
      fill="#35ADE2"
    />
    <path
      d="M629.02 447.653H610.69L525.98 376.353C522.524 373.492 519.31 370.352 516.37 366.963C517.11 374.416 517.48 380.299 517.48 384.613V447.613H493.39V332.313H511.71L592.85 400.263C597.529 404.125 601.924 408.319 606 412.813C605.253 402.079 604.88 395.446 604.88 392.913V332.313H629.02V447.653Z"
      fill="#35ADE2"
    />
    <path
      d="M788.14 352.883H734.94V447.653H708.12V352.883H655.12V332.313H788.12L788.14 352.883Z"
      fill="#35ADE2"
    />
    <path
      d="M332.1 47.8126C321.88 46.6926 311.3 47.9326 302.35 53.3126C296.262 56.9913 290.98 61.8621 286.82 67.6326C275.87 84.0326 277.25 106.253 288 122.373C289.24 92.8126 302.54 59.3126 332.1 47.8126Z"
      fill="#0071BC"
    />
    <path
      d="M385.02 65.9929C386.88 66.4729 388.71 66.9929 390.54 67.6129C388.75 66.2229 386.94 64.8329 385.08 63.6129C372.19 55.6829 356.08 52.1229 341.33 56.4829C322.38 61.9429 308.33 78.4829 302.6 96.9129C295.23 119.273 299.97 144.803 314.12 163.493C308.42 138.843 309.55 110.993 324.93 89.9029C337.76 70.7329 362.44 60.1529 385.02 65.9929Z"
      fill="#1D80C2"
    />
    <path
      d="M466.3 175.623C458.691 188.655 446.559 198.438 432.21 203.113C420.73 206.963 408.6 205.343 397.07 202.773C400.34 203.503 404.35 207.503 407.46 209.063C411.29 210.999 415.312 212.531 419.46 213.633C427.852 215.861 436.664 215.975 445.11 213.963C464.32 209.353 479.11 192.643 485.25 174.333C493.15 150.813 487.61 124.583 473.25 104.803C477.06 120.983 479.33 138.003 474.8 154.333C473.057 161.817 470.191 168.995 466.3 175.623V175.623Z"
      fill="#2E3192"
    />
    <path
      d="M330.07 214.973C321.67 206.413 312.07 199.123 303.8 190.403C288.787 175.172 276.597 157.396 267.8 137.903C263.8 129.903 261.34 121.273 257.38 113.293C256 126.443 256.68 140.003 261.44 152.453C273.12 182.753 302.14 201.293 330.07 214.973Z"
      fill="#0071BC"
    />
    <path
      d="M528.63 122.303C519.34 89.5728 488.36 69.8728 459.31 55.9028C470.46 67.2328 482.95 77.2228 493.21 89.4628C505.382 103.564 515.177 119.554 522.21 136.803C525.21 143.233 527.21 150.073 530.64 156.293C531.97 144.933 530.89 133.473 528.58 122.293L528.63 122.303Z"
      fill="#0071BC"
    />
    <path
      d="M518.39 145.023C510.47 109.273 483.88 82.4028 456.13 60.1328C447.43 53.1328 437.99 47.2228 428.91 40.7928C440.5 42.1228 452.06 44.0828 463.14 47.7928C480.89 54.6528 496.52 65.9928 509.27 80.0228C518.27 91.0228 525.32 103.343 531.66 116.023L530.92 113.173C528.201 103.748 524.678 94.5747 520.39 85.7528C518.85 82.8028 517.25 79.9128 515.69 77.0128C502.27 52.0128 480.07 29.7528 451.62 23.3728C447.995 22.4952 444.322 21.8274 440.62 21.3728C418.44 17.8828 395.62 23.4428 373.67 17.6928C383.355 15.3625 393.151 13.5202 403.02 12.1728C418.39 11.2828 434.02 11.7728 449.02 15.2628C458.92 17.3928 468.17 21.7128 477.91 24.5128C474.58 22.2128 471.14 20.0928 467.69 18.0228C467.56 17.6228 467.81 18.4228 467.69 18.0228C448.12 9.3228 426.96 -0.537198 405.14 0.0228019C392.73 0.192802 379.81 0.0828035 367.55 2.4228C340.2 7.6328 315.13 22.4228 295.85 42.3228C304.07 38.9928 311.71 34.2128 320.35 31.9428C347.17 23.4328 376.42 27.9428 401.68 39.2928C410.8 44.4128 419.4 50.3728 428.5 55.5228L426.16 55.8328C411.41 49.2328 397.07 41.3828 381.28 37.4128C364.64 32.3228 346.88 30.1728 329.62 33.1828C328.62 33.3328 326.62 33.6428 325.62 33.8128C314.01 36.8128 302.49 41.4628 293.36 49.4328C295.49 48.6228 297.62 47.8528 299.77 47.0928C314.95 41.4028 331.39 40.4728 347.39 41.5728C382.48 43.9728 420.08 65.3828 432.33 99.7728C435.78 109.463 438.19 122.383 436.97 132.693C435.9 141.843 432.15 156.263 426.33 163.453C417.33 174.603 402.45 183.593 387.89 177.933C377.84 174.753 373.18 163.933 374.3 154.063C369.79 159.513 366.3 167.393 370.38 174.123C375.92 183.473 386.14 188.783 395.89 192.783C429.74 206.673 460.05 174.383 466.3 143.613C467.89 135.813 469.05 125.083 467.11 117.253C462.72 99.5928 451.96 83.8128 438.28 71.9728C447.07 75.3028 454.19 81.5428 461.28 87.4628C477.85 101.813 492.39 118.813 502.65 138.253C504.517 141.825 506.186 145.497 507.65 149.253C514.953 166.664 516.292 186.001 511.46 204.253C520.73 186.133 522.86 164.893 518.46 145.093L518.39 145.023Z"
      fill="#0071BC"
    />
    <path
      d="M500 148.663C499.21 159.753 497.38 170.813 493.27 181.173C490.74 188.083 486.91 194.403 483.27 200.743L483.15 201.363C475.986 210.434 466.718 217.621 456.15 222.303C469.36 224.033 483.22 220.453 493.1 211.303H493.65C508.74 197.353 513.65 173.983 504.37 155.473C502.9 153.133 501.47 150.903 500 148.663Z"
      fill="#0071BC"
    />
    <path
      d="M442.27 242.863C415.687 242.967 389.721 234.85 367.93 219.623C364.88 217.433 361.93 215.093 359.04 212.693C367.93 216.823 376.47 221.693 385.63 225.233C416.69 238.923 454.99 245.233 485.63 227.023C485.19 226.593 484.33 225.733 483.89 225.313C475.96 225.503 468.29 228.703 460.27 228.603C434.43 230.503 408.92 222.493 386.9 209.343C377.62 203.803 367.9 192.723 362.17 183.683C349.41 163.683 345.74 132.453 358.92 111.683C362.64 105.813 368.62 98.7726 374.58 95.0926C378.346 92.8091 382.553 91.3479 386.923 90.805C391.294 90.2621 395.73 90.6498 399.94 91.9426C411.09 95.5526 412.17 104.703 414.06 114.843C413.9 114.013 417.06 110.463 417.48 109.503C418.366 107.722 418.912 105.793 419.09 103.813C420.02 90.4926 403.53 81.1026 392.73 77.3326C381.413 73.3074 368.967 73.8965 358.08 78.9726C330.03 92.3126 311.76 126.703 322.19 157.103C326.96 174.023 338.71 187.633 350.58 200.103C348.19 198.583 345.81 197.103 343.49 195.533C325.636 183.208 309.93 168.033 297 150.613C281.15 128.613 269.57 101.123 275 73.6126C275.27 72.0026 275.82 68.8026 276.12 67.2026C266.195 87.4617 264.657 110.817 271.84 132.203C276.386 145.595 283.051 158.172 291.58 169.453C309.32 193.023 332.31 212.453 357.58 227.453C358.04 227.753 358.97 228.293 359.43 228.593C334.691 227.946 310.919 218.848 292.07 202.813C277.4 190.173 266.15 173.963 258.07 156.463C257.69 155.703 256.89 154.203 256.49 153.463C260.96 174.463 271.09 194.013 283.81 211.183C290.565 219.708 298.366 227.348 307.03 233.923C321.34 243.103 337.84 249.023 354.88 249.803C374.67 250.643 394.66 247.873 414.23 251.973C410.33 253.133 406.44 254.253 402.58 255.403C371.53 260.843 338.32 259.033 310.03 244.113C309.18 243.733 307.52 242.983 306.68 242.573C322.08 255.663 341.33 263.833 361.08 267.653C381.08 272.393 402.27 271.973 422.37 267.653C448.72 262.743 472.66 246.793 491.91 228.713C492.165 228.373 492.364 227.995 492.5 227.593C491.355 228.543 490.038 229.262 488.62 229.713C474.144 236.997 458.415 241.46 442.27 242.863V242.863Z"
      fill="#0071BC"
    />
    <path
      d="M263.39 485.143H237.25V493.973H251.39V500.083H237.29V512.813H229.5V479.293H263.39V485.143Z"
      fill="#0071BC"
    />
    <path
      d="M351.46 512.813H318.52V479.293H326.32V506.513H351.46V512.813Z"
      fill="#0071BC"
    />
    <path
      d="M447.73 504.883C447.73 508.003 447.14 510.103 445.94 511.193C444.74 512.283 442.58 512.813 439.39 512.813H414.73C411.57 512.813 409.393 512.273 408.2 511.193C407.007 510.113 406.403 508.009 406.39 504.883V487.213C406.39 484.099 406.987 481.999 408.18 480.913C409.373 479.826 411.55 479.286 414.71 479.293H439.39C442.55 479.293 444.723 479.833 445.91 480.913C447.11 482.003 447.7 484.103 447.7 487.213L447.73 504.883ZM439.93 506.513V485.273H414.21V506.513H439.93Z"
      fill="#0071BC"
    />
    <path
      d="M558.68 479.293L545.76 512.813H540.5L531.27 489.623L521.92 512.813H516.52L503.6 479.293H511.6L520.16 501.813L529.06 479.333H534.52L543.61 501.813L551.73 479.333L558.68 479.293Z"
      fill="#0071BC"
    />
  </Icon>
);

export default ColorfulLogoIcon;

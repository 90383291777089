/**
 * Returns the fiscal year of a specific date based on org-wide fiscal year start config. For more info check
 * fiscalYearRange function documentation.
 *
 * @param date - The date in question
 * @param fiscalYearStart - The config from the org that specifies the month/day in which the fiscal year start.
 * @returns - The fiscal year for the given date.
 */
export default function getFiscalYear(
  date: Date,
  fiscalYearStart: Date,
): number {
  const inputYear = date.getFullYear();
  const inputMonth = date.getMonth(); // Month is zero-based
  const inputDay = date.getDate();

  const inputPointStr = `${inputMonth}.${
    inputDay < 10 ? `0${inputDay}` : inputDay
  }`;
  const inputPoint = parseFloat(inputPointStr);

  // const fyStartYear = fiscalYearStart.getFullYear();
  const fyStartMonth = fiscalYearStart.getMonth(); // Month is zero-based
  const fyStartDay = fiscalYearStart.getDate();
  const fyStartPointStr = `${fyStartMonth}.${
    fyStartDay < 10 ? `0${fyStartDay}` : fyStartDay
  }`;
  const fyStartPoint = parseFloat(fyStartPointStr);

  if (inputPoint >= fyStartPoint) {
    if (fyStartMonth == 0) {
      // https://github.com/Grantflow2/grantflow/issues/47#issuecomment-1913725473
      return inputYear;
    }
    return inputYear + 1;
  }
  return inputYear;
}

import yup from "settings/yup";

const deadlinesReportSchema = yup.object().shape({
    grantDeadlines: yup.boolean().required(),
    reportDeadlines: yup.boolean().required()
});

export interface DeadlineReportSchema {
    grantDeadlines: boolean;
    reportDeadlines: boolean;
}

export default deadlinesReportSchema;

import React from "react";
import { Route, Switch } from "react-router-dom";

import withRouterSessionValidator from "hocs/withRouterSessionValidator";
import RouteContainer from "contexts/route/RouteContainer";
import AppWrapper from "components/AppWrapper";
import { pages } from "routes/pages";
import NotFound from "views/NotFound";

const AppRouter: React.FC = () => (
  <Switch>
    {Object.values(pages).map((routeProps) => {
      const Component = routeProps.component;

      return (
        <Route
          strict={routeProps?.strict}
          exact={routeProps?.exact}
          path={routeProps?.path}
          key={routeProps?.path}
        >
          <RouteContainer routeProps={routeProps}>
            <AppWrapper>
              <Component />
            </AppWrapper>
          </RouteContainer>
        </Route>
      );
    })}
    <Route component={NotFound} />
  </Switch>
);

export default withRouterSessionValidator(AppRouter);

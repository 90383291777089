// {method: string, endPoint: string, data?: [Object, Array<any>], onSuccess: (results: any) => void, onError: (error: string) => void}

export const xhr = (req: any) => {
    const method: string = req.method;
    const endPoint: string = req.endPoint;

    const xhr = new XMLHttpRequest();
    xhr.open(method, endPoint, true);
    xhr.setRequestHeader("Content-type", "application/json; charset=UTF-8");
    xhr.responseType = 'json';
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        const resp = typeof xhr.response === 'string' ? JSON.parse(xhr.response): xhr.response;
        req.onSuccess(resp);
      } else {
        req.onError(xhr.statusText);
      }
    };

    xhr.onerror = () => {
      req.onError(xhr.statusText);
    };

    if(method.toLowerCase() === "get"){
        xhr.send();
    }else{
        const dataToSend = JSON.stringify(req.data);
        xhr.send(dataToSend);
    }
}

import React, { memo } from "react";

import CardContainer from "components/Cards/CardContainer";
import CardHeader from "components/Cards/CardHeader";
import "./styles.scss";
import formatCurrency from "utils/formatCurrency";
import { FragmentType, graphql, useFragment } from "generated/graphql/v2";

const lineCount = 10;
const ranges = [
  { w: 85, color: "#a3a3ff" },
  { w: 75, color: "#7879ff" },
  { w: 50, color: "#4949ff" },
];

const BulletChartFragment = graphql(`
  fragment BulletChartFragment on Organization {
    yearlyGoal(fiscalYear: $currentFy)
    bcAwards: awardsByYear(fiscalYears: [$currentFy]) {
      awarded
    }
  }
`);

const BulletChart = (props: {
  chartData: FragmentType<typeof BulletChartFragment>;
}) => {
  const overall = useFragment(BulletChartFragment, props.chartData);

  const goal = Number(overall.yearlyGoal) || 0;
  const achieved = Number(overall.bcAwards[0]?.awarded) || 0;
  const achievedPercentage = (achieved / (goal || 1)) * 100;

  const w = Math.floor(goal * 1.1);
  const goalW = (goal / w) * 100 || 0;
  const currentW = Math.min((goalW * achieved) / goal || 0, 100);

  return (
    <CardContainer height="full" display="flex" flexDirection="column" pb={7}>
      <CardHeader title={`Current Fiscal Year Fundraising Tracker`} />

      <div className="bullet-chart">
        {goalW > 0 && (
          <>
            {ranges.map((item) => (
              <div
                key={item.w}
                className="bullet-chart-range"
                style={{
                  width: `${(goalW * item.w) / 100}%`,
                  backgroundColor: item.color,
                }}
              >
                <span>{`${item.w}%`}</span>
              </div>
            ))}
            {Array.from({ length: lineCount - 1 }).map((_, i) => (
              <span
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                className="bullet-chart-line"
                style={{ left: `${(goalW / lineCount) * (i + 1)}%` }}
              />
            ))}
            <div
              className="bullet-chart-current"
              style={{ left: `${currentW}%` }}
            >
              <span
                style={{
                  transform: `translate(${
                    currentW < 10 ? 0 : currentW > 90 ? -100 : -50
                  }%, -4px)`,
                }}
              >
                {`${formatCurrency(achieved)} (${
                  // comment for better-looking formatting
                  achievedPercentage.toFixed(2)
                }%)`}
              </span>
            </div>
          </>
        )}

        <div className="bullet-chart-goal" style={{ left: `${goalW}%` }}>
          <span>
            100%
            <br />
            {formatCurrency(goal)}
          </span>
        </div>
      </div>
    </CardContainer>
  );
};

export default memo(BulletChart);

import i18next from "i18next";

import { MIN_PASSWORD_LENGTH } from "constants/forms";
import yup from "settings/yup";

const resetPasswordSchema = yup.object().shape({
  password: yup.string().min(MIN_PASSWORD_LENGTH).required(),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password")], i18next.t("schemas.passwords_must_match")),
});

export interface ResetPasswordSchema {
  password: string;
  passwordConfirmation: string;
}

export default resetPasswordSchema;

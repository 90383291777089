import React from "react";
import { useTranslation } from "react-i18next";
import { Text } from "@chakra-ui/react";

import Button from "components/Button";

import { EditButtonProps } from "./types";

const EditButton: React.FC<EditButtonProps> = ({
  isEditing,
  handleClick,
  isDisabled = false,
  isLoading = false,
}) => {
  const [t] = useTranslation();

  const buttonColor = isEditing ? "secondary.200" : "primary.100";

  return (
    <Button
      background={buttonColor}
      onClick={handleClick}
      disabled={isDisabled}
      isLoading={isLoading}
    >
      {isEditing ? (
        <Text>{t("buttons.save")}</Text>
      ) : (
        <Text>{t("buttons.edit")}</Text>
      )}
    </Button>
  );
};
export default EditButton;

import { Flex } from "@chakra-ui/react";
import React, { useCallback, useState } from "react";

import { formattedMonthOptions } from "utils/formattedMonthOptions";
import useFilter, { filters } from "hooks/useFilter";
import CaretLeftIcon from "settings/theme/ChakraTheme/icons/CaretLeftIcon";
import CaretRightIcon from "settings/theme/ChakraTheme/icons/CaretRightIcon";
import { PeriodsEnum } from "constants/periods";

import { DateFilterProps } from "./types";
import SwapButton from "./SwapButton";
import MonthSelector from "./MonthSelector";

const DateFilter: React.FC<{ flexProps?: DateFilterProps, onFiscalYearChange: (year: number) => void }> = (props) => {
  const [submissionMonth, setSubmissionMonth] = useFilter(
    filters.submissionMonth,
  );
  const [submissionYear, setSubmissionYear] = useFilter(filters.submissionYear);

  const isSwapDisabled =
    submissionMonth === PeriodsEnum.All; //|| submissionMonth === PeriodsEnum.Year;
  const isYearPrecision = submissionMonth === PeriodsEnum.Year;
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);

  const handleDecrease = useCallback(() => {
    const currentSelectedMonth = submissionMonth;

    const selectedYear = submissionYear;
    setIsNextButtonDisabled(false);

    const monthOptions = formattedMonthOptions(selectedYear as string);

    let monthIndex = monthOptions.findIndex(
      (month) => month.value === currentSelectedMonth,
    );

    if (monthIndex === 0 && submissionYear === PeriodsEnum.All) {
      return;
    }

    if (isYearPrecision) {
      const prevYear = (Number(submissionYear) - 1);
      setSubmissionYear(prevYear.toString());
      props.onFiscalYearChange(prevYear);
      return;
    }

    if (monthIndex === 0) {
      setSubmissionYear((Number(submissionYear) - 1).toString());

      monthIndex = monthOptions.length;
    }

    const updatedMonth = monthOptions[monthIndex - 1];

    setSubmissionMonth(updatedMonth.value);
  }, [
    submissionYear,
    submissionMonth,
    isYearPrecision,
    setSubmissionYear,
    setSubmissionMonth,
  ]);

  const checkNextButtonStatus = (submissionYear: number) => {
    const twoYearsAhead = (new Date()).getFullYear();
    if (Number(submissionYear) > twoYearsAhead) {
      setIsNextButtonDisabled(true);
    }
  }

  const handleIncrease = useCallback(() => {
    const currentSelectedMonth = submissionMonth;

    const selectedYear = submissionYear;

    const monthOptions = formattedMonthOptions(selectedYear as string);

    const monthOptionsLength = monthOptions.length;

    let monthIndex = monthOptions.findIndex(
      (month) => month.value === currentSelectedMonth,
    );

    if (
      monthIndex === monthOptionsLength - 1 &&
      submissionYear === PeriodsEnum.All
    ) {
      checkNextButtonStatus(Number(submissionYear));
      return;
    }

    if (isYearPrecision) {
      const nextYear = (Number(submissionYear) + 1);
      setSubmissionYear(nextYear.toString());
      props.onFiscalYearChange(nextYear);
      checkNextButtonStatus(Number(submissionYear));
      return;
    }

    if (monthIndex === monthOptionsLength - 1) {
      setSubmissionYear((Number(submissionYear) + 1).toString());

      monthIndex = 0;

      const updatedMonth = monthOptions[monthIndex];

      setSubmissionMonth(updatedMonth.value);
      checkNextButtonStatus(Number(submissionYear));
      return;
    }

    const updatedMonth = monthOptions[monthIndex + 1];

    setSubmissionMonth(updatedMonth.value);
  }, [
    submissionYear,
    submissionMonth,
    isYearPrecision,
    setSubmissionYear,
    setSubmissionMonth,
  ]);

  return (
    <Flex backgroundColor="primary.100" justifyContent="center" {...props.flexProps}>
      <SwapButton
        icon={<CaretLeftIcon />}
        disabled={isSwapDisabled}
        onClick={handleDecrease}
      />

      <MonthSelector />

      <SwapButton
        icon={<CaretRightIcon />}
        disabled={isSwapDisabled || isNextButtonDisabled}
        onClick={handleIncrease}
      />
    </Flex>
  );
};

export default DateFilter;

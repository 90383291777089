import React from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

import { useToast } from "contexts/toast";
import createProgramSchema, {
  CreateProgramSchema,
} from "settings/yup/schemas/createProgramSchema";
import { getFloat } from "utils/getFloat";
import capitalizeStringFirstLetter from "utils/capitalizeStringFirstLetter";

import { useOrganizationId } from "hooks/_new/useOrganization";
import { FormContainer } from "components/_new/theme/forms/FormContainer";
import { Input } from "react-rainbow-components";
import { ModalComponentProps } from "contexts/modal/types";
import { graphql } from "generated/graphql/v2";
import { useMutation } from "@apollo/client";
import { useModal } from "contexts/modal";
import { MoneyInput } from "components/_new/theme/inputs/MoneyInput";
import { Stack } from "@chakra-ui/react";
import { Button } from "components/_new/theme/Button";

export const CreateProgramMutation = graphql(`
  mutation CreateProgram($params: CreateProgramParams!) {
    createProgram(params: $params) {
      id
    }
  }
`);

const CreateProgramForm: React.FC<ModalComponentProps> = ({ hideModal }) => {
  const { addToast } = useToast();
  const [t] = useTranslation();

  const { errors, control, handleSubmit } = useForm<CreateProgramSchema>({
    resolver: yupResolver(createProgramSchema),
    mode: "onBlur",
    defaultValues: {
      name: undefined,
      yearlyGoal: undefined,
    },
    shouldUnregister: false,
  });

  const organizationId = useOrganizationId();
  const [createProgram] = useMutation(CreateProgramMutation, {
    refetchQueries: ["GetCurrentYearGoal", "ListProgramsQuery"],
    awaitRefetchQueries: true,
  });

  return (
    <FormContainer
      onSubmit={handleSubmit(async (values) => {
        try {
          await createProgram({
            variables: {
              params: {
                name: values.name,
                yearlyGoal: getFloat(values.yearlyGoal)?.toString(),
                organizationId: organizationId,
              },
            },
          });
          addToast({
            title: t("create_program.success"),
            type: "success",
          });

          hideModal();
        } catch (error) {
          addToast({
            title: capitalizeStringFirstLetter((error as Error).message),
            type: "error",
          });
        }
      })}
    >
      <Controller
        required
        name="name"
        control={control}
        render={({ value, onChange }) => (
          <Input
            value={value}
            onChange={onChange}
            required
            error={errors.name}
            label={t("create_program.steps.program.program_name")}
            labelAlignment="left"
            type="text"
          />
        )}
      />

      <Controller
        control={control}
        name="yearlyGoal"
        render={({ value, onChange }) => (
          <MoneyInput
            value={value}
            onChange={onChange}
            required
            error={errors.yearlyGoal}
            label={t("create_program.steps.program.yearly_goal")}
          />
        )}
      />

      <Stack justifyContent="center" alignItems="center">
        <Button type="submit" bgColor={`primary.200`} fontSize="sm">
          {t("buttons.submit")}
        </Button>
      </Stack>
    </FormContainer>
  );
};

export const useCreateProgramModal = () => {
  const [showModal] = useModal();
  const [t] = useTranslation();

  return () => {
    showModal({
      title: t("create_program.title"),
      component: CreateProgramForm,
      closeOnOverlayClick: false,
    });
  };
};

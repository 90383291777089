import yup from "settings/yup";

import buildCurrencyTest from "../tests/currencyFieldTest";

const createProgramSchema = yup.object().shape({
  name: yup.string().required(),
  yearlyGoal: yup.string().test(buildCurrencyTest()),
});

export interface CreateProgramSchema {
  name: string;
  yearlyGoal: string;
}

export default createProgramSchema;

import React from "react";
import { Helmet } from "react-helmet";
import { Flex } from "@chakra-ui/react";

import { useRouteProps } from "contexts/route";
import Header from "components/Header";
import Footer from "components/Footer";
import i18n from "translations/i18n";
import PrintableHeader from "components/PrintableHeader";
import Unauthorized from "views/Unauthorized";
import { useCurrentUser } from "contexts/currentUser";

/**
 * App wrapper. Can be used to render Header, Footer, etc.
 * @param param0 options
 */
const AppWrapper: React.FC = ({ children }) => {
  const { showHeader, showFooter, requireAuthentication } = useRouteProps();
  const [user] = useCurrentUser();

  return (
    <Flex
      flexDirection="column"
      minHeight="100vh"
      backgroundColor="background"
      css={{ "@media print": { backgroundColor: "#ffffff" } }}
    >
      <PrintableHeader />
      <Helmet htmlAttributes={{ lang: i18n.language }} />
      {showHeader && <Header showNavItems={user?.authorized ?? false} />}
      {requireAuthentication && !user?.authorized ? <Unauthorized /> : children}
      {showFooter && <Footer />}
    </Flex>
  );
};

export default AppWrapper;

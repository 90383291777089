import yup from "settings/yup";

const forgotPasswordSchema = yup.object().shape({
  email: yup.string().email().required(),
});

export interface ForgotPasswordSchema {
  email: string;
}

export default forgotPasswordSchema;

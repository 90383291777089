import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  Decimal: string;
};

/** An application */
export type Application = {
  __typename?: "Application";
  award?: Maybe<Award>;
  deadline: Scalars["Date"];
  deadlineType: DeadlineTypeEnum;
  declined: Scalars["Boolean"];
  declinedDetails?: Maybe<DeclinedDetails>;
  grant: Grant;
  id: Scalars["Int"];
  isSubmittedAsIneligible: Scalars["Boolean"];
  reports?: Maybe<Array<Maybe<Report>>>;
  status: ApplicationStatusEnum;
  submission?: Maybe<Submission>;
  ineligibleReason?: Maybe<Scalars["String"]>;
};

/** The application status enum */
export enum ApplicationStatusEnum {
  /** Awarded */
  Awarded = "AWARDED",
  /** Declined */
  Declined = "DECLINED",
  /** Ineligible */
  Ineligible = "INELIGIBLE",
  /** Did not apply */
  NotApply = "NOT_APPLY",
  /** Pending */
  Pending = "PENDING",
}

/** An award */
export type Award = {
  __typename?: "Award";
  amountAwarded: Scalars["Decimal"];
  grantApplication: Application;
  id: Scalars["Int"];
  isPrecisionByYear: Scalars["Boolean"];
  program: Program;
  receivementDate: Scalars["Date"];
  responseDays?: Maybe<Scalars["Int"]>;
};

/** Award by year */
export type AwardByYear = {
  __typename?: "AwardByYear";
  total?: Maybe<Scalars["Decimal"]>;
  year?: Maybe<Scalars["Int"]>;
};

/** Available parameters to create an application */
export type CreateApplicationParams = {
  award?: InputMaybe<CreateAwardParams>;
  deadline: Scalars["Date"];
  deadlineType: DeadlineTypeEnum;
  declined?: InputMaybe<Scalars["Boolean"]>;
  declinedDetails?: InputMaybe<CreateDeclinedParams>;
  isSubmittedAsIneligible?: InputMaybe<Scalars["Boolean"]>;
  notApply?: InputMaybe<Scalars["Boolean"]>;
  reports?: InputMaybe<Array<CreateReportParams>>;
  submission?: InputMaybe<CreateSubmissionParams>;
  ineligibleReason?: InputMaybe<Scalars["String"]>;
};

/** Available parameters to create an award */
export type CreateAwardParams = {
  amountAwarded: Scalars["Decimal"];
  receivementDate: Scalars["Date"];
};

/** Available parameters to create when decline */
export type CreateDeclinedParams = {
  declinedDate?: InputMaybe<Scalars["Date"]>;
  declinedReason?: InputMaybe<Scalars["String"]>;
};

/** Available fields to create a funder */
export type CreateFunderParams = {
  email?: InputMaybe<Scalars["String"]>; 
  howToApply?: InputMaybe<Scalars["String"]>;
  isPaused?: InputMaybe<Scalars["Boolean"]>;
  keyContacts?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<CreateLocationParams>;
  name: Scalars["String"];
  notes?: InputMaybe<Scalars["String"]>;
  organizationId: Scalars["Int"];
  phone?: InputMaybe<Scalars["String"]>; 
  type: FunderTypeEnum; 
  website?: InputMaybe<Scalars["String"]>;
};

/** Available parameters to create a grant */
export type CreateGrantParams = {
  application: CreateApplicationParams;
  frequency: GrantFrequencyEnum;
  funderId: Scalars["Int"];
  programId: Scalars["Int"];
  recurrenceCount?: InputMaybe<Scalars["Int"]>;
};

/** Available parameters to create a location */
export type CreateLocationParams = {
  city?: InputMaybe<Scalars["String"]>;
  complement?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  streetAddress?: InputMaybe<Scalars["String"]>;
  zipcode?: InputMaybe<Scalars["String"]>;
};

/** Available fields to create an organization */
export type CreateOrganizationParams = {
  fiscalYearStart: Scalars["Date"];
  name: Scalars["String"];
};

/** Available fields to create a program */
export type CreateProgramParams = {
  name: Scalars["String"];
  organizationId: Scalars["Int"];
  yearlyGoal?: InputMaybe<Scalars["Decimal"]>;
};

/** Available parameters to create a report */
export type CreateReportParams = {
  deadline: Scalars["Date"];
  submissionDate?: InputMaybe<Scalars["Date"]>;
  urgency: ReportUrgencyEnum;
};

/** Available parameters to create a submission */
export type CreateSubmissionParams = {
  amountRequested: Scalars["Decimal"];
  submissionDate: Scalars["Date"];
};

/** The current year goal */
export type CurrentYearGoal = {
  __typename?: "CurrentYearGoal";
  overall?: Maybe<OverallYearGoal>;
  programs?: Maybe<Array<Maybe<Program>>>;
};

/** The application deadline type enum */
export enum DeadlineTypeEnum {
  /** Required */
  Required = "REQUIRED",
  /** Rolling */
  Rolling = "ROLLING",
}

/** A Declined details */
export type DeclinedDetails = {
  __typename?: "DeclinedDetails";
  declinedDate: Scalars["Date"];
  declinedReason?: Maybe<Scalars["String"]>;
  grantApplication: Application;
  id: Scalars["Int"];
};

/** Available parameters to filter applications */
export type FilterApplicationParams = {
  /** Should not be sent with the 'year' field defined */
  endAt?: InputMaybe<Scalars["Date"]>;
  organizationId: Scalars["Int"];
  search?: InputMaybe<Scalars["String"]>;
  /** Should not be sent with the 'year' field defined */
  startAt?: InputMaybe<Scalars["Date"]>;
  status?: InputMaybe<FilterApplicationStatusEnum>;
  /** It should not be sent with the 'startAt' or 'endAt' fields defined */
  year?: InputMaybe<Scalars["Int"]>;
};

/** The enum filter for the application status */
export enum FilterApplicationStatusEnum {
  /** Awarded */
  Awarded = "AWARDED",
  /** Declined */
  Declined = "DECLINED",
  /** Ineligible */
  Ineligible = "INELIGIBLE",
  /** Did not apply */
  NotApply = "NOT_APPLY",
  /** Pending */
  Pending = "PENDING",
  /** Submitted */
  Submitted = "SUBMITTED",
}

/** Available parameters to filter a report */
export type FilterReportParams = {
  endAt?: InputMaybe<Scalars["Date"]>;
  organizationId: Scalars["Int"];
  search?: InputMaybe<Scalars["String"]>;
  startAt?: InputMaybe<Scalars["Date"]>;
  submitted?: InputMaybe<Scalars["Boolean"]>;
};

/** A funder */
export type Funder = {
  __typename?: "Funder";
  awards?: Maybe<Array<Maybe<Award>>>;
  email?: Maybe<Scalars["String"]>;
  grants?: Maybe<Array<Maybe<Grant>>>;
  howToApply?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  isPaused: Scalars["Boolean"];
  keyContacts?: Maybe<Scalars["String"]>;
  lastAward?: Maybe<Award>;
  location?: Maybe<Location>;
  name: Scalars["String"];
  notes?: Maybe<Scalars["String"]>;
  organization: Organization;
  phone?: Maybe<Scalars["String"]>;
  totalAwardAmount?: Maybe<Scalars["Decimal"]>;
  type: FunderTypeEnum;
  website?: Maybe<Scalars["String"]>;
};

/** A funder */
export type FunderTotalAwardAmountArgs = {
  endAt?: InputMaybe<Scalars["Date"]>;
  startAt?: InputMaybe<Scalars["Date"]>;
};

/** The funder type enum */
export enum FunderTypeEnum {
  /** Corporate */
  Corporate = "CORPORATE",
  /** Foundation */
  Foundation = "FOUNDATION",
  /** Government */
  Government = "GOVERNMENT",
  /** Organization */
  Organization = "ORGANIZATION",
  /** Other */
  Other = "OTHER",
}

/** The funder types count */
export type FunderTypesCount = {
  __typename?: "FunderTypesCount";
  corporate?: Maybe<Scalars["Int"]>;
  foundation?: Maybe<Scalars["Int"]>;
  government?: Maybe<Scalars["Int"]>;
  organization?: Maybe<Scalars["Int"]>;
  other?: Maybe<Scalars["Int"]>;
};

/** A grant */
export type Grant = {
  __typename?: "Grant";
  applications?: Maybe<Array<Maybe<Application>>>;
  frequency: GrantFrequencyEnum;
  funder: Funder;
  id: Scalars["Int"];
  program: Program;
  responseAverage?: Maybe<Scalars["Int"]>;
};

/** Possible values for the grant frequency */
export enum GrantFrequencyEnum {
  /** One Time */
  OneTime = "ONE_TIME",
  /** Recurring */
  Recurring = "RECURRING",
}

/** A Grants Summary */
export type GrantsSummary = {
  __typename?: "GrantsSummary";
  totalApplicationAmount?: Maybe<Scalars["Decimal"]>;
  totalApplicationCount?: Maybe<Scalars["Int"]>;
  totalAwardedAmount?: Maybe<Scalars["Decimal"]>;
  totalAwardedGrants?: Maybe<Scalars["Int"]>;
  totalPendingAmount?: Maybe<Scalars["Decimal"]>;
  totalPendingGrants?: Maybe<Scalars["Int"]>;
};

/** The largest funders object */
export type LargestFunders = {
  __typename?: "LargestFunders";
  overall?: Maybe<Array<Maybe<Funder>>>;
  programFunders?: Maybe<Array<Maybe<ProgramFunder>>>;
};

/** A location */
export type Location = {
  __typename?: "Location";
  city?: Maybe<Scalars["String"]>;
  complement?: Maybe<Scalars["String"]>;
  funder?: Maybe<Funder>;
  id?: Maybe<Scalars["Int"]>;
  state?: Maybe<Scalars["String"]>;
  streetAddress?: Maybe<Scalars["String"]>;
  zipcode?: Maybe<Scalars["String"]>;
};

/** An organization */
export type Organization = {
  __typename?: "Organization";
  awardsByYear?: Maybe<Array<Maybe<AwardByYear>>>;
  currentFiscalYear: Scalars["Int"];
  currentYearGoal: CurrentYearGoal;
  fiscalYearStart: Scalars["Date"];
  funders?: Maybe<Array<Maybe<Funder>>>;
  funderTypesCount: FunderTypesCount;
  grantsSummary: GrantsSummary;
  id: Scalars["Int"];
  largestFunders: LargestFunders;
  name: Scalars["String"];
  oldestApplication?: Maybe<Application>;
  oldestAward?: Maybe<Award>;
  programs?: Maybe<Array<Maybe<Program>>>;
  user?: Maybe<User>;
};

/** An organization */
export type OrganizationAwardsByYearArgs = {
  startAt?: InputMaybe<Scalars["Date"]>;
};

/** An organization */
export type OrganizationFunderTypesCountArgs = {
  endAt?: InputMaybe<Scalars["Date"]>;
  startAt?: InputMaybe<Scalars["Date"]>;
};

/** An organization */
export type OrganizationLargestFundersArgs = {
  endAt?: InputMaybe<Scalars["Date"]>;
  startAt?: InputMaybe<Scalars["Date"]>;
  type?: InputMaybe<FunderTypeEnum>;
};

/** The overall year goal */
export type OverallYearGoal = {
  __typename?: "OverallYearGoal";
  achieved?: Maybe<Scalars["Decimal"]>;
  achievedRate?: Maybe<Scalars["Decimal"]>;
  goal?: Maybe<Scalars["Decimal"]>;
  missing?: Maybe<Scalars["Decimal"]>;
};

export type PaginationParams = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  sortingOrder?: InputMaybe<SortingOrder>;
};

/** A program */
export type Program = {
  __typename?: "Program";
  achievedAmount?: Maybe<Scalars["Decimal"]>;
  funders?: Maybe<Array<Maybe<Funder>>>;
  grants?: Maybe<Array<Maybe<Grant>>>;
  id: Scalars["Int"];
  missingAmount?: Maybe<Scalars["Decimal"]>;
  name: Scalars["String"];
  organization: Organization;
  yearlyGoal: Scalars["Decimal"];
};

/** A Program Funder */
export type ProgramFunder = {
  __typename?: "ProgramFunder";
  funders?: Maybe<Array<Maybe<Funder>>>;
  program: Program;
};

/** A report */
export type Report = {
  __typename?: "Report";
  deadline: Scalars["Date"];
  grantApplication: Application;
  id: Scalars["Int"];
  submissionDate?: Maybe<Scalars["Date"]>;
  submitted: Scalars["Boolean"];
  urgency: ReportUrgencyEnum;
};

/** Possible values for the report urgency */
export enum ReportUrgencyEnum {
  /** Courtesy */
  Courtesy = "COURTESY",
  /** Required */
  Required = "REQUIRED",
}

export type RootMutationType = {
  __typename?: "RootMutationType";
  /** Create a funder */
  createFunder?: Maybe<Funder>;
  /** Create a grant */
  createGrant?: Maybe<Grant>;
  /** Create a previous award */
  createPreviousAward?: Maybe<Award>;
  /** Create a program */
  createProgram?: Maybe<Program>;
  /** Creates an unverified user with unconfirmed email */
  createUnverifiedUser?: Maybe<Scalars["String"]>;
  /** Delete a funder */
  deleteFunder?: Maybe<Funder>;
  /** Delete a program */
  deleteProgram?: Maybe<Program>;
  /** Logins an user */
  loginUser?: Maybe<Scalars["String"]>;
  /** Sends an email to reset password */
  requestResetPassword?: Maybe<Scalars["String"]>;
  /** Resets the user's password */
  resetPassword?: Maybe<Scalars["String"]>;
  /** Update an application */
  updateApplication?: Maybe<Application>;
  /** Delete an application*/
  deleteApplication?: Maybe<Scalars["Int"]>;
  /** Update a funder */
  updateFunder?: Maybe<Funder>;
  /** Update an organization */
  updateOrganization?: Maybe<Organization>;
  /** Update a program */
  updateProgram?: Maybe<Program>;
  /** Update a report */
  updateReport?: Maybe<Report>;
  /** Delete a grant*/
  deleteGrant?: Maybe<Scalars["Int"]>;
};

export type RootMutationTypeCreateFunderArgs = {
  params: CreateFunderParams;
};

export type RootMutationTypeCreateGrantArgs = {
  params: CreateGrantParams;
};

export type RootMutationTypeCreatePreviousAwardArgs = {
  amountAwarded: Scalars["Decimal"];
  awardedDate?: InputMaybe<Scalars["Date"]>;
  awardedYear?: InputMaybe<Scalars["Int"]>;
  funderId: Scalars["Int"];
  programId: Scalars["Int"];
  submissionDate?: InputMaybe<Scalars["Date"]>;
};

export type RootMutationTypeCreateProgramArgs = {
  params: CreateProgramParams;
};

export type RootMutationTypeCreateUnverifiedUserArgs = {
  email: Scalars["String"];
  organization: CreateOrganizationParams;
  password: Scalars["String"];
};

export type RootMutationTypeDeleteFunderArgs = {
  id: Scalars["Int"];
};

export type RootMutationTypeDeleteProgramArgs = {
  id: Scalars["Int"];
};

export type RootMutationTypeLoginUserArgs = {
  email: Scalars["String"];
  password: Scalars["String"];
};

export type RootMutationTypeRequestResetPasswordArgs = {
  email: Scalars["String"];
};

export type RootMutationTypeResetPasswordArgs = {
  password: Scalars["String"];
  token: Scalars["String"];
};

export type RootMutationTypeUpdateApplicationArgs = {
  id: Scalars["Int"];
  params: UpdateApplicationParams;
};

export type RootMutationTypeUpdateFunderArgs = {
  id: Scalars["Int"];
  params: UpdateFunderParams;
};

export type RootMutationTypeUpdateOrganizationArgs = {
  id: Scalars["Int"];
  params: UpdateOrganizationParams;
};

export type RootMutationTypeUpdateProgramArgs = {
  id: Scalars["Int"];
  params: UpdateProgramParams;
};

export type RootMutationTypeUpdateReportArgs = {
  id: Scalars["Int"];
  params: UpdateReportParams;
};

export type RootMutationTypeDeleteGrantArgs = {
  id: Scalars["Int"];
};

export type RootQueryType = {
  __typename?: "RootQueryType";
  /** Gets the user associated with the given token */
  currentUser?: Maybe<User>;
  /** Gets an application by ID */
  getApplication?: Maybe<Application>;
  /** Gets a funder by ID */
  getFunder?: Maybe<Funder>;
  /** Gets an organization by ID */
  getOrganization?: Maybe<Organization>;
  /** Gets a program by ID */
  getProgram?: Maybe<Program>;
  /** Gets a report by ID */
  getReport?: Maybe<Report>;
  /** Gets a user by id */
  getUser?: Maybe<User>;
  /** Gets a list of applications */
  listApplications?: Maybe<Array<Maybe<Application>>>;
  /** Gets a list of all funders */
  listFunders?: Maybe<Array<Maybe<Funder>>>;
  /** Gets a list of all organizations */
  listOrganizations?: Maybe<Array<Maybe<Organization>>>;
  /** Gets a list of all programs */
  listPrograms?: Maybe<Array<Maybe<Program>>>;
  /** List all reports with filters */
  listReports?: Maybe<Array<Maybe<Report>>>;
  /** Gets a list of all users */
  listUsers?: Maybe<Array<Maybe<User>>>;
  /** Gets the application version */
  version?: Maybe<Scalars["String"]>;
};

export type RootQueryTypeGetApplicationArgs = {
  id: Scalars["Int"];
};

export type RootQueryTypeGetFunderArgs = {
  id: Scalars["Int"];
};

export type RootQueryTypeGetOrganizationArgs = {
  id: Scalars["Int"];
};

export type RootQueryTypeGetProgramArgs = {
  id: Scalars["Int"];
};

export type RootQueryTypeGetReportArgs = {
  id: Scalars["Int"];
};

export type RootQueryTypeGetUserArgs = {
  id: Scalars["Int"];
};

export type RootQueryTypeListApplicationsArgs = {
  filters: FilterApplicationParams;
  pagination?: InputMaybe<PaginationParams>;
};

export type RootQueryTypeListReportsArgs = {
  filters: FilterReportParams;
  pagination?: InputMaybe<PaginationParams>;
};

/** Sorting order */
export enum SortingOrder {
  /** Ascending order */
  Asc = "ASC",
  /** Descending order */
  Desc = "DESC",
}

/** A submission */
export type Submission = {
  __typename?: "Submission";
  amountRequested: Scalars["Decimal"];
  grantApplication: Application;
  id: Scalars["Int"];
  submissionDate: Scalars["Date"];
};

/** Available parameters to update an application */
export type UpdateApplicationParams = {
  award?: InputMaybe<UpdateAwardParams>;
  deadline?: InputMaybe<Scalars["Date"]>;
  deadlineType?: InputMaybe<DeadlineTypeEnum>;
  declined?: InputMaybe<Scalars["Boolean"]>;
  declinedDetails?: InputMaybe<CreateDeclinedParams>;
  reports?: InputMaybe<Array<UpdateReportParams>>;
  submission?: InputMaybe<UpdateSubmissionParams>;
  ineligibleReason?: InputMaybe<Scalars["String"]>;
  programId: Scalars["Int"];
};

/** Available parameters to update an award */
export type UpdateAwardParams = {
  amountAwarded?: InputMaybe<Scalars["Decimal"]>;
  id?: InputMaybe<Scalars["Int"]>;
  receivementDate?: InputMaybe<Scalars["Date"]>;
};

/** Available fields to update a finder */
export type UpdateFunderParams = {
  email?: InputMaybe<Scalars["String"]>;
  howToApply?: InputMaybe<Scalars["String"]>;
  isPaused?: InputMaybe<Scalars["Boolean"]>;
  keyContacts?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<UpdateLocationParams>;
  name?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  organizationId?: InputMaybe<Scalars["Int"]>;
  phone?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<FunderTypeEnum>;
  website?: InputMaybe<Scalars["String"]>;
  complexityScore?: InputMaybe<Scalars["String"]>;
};

/** Available parameters to update a location */
export type UpdateLocationParams = {
  city?: InputMaybe<Scalars["String"]>;
  complement?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  state?: InputMaybe<Scalars["String"]>;
  streetAddress?: InputMaybe<Scalars["String"]>;
  zipcode?: InputMaybe<Scalars["String"]>;
};

/** Available fields to update an organization */
export type UpdateOrganizationParams = {
  fiscalYearStart?: InputMaybe<Scalars["Date"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** Available fields to update a program */
export type UpdateProgramParams = {
  name?: InputMaybe<Scalars["String"]>;
  yearlyGoal?: InputMaybe<Scalars["Decimal"]>;
};

/** Available parameters to update a report */
export type UpdateReportParams = {
  deadline?: InputMaybe<Scalars["Date"]>;
  id?: InputMaybe<Scalars["Int"]>;
  submissionDate?: InputMaybe<Scalars["Date"]>;
  urgency?: InputMaybe<ReportUrgencyEnum>;
};

/** Available parameters to update a submission */
export type UpdateSubmissionParams = {
  amountRequested?: InputMaybe<Scalars["Decimal"]>;
  id?: InputMaybe<Scalars["Int"]>;
  submissionDate?: InputMaybe<Scalars["Date"]>;
};

/** An user */
export type User = {
  __typename?: "User";
  authorized?: Maybe<Scalars["Boolean"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  organization?: Maybe<Organization>;
  role?: Maybe<UserRoleEnum>;
  verified?: Maybe<Scalars["Boolean"]>;
};

/** User roles */
export enum UserRoleEnum {
  /** Admin */
  Admin = "ADMIN",
  /** User */
  User = "USER",
}

export type GetCurrentUserRoleQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserRoleQuery = {
  __typename?: "RootQueryType";
  currentUser?: { __typename?: "User"; id?: number; role?: UserRoleEnum };
};

export type ListAdminImpersonatorOrganizationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ListAdminImpersonatorOrganizationsQuery = {
  __typename?: "RootQueryType";
  listOrganizations?: Array<{
    __typename?: "Organization";
    id: number;
    name: string;
  }>;
};

export type CreateGrantMutationVariables = Exact<{
  params: CreateGrantParams;
}>;

export type CreateGrantMutation = {
  __typename?: "RootMutationType";
  createGrant?: {
    __typename: "Grant";
    id: number;
    funder: {
      __typename: "Funder";
      organization: { __typename: "Organization"; id: number };
    };
  };
};

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserQuery = {
  __typename?: "RootQueryType";
  currentUser?: {
    __typename?: "User";
    id?: number;
    organization?: {
      __typename?: "Organization";
      fiscalYearStart: string;
      id: number;
      name: string;
      funders?: Array<{ __typename?: "Funder"; id: number; name: string }>;
      programs?: Array<{
        __typename?: "Program";
        id: number;
        name: string;
        achievedAmount?: string;
        missingAmount?: string;
        yearlyGoal: string;
      }>;
      currentYearGoal: {
        __typename?: "CurrentYearGoal";
        overall?: {
          __typename?: "OverallYearGoal";
          achieved?: string;
          achievedRate?: string;
          goal?: string;
          missing?: string;
        };
      };
    };
  };
};

export type CreateFunderMutationVariables = Exact<{
  params: CreateFunderParams;
}>;

export type CreateFunderMutation = {
  __typename?: "RootMutationType";
  createFunder?: {
    __typename?: "Funder";
    id: number;
    name: string;
    type?: string;
    email?: string;
    phone?: string;
    keyContacts?: string;
    website?: string;
    location?: {
      city?: string;
      complement?: string;
      state?: string;
      streetAddress?: string;
      zipcode?: string;
    }
  };
};

export type CreatePreviousAwardMutationVariables = Exact<{
  amountAwarded: Scalars["Decimal"];
  awardedDate?: InputMaybe<Scalars["Date"]>;
  awardedYear?: InputMaybe<Scalars["Int"]>;
  funderId: Scalars["Int"];
  programId: Scalars["Int"];
  submissionDate?: InputMaybe<Scalars["Date"]>;
}>;

export type CreatePreviousAwardMutation = {
  __typename?: "RootMutationType";
  createPreviousAward?: { __typename?: "Award"; id: number };
};

export type CreateProgramMutationVariables = Exact<{
  params: CreateProgramParams;
}>;

export type CreateProgramMutation = {
  __typename?: "RootMutationType";
  createProgram?: { __typename?: "Program"; id: number };
};

export type CreateUnverifiedUserMutationVariables = Exact<{
  email: Scalars["String"];
  password: Scalars["String"];
  organization: CreateOrganizationParams;
}>;

export type CreateUnverifiedUserMutation = {
  __typename?: "RootMutationType";
  createUnverifiedUser?: string;
};

export type DeleteFunderMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type DeleteFunderMutation = {
  __typename?: "RootMutationType";
  deleteFunder?: { __typename?: "Funder"; id: number };
};

export type DeleteProgramMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type DeleteProgramMutation = {
  __typename?: "RootMutationType";
  deleteProgram?: { __typename?: "Program"; id: number };
};

export type DeleteGrantMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type DeleteGrantMutation = {
  __typename?: "RootMutationType";
  deleteGrant?: { __typename?: "Grant"; id: number };
};

export type DeleteApplicationMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type DeleteApplicationMutation = {
  __typename?: "RootMutationType";
  deleteApplication?: { __typename?: "Application"; id: number };
};

export type LoginUserMutationVariables = Exact<{
  email: Scalars["String"];
  password: Scalars["String"];
}>;

export type LoginUserMutation = {
  __typename?: "RootMutationType";
  loginUser?: string;
};

export type RequestResetPasswordMutationVariables = Exact<{
  email: Scalars["String"];
}>;

export type RequestResetPasswordMutation = {
  __typename?: "RootMutationType";
  requestResetPassword?: string;
};

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars["String"];
  token: Scalars["String"];
}>;

export type ResetPasswordMutation = {
  __typename?: "RootMutationType";
  resetPassword?: string;
};

export type UpdateApplicationMutationVariables = Exact<{
  id: Scalars["Int"];
  params: UpdateApplicationParams;
}>;

export type UpdateApplicationMutation = {
  __typename?: "RootMutationType";
  updateApplication?: {
    __typename?: "Application";
    id: number;
    grant: {
      __typename?: "Grant";
      funder: {
        __typename?: "Funder";
        organization: { __typename?: "Organization"; id: number };
      };
    };
  };
};

export type UpdateFunderMutationVariables = Exact<{
  id: Scalars["Int"];
  params: UpdateFunderParams;
}>;

export type UpdateFunderMutation = {
  __typename?: "RootMutationType";
  updateFunder?: { __typename?: "Funder"; id: number; isPaused: boolean };
};

export type UpdateOrganizationMutationVariables = Exact<{
  id: Scalars["Int"];
  params: UpdateOrganizationParams;
}>;

export type UpdateOrganizationMutation = {
  __typename?: "RootMutationType";
  updateOrganization?: { __typename?: "Organization"; id: number };
};

export type UpdateReportMutationVariables = Exact<{
  id: Scalars["Int"];
  params: UpdateReportParams;
}>;

export type UpdateReportMutation = {
  __typename?: "RootMutationType";
  updateReport?: { __typename?: "Report"; id: number };
};

export type UpdateYearlyGoalMutationVariables = Exact<{
  id: Scalars["Int"];
  params: UpdateProgramParams;
}>;

export type UpdateYearlyGoalMutation = {
  __typename?: "RootMutationType";
  updateProgram?: { __typename?: "Program"; id: number };
};

export type GetApplicationQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type GetApplicationQuery = {
  __typename?: "RootQueryType";
  getApplication?: {
    __typename?: "Application";
    id: number;
    status?: ApplicationStatusEnum;
    isSubmittedAsIneligible: boolean;
    ineligibleReason: string;
    deadline: string;
    deadlineType: DeadlineTypeEnum,
    declined: boolean;
    award?: {
      __typename?: "Award";
      id: number;
      amountAwarded: string;
      receivementDate: string;
    };
    declinedDetails?: {
      __typename?: "DeclinedDetails";
      declinedDate: string;
      declinedReason?: string;
    };
    submission?: {
      __typename?: "Submission";
      id: number;
      submissionDate: string;
      amountRequested: string;
    };
    reports?: Array<{
      __typename?: "Report";
      id: number;
      urgency: ReportUrgencyEnum;
      deadline: string;
    }>;
  };
};

export type GetAwardsByYearQueryVariables = Exact<{
  startAt?: InputMaybe<Scalars["Date"]>;
}>;

export type GetAwardsByYearQuery = {
  __typename?: "RootQueryType";
  currentUser?: {
    __typename?: "User";
    id?: number;
    organization?: {
      __typename?: "Organization";
      id: number;
      awardsByYear?: Array<{
        __typename?: "AwardByYear";
        total?: string;
        year?: number;
      }>;
    };
  };
};

export type GetCurrentYearGoalQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentYearGoalQuery = {
  __typename?: "RootQueryType";
  currentUser?: {
    __typename?: "User";
    id?: number;
    organization?: {
      __typename?: "Organization";
      id: number;
      fiscalYearStart: string;
      currentYearGoal: {
        __typename?: "CurrentYearGoal";
        overall?: {
          __typename?: "OverallYearGoal";
          achieved?: string;
          achievedRate?: string;
          goal?: string;
          missing?: string;
        };
        programs?: Array<{
          __typename?: "Program";
          id: number;
          name: string;
          achievedAmount?: string;
          missingAmount?: string;
          yearlyGoal: string;
        }>;
      };
    };
  };
};

export type GetFunderQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type GetFunderQuery = {
  __typename?: "RootQueryType";
  getFunder?: {
    __typename?: "Funder";
    id: number;
    name: string;
    keyContacts?: string;
    email?: string;
    phone?: string;
    type: FunderTypeEnum;
    website?: string;
    howToApply?: string;
    complexityScore?: string;
    notes?: string;
    isPaused: boolean;
    location?: {
      __typename?: "Location";
      id?: number;
      city?: string;
      complement?: string;
      state?: string;
      streetAddress?: string;
      zipcode?: string;
    };
    grants?: Array<{
      __typename?: "Grant";
      applications?: Array<{
        __typename?: "Application";
        id: number;
        status: ApplicationStatusEnum;
        deadline: string;
        isSubmittedAsIneligible: boolean;
        ineligibleReason: string;
        submission?: {
          __typename?: "Submission";
          id: number;
          submissionDate: string;
          amountRequested: string;
        };
        declinedDetails?: {
          __typename?: "DeclinedDetails";
          declinedReason?: string;
          declinedDate: string;
        };
        award?: {
          __typename?: "Award";
          id: number;
          isPrecisionByYear: boolean;
          receivementDate: string;
          amountAwarded: string;
        };
        grant: {
          __typename?: "Grant";
          program: { __typename?: "Program"; id: number; name: string };
        };
      }>;
    }>;
    awards?: Array<{
      __typename?: "Award";
      id: number;
      isPrecisionByYear: boolean;
      receivementDate: string;
      amountAwarded: string;
      program: { __typename?: "Program"; id: number; name: string };
      grantApplication: {
        __typename?: "Application";
        id: number;
        submission?: {
          __typename?: "Submission";
          id: number;
          submissionDate: string;
          amountRequested: string;
        };
      };
    }>;
  };
};

export type GetFundersByTypeQueryVariables = Exact<{
  startAt?: InputMaybe<Scalars["Date"]>;
  endAt?: InputMaybe<Scalars["Date"]>;
}>;

export type GetFundersByTypeQuery = {
  __typename?: "RootQueryType";
  currentUser?: {
    __typename?: "User";
    id?: number;
    organization?: {
      __typename?: "Organization";
      id: number;
      funderTypesCount: {
        __typename?: "FunderTypesCount";
        corporate?: number;
        foundation?: number;
        government?: number;
        other?: number;
      };
    };
  };
};

export type GetImpersonatedOrganizationAwardsByYearQueryVariables = Exact<{
  startAt?: InputMaybe<Scalars["Date"]>;
  organizationId: Scalars["Int"];
}>;

export type GetImpersonatedOrganizationAwardsByYearQuery = {
  __typename?: "RootQueryType";
  getOrganization?: {
    __typename?: "Organization";
    id: number;
    name: string;
    awardsByYear?: Array<{
      __typename?: "AwardByYear";
      total?: string;
      year?: number;
    }>;
  };
};

export type GetImpersonatedOrganizationCurrentYearGoalQueryVariables = Exact<{
  organizationId: Scalars["Int"];
}>;

export type GetImpersonatedOrganizationCurrentYearGoalQuery = {
  __typename?: "RootQueryType";
  getOrganization?: {
    __typename?: "Organization";
    id: number;
    name: string;
    fiscalYearStart: string;
    currentYearGoal: {
      __typename?: "CurrentYearGoal";
      overall?: {
        __typename?: "OverallYearGoal";
        achieved?: string;
        achievedRate?: string;
        goal?: string;
        missing?: string;
      };
      programs?: Array<{
        __typename?: "Program";
        id: number;
        name: string;
        achievedAmount?: string;
        missingAmount?: string;
        yearlyGoal: string;
      }>;
    };
  };
};

export type GetImpersonatedOrganizationFiscalYearQueryVariables = Exact<{
  organizationId: Scalars["Int"];
}>;

export type GetImpersonatedOrganizationFiscalYearQuery = {
  __typename?: "RootQueryType";
  getOrganization?: {
    __typename?: "Organization";
    id: number;
    name: string;
    currentFiscalYear: number;
    fiscalYearStart: string;
  };
};

export type GetImpersonatedOrganizationFundersByTypeQueryVariables = Exact<{
  startAt?: InputMaybe<Scalars["Date"]>;
  endAt?: InputMaybe<Scalars["Date"]>;
  organizationId: Scalars["Int"];
}>;

export type GetImpersonatedOrganizationFundersByTypeQuery = {
  __typename?: "RootQueryType";
  getOrganization?: {
    __typename?: "Organization";
    id: number;
    name: string;
    funderTypesCount: {
      __typename?: "FunderTypesCount";
      corporate?: number;
      foundation?: number;
      government?: number;
      other?: number;
    };
  };
};

export type GetImpersonatedOrganizationLargestFundersQueryVariables = Exact<{
  type?: InputMaybe<FunderTypeEnum>;
  startAt?: InputMaybe<Scalars["Date"]>;
  endAt?: InputMaybe<Scalars["Date"]>;
  organizationId: Scalars["Int"];
}>;

export type GetImpersonatedOrganizationLargestFundersQuery = {
  __typename?: "RootQueryType";
  getOrganization?: {
    __typename?: "Organization";
    id: number;
    name: string;
    programs?: Array<{ __typename?: "Program"; id: number; name: string }>;
    largestFunders: {
      __typename?: "LargestFunders";
      overall?: Array<{
        __typename?: "Funder";
        id: number;
        name: string;
        totalAwardAmount?: string;
      }>;
      programFunders?: Array<{
        __typename?: "ProgramFunder";
        funders?: Array<{
          __typename?: "Funder";
          id: number;
          name: string;
          totalAwardAmount?: string;
        }>;
        program: { __typename?: "Program"; id: number; name: string };
      }>;
    };
  };
};

export type GetImpersonatedOrganizationOldestAwardDateQueryVariables = Exact<{
  organizationId: Scalars["Int"];
}>;

export type GetImpersonatedOrganizationOldestAwardDateQuery = {
  __typename?: "RootQueryType";
  getOrganization?: {
    __typename?: "Organization";
    id: number;
    name: string;
    fiscalYearStart: string;
    oldestAward?: { __typename?: "Award"; id: number; receivementDate: string };
  };
};

export type GetImpersonatedOrganizationProgramsQueryVariables = Exact<{
  organizationId: Scalars["Int"];
}>;

export type GetImpersonatedOrganizationProgramsQuery = {
  __typename?: "RootQueryType";
  getOrganization?: {
    __typename?: "Organization";
    id: number;
    name: string;
    funders?: Array<{ __typename?: "Funder"; id: number; name: string }>;
    programs?: Array<{ __typename?: "Program"; id: number; name: string }>;
  };
};

export type GetImpersonatedOrganizationQueryVariables = Exact<{
  organizationId: Scalars["Int"];
}>;

export type GetImpersonatedOrganizationQuery = {
  __typename?: "RootQueryType";
  getOrganization?: { __typename?: "Organization"; id: number; name: string };
};

export type GetLargestFundersQueryVariables = Exact<{
  type?: InputMaybe<FunderTypeEnum>;
  startAt?: InputMaybe<Scalars["Date"]>;
  endAt?: InputMaybe<Scalars["Date"]>;
}>;

export type GetLargestFundersQuery = {
  __typename?: "RootQueryType";
  currentUser?: {
    __typename?: "User";
    id?: number;
    organization?: {
      __typename?: "Organization";
      id: number;
      programs?: Array<{ __typename?: "Program"; id: number; name: string }>;
      largestFunders: {
        __typename?: "LargestFunders";
        overall?: Array<{
          __typename?: "Funder";
          id: number;
          name: string;
          totalAwardAmount?: string;
        }>;
        programFunders?: Array<{
          __typename?: "ProgramFunder";
          funders?: Array<{
            __typename?: "Funder";
            id: number;
            name: string;
            totalAwardAmount?: string;
          }>;
          program: { __typename?: "Program"; id: number; name: string };
        }>;
      };
    };
  };
};

export type GetOldestAwardDateQueryVariables = Exact<{ [key: string]: never }>;

export type GetOldestAwardDateQuery = {
  __typename?: "RootQueryType";
  currentUser?: {
    __typename?: "User";
    id?: number;
    organization?: {
      __typename?: "Organization";
      id: number;
      fiscalYearStart: string;
      oldestAward?: {
        __typename?: "Award";
        id: number;
        receivementDate: string;
      };
    };
  };
};

export type GetOrganizationFiscalYearQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetOrganizationFiscalYearQuery = {
  __typename?: "RootQueryType";
  currentUser?: {
    __typename?: "User";
    id?: number;
    organization?: {
      __typename?: "Organization";
      id: number;
      currentFiscalYear: number;
      fiscalYearStart: string;
    };
  };
};

export type GetOrganizationProgramsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetOrganizationProgramsQuery = {
  __typename?: "RootQueryType";
  currentUser?: {
    __typename?: "User";
    id?: number;
    organization?: {
      __typename?: "Organization";
      id: number;
      funders?: Array<{ __typename?: "Funder"; id: number; name: string }>;
      programs?: Array<{ __typename?: "Program"; id: number; name: string }>;
    };
  };
};

export type GetOrganizationQueryVariables = Exact<{ [key: string]: never }>;

export type GetOrganizationQuery = {
  __typename?: "RootQueryType";
  currentUser?: {
    __typename?: "User";
    id?: number;
    organization?: { __typename?: "Organization"; id: number };
  };
};

export type GetReportQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type GetReportQuery = {
  __typename?: "RootQueryType";
  getReport?: {
    __typename?: "Report";
    id: number;
    deadline: string;
    submitted: boolean;
    submissionDate?: string;
    urgency: ReportUrgencyEnum;
    grantApplication: {
      __typename?: "Application";
      id: number;
      grant: {
        __typename?: "Grant";
        id: number;
        funder: { __typename?: "Funder"; id: number; name: string };
      };
    };
  };
};

export type GetUserAuthorizationQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetUserAuthorizationQuery = {
  __typename?: "RootQueryType";
  currentUser?: {
    __typename?: "User";
    id?: number;
    authorized?: boolean;
    role?: UserRoleEnum;
  };
};

export type ListApplicationsQueryVariables = Exact<{
  filters: FilterApplicationParams;
  pagination?: InputMaybe<PaginationParams>;
}>;

export type ListApplicationsQuery = {
  __typename?: "RootQueryType";
  listApplications?: Array<{
    __typename?: "Application";
    id: number;
    status: ApplicationStatusEnum;
    isSubmittedAsIneligible: boolean;
    ineligibleReason: string;
    deadline: string;
    deadlineType: DeadlineTypeEnum,
    declined: boolean;
    award?: {
      __typename?: "Award";
      id: number;
      amountAwarded: string;
      receivementDate: string;
    };
    grant: {
      __typename?: "Grant";
      id: number;
      frequency: string;
      responseAverage?: number;
      program: { __typename?: "Program"; id: number; name: string };
      funder: {
        __typename?: "Funder";
        id: number;
        name: string;
        lastAward?: {
          __typename?: "Award";
          id: number;
          receivementDate: string;
        };
      };
    };
    declinedDetails?: {
      __typename?: "DeclinedDetails";
      declinedDate: string;
      declinedReason?: string;
    };
    submission?: {
      __typename?: "Submission";
      id: number;
      submissionDate: string;
      amountRequested: string;
    };
    reports?: Array<{
      __typename?: "Report";
      id: number;
      urgency: ReportUrgencyEnum;
      deadline: string;
    }>;
  }>;
};

export type ListFundersQueryVariables = Exact<{ [key: string]: never }>;

export type ListFundersQuery = {
  __typename?: "RootQueryType";
  currentUser?: {
    __typename?: "User";
    id?: number;
    organization?: {
      __typename?: "Organization";
      id: number;
      funders?: Array<{
        __typename?: "Funder";
        id: number;
        name: string;
        email?: string;
        type: FunderTypeEnum;
        website?: string;
        totalAwardAmount?: string;
      }>;
    };
  };
};

export type ListImpersonatedOrganizationFundersQueryVariables = Exact<{
  organizationId: Scalars["Int"];
}>;

export type ListImpersonatedOrganizationFundersQuery = {
  __typename?: "RootQueryType";
  getOrganization?: {
    __typename?: "Organization";
    id: number;
    name: string;
    funders?: Array<{
      __typename?: "Funder";
      id: number;
      name: string;
      email?: string;
      type: FunderTypeEnum;
      website?: string;
      totalAwardAmount?: string;
    }>;
  };
};

export type ListReportsQueryVariables = Exact<{
  filters: FilterReportParams;
  pagination?: InputMaybe<PaginationParams>;
}>;

export type ListReportsQuery = {
  __typename?: "RootQueryType";
  listReports?: Array<{
    __typename?: "Report";
    id: number;
    deadline: string;
    submissionDate?: string;
    submitted: boolean;
    urgency: ReportUrgencyEnum;
    grantApplication: {
      __typename?: "Application";
      id: number;
      award?: { __typename?: "Award"; id: number; amountAwarded: string };
      grant: {
        __typename?: "Grant";
        id: number;
        funder: { __typename?: "Funder"; id: number; name: string };
        program: { __typename?: "Program"; id: number; name: string };
      };
    };
  }>;
};

export type GetBaseCurrentOrganizationQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetBaseCurrentOrganizationQuery = {
  __typename?: "RootQueryType";
  currentUser?: {
    __typename?: "User";
    id?: number;
    organization?: { __typename?: "Organization"; id: number };
  };
};

export type GetBaseImpersonatedOrganizationQueryVariables = Exact<{
  organizationId: Scalars["Int"];
}>;

export type GetBaseImpersonatedOrganizationQuery = {
  __typename?: "RootQueryType";
  getOrganization?: { __typename?: "Organization"; id: number; name: string };
};

export const GetCurrentUserRoleDocument = gql`
  query GetCurrentUserRole {
    currentUser {
      id
      role
    }
  }
`;

/**
 * __useGetCurrentUserRoleQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserRoleQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserRoleQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserRoleQuery,
    GetCurrentUserRoleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCurrentUserRoleQuery,
    GetCurrentUserRoleQueryVariables
  >(GetCurrentUserRoleDocument, options);
}
export function useGetCurrentUserRoleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserRoleQuery,
    GetCurrentUserRoleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCurrentUserRoleQuery,
    GetCurrentUserRoleQueryVariables
  >(GetCurrentUserRoleDocument, options);
}
export type GetCurrentUserRoleQueryHookResult = ReturnType<
  typeof useGetCurrentUserRoleQuery
>;
export type GetCurrentUserRoleLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserRoleLazyQuery
>;
export type GetCurrentUserRoleQueryResult = Apollo.QueryResult<
  GetCurrentUserRoleQuery,
  GetCurrentUserRoleQueryVariables
>;
export const ListAdminImpersonatorOrganizationsDocument = gql`
  query ListAdminImpersonatorOrganizations {
    listOrganizations {
      id
      name
    }
  }
`;

/**
 * __useListAdminImpersonatorOrganizationsQuery__
 *
 * To run a query within a React component, call `useListAdminImpersonatorOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAdminImpersonatorOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAdminImpersonatorOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAdminImpersonatorOrganizationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListAdminImpersonatorOrganizationsQuery,
    ListAdminImpersonatorOrganizationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListAdminImpersonatorOrganizationsQuery,
    ListAdminImpersonatorOrganizationsQueryVariables
  >(ListAdminImpersonatorOrganizationsDocument, options);
}
export function useListAdminImpersonatorOrganizationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListAdminImpersonatorOrganizationsQuery,
    ListAdminImpersonatorOrganizationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListAdminImpersonatorOrganizationsQuery,
    ListAdminImpersonatorOrganizationsQueryVariables
  >(ListAdminImpersonatorOrganizationsDocument, options);
}
export type ListAdminImpersonatorOrganizationsQueryHookResult = ReturnType<
  typeof useListAdminImpersonatorOrganizationsQuery
>;
export type ListAdminImpersonatorOrganizationsLazyQueryHookResult = ReturnType<
  typeof useListAdminImpersonatorOrganizationsLazyQuery
>;
export type ListAdminImpersonatorOrganizationsQueryResult = Apollo.QueryResult<
  ListAdminImpersonatorOrganizationsQuery,
  ListAdminImpersonatorOrganizationsQueryVariables
>;
export const CreateGrantDocument = gql`
  mutation CreateGrant($params: CreateGrantParams!) {
    createGrant(params: $params) {
      id
      funder {
        organization {
          id
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export type CreateGrantMutationFn = Apollo.MutationFunction<
  CreateGrantMutation,
  CreateGrantMutationVariables
>;

/**
 * __useCreateGrantMutation__
 *
 * To run a mutation, you first call `useCreateGrantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGrantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGrantMutation, { data, loading, error }] = useCreateGrantMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateGrantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateGrantMutation,
    CreateGrantMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateGrantMutation, CreateGrantMutationVariables>(
    CreateGrantDocument,
    options,
  );
}
export type CreateGrantMutationHookResult = ReturnType<
  typeof useCreateGrantMutation
>;
export type CreateGrantMutationResult =
  Apollo.MutationResult<CreateGrantMutation>;
export type CreateGrantMutationOptions = Apollo.BaseMutationOptions<
  CreateGrantMutation,
  CreateGrantMutationVariables
>;
export const GetCurrentUserDocument = gql`
  query GetCurrentUser {
    currentUser {
      id
      organization {
        fiscalYearStart
        id
        funders {
          id
          name
        }
        programs {
          id
          name
          achievedAmount
          missingAmount
          yearlyGoal
        }
        currentYearGoal {
          overall {
            achieved
            achievedRate
            goal
            missing
          }
        }
      }
    }
  }
`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options,
  );
}
export function useGetCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options,
  );
}
export type GetCurrentUserQueryHookResult = ReturnType<
  typeof useGetCurrentUserQuery
>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserLazyQuery
>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables
>;
export const CreateFunderDocument = gql`
  mutation CreateFunder($params: CreateFunderParams!) {
    createFunder(params: $params) {
      id
      name
    }
  }
`;
export type CreateFunderMutationFn = Apollo.MutationFunction<
  CreateFunderMutation,
  CreateFunderMutationVariables
>;

/**
 * __useCreateFunderMutation__
 *
 * To run a mutation, you first call `useCreateFunderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFunderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFunderMutation, { data, loading, error }] = useCreateFunderMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateFunderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFunderMutation,
    CreateFunderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateFunderMutation,
    CreateFunderMutationVariables
  >(CreateFunderDocument, options);
}
export type CreateFunderMutationHookResult = ReturnType<
  typeof useCreateFunderMutation
>;
export type CreateFunderMutationResult =
  Apollo.MutationResult<CreateFunderMutation>;
export type CreateFunderMutationOptions = Apollo.BaseMutationOptions<
  CreateFunderMutation,
  CreateFunderMutationVariables
>;
export const CreatePreviousAwardDocument = gql`
  mutation CreatePreviousAward(
    $amountAwarded: Decimal!
    $awardedDate: Date
    $awardedYear: Int
    $funderId: Int!
    $programId: Int!
    $submissionDate: Date
  ) {
    createPreviousAward(
      amountAwarded: $amountAwarded
      awardedDate: $awardedDate
      awardedYear: $awardedYear
      funderId: $funderId
      programId: $programId
      submissionDate: $submissionDate
    ) {
      id
    }
  }
`;
export type CreatePreviousAwardMutationFn = Apollo.MutationFunction<
  CreatePreviousAwardMutation,
  CreatePreviousAwardMutationVariables
>;

/**
 * __useCreatePreviousAwardMutation__
 *
 * To run a mutation, you first call `useCreatePreviousAwardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePreviousAwardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPreviousAwardMutation, { data, loading, error }] = useCreatePreviousAwardMutation({
 *   variables: {
 *      amountAwarded: // value for 'amountAwarded'
 *      awardedDate: // value for 'awardedDate'
 *      awardedYear: // value for 'awardedYear'
 *      funderId: // value for 'funderId'
 *      programId: // value for 'programId'
 *      submissionDate: // value for 'submissionDate'
 *   },
 * });
 */
export function useCreatePreviousAwardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePreviousAwardMutation,
    CreatePreviousAwardMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePreviousAwardMutation,
    CreatePreviousAwardMutationVariables
  >(CreatePreviousAwardDocument, options);
}
export type CreatePreviousAwardMutationHookResult = ReturnType<
  typeof useCreatePreviousAwardMutation
>;
export type CreatePreviousAwardMutationResult =
  Apollo.MutationResult<CreatePreviousAwardMutation>;
export type CreatePreviousAwardMutationOptions = Apollo.BaseMutationOptions<
  CreatePreviousAwardMutation,
  CreatePreviousAwardMutationVariables
>;
export const CreateProgramDocument = gql`
  mutation CreateProgram($params: CreateProgramParams!) {
    createProgram(params: $params) {
      id
    }
  }
`;
export type CreateProgramMutationFn = Apollo.MutationFunction<
  CreateProgramMutation,
  CreateProgramMutationVariables
>;

/**
 * __useCreateProgramMutation__
 *
 * To run a mutation, you first call `useCreateProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProgramMutation, { data, loading, error }] = useCreateProgramMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateProgramMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProgramMutation,
    CreateProgramMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProgramMutation,
    CreateProgramMutationVariables
  >(CreateProgramDocument, options);
}
export type CreateProgramMutationHookResult = ReturnType<
  typeof useCreateProgramMutation
>;
export type CreateProgramMutationResult =
  Apollo.MutationResult<CreateProgramMutation>;
export type CreateProgramMutationOptions = Apollo.BaseMutationOptions<
  CreateProgramMutation,
  CreateProgramMutationVariables
>;
export const CreateUnverifiedUserDocument = gql`
  mutation CreateUnverifiedUser(
    $email: String!
    $password: String!
    $organization: CreateOrganizationParams!
  ) {
    createUnverifiedUser(
      email: $email
      password: $password
      organization: $organization
    )
  }
`;
export type CreateUnverifiedUserMutationFn = Apollo.MutationFunction<
  CreateUnverifiedUserMutation,
  CreateUnverifiedUserMutationVariables
>;

/**
 * __useCreateUnverifiedUserMutation__
 *
 * To run a mutation, you first call `useCreateUnverifiedUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUnverifiedUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUnverifiedUserMutation, { data, loading, error }] = useCreateUnverifiedUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      organization: // value for 'organization'
 *   },
 * });
 */
export function useCreateUnverifiedUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUnverifiedUserMutation,
    CreateUnverifiedUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUnverifiedUserMutation,
    CreateUnverifiedUserMutationVariables
  >(CreateUnverifiedUserDocument, options);
}
export type CreateUnverifiedUserMutationHookResult = ReturnType<
  typeof useCreateUnverifiedUserMutation
>;
export type CreateUnverifiedUserMutationResult =
  Apollo.MutationResult<CreateUnverifiedUserMutation>;
export type CreateUnverifiedUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUnverifiedUserMutation,
  CreateUnverifiedUserMutationVariables
>;
export const DeleteFunderDocument = gql`
  mutation DeleteFunder($id: Int!) {
    deleteFunder(id: $id) {
      id
    }
  }
`;
export type DeleteFunderMutationFn = Apollo.MutationFunction<
  DeleteFunderMutation,
  DeleteFunderMutationVariables
>;

/**
 * __useDeleteFunderMutation__
 *
 * To run a mutation, you first call `useDeleteFunderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFunderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFunderMutation, { data, loading, error }] = useDeleteFunderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFunderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFunderMutation,
    DeleteFunderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteFunderMutation,
    DeleteFunderMutationVariables
  >(DeleteFunderDocument, options);
}
export type DeleteFunderMutationHookResult = ReturnType<
  typeof useDeleteFunderMutation
>;
export type DeleteFunderMutationResult =
  Apollo.MutationResult<DeleteFunderMutation>;
export type DeleteFunderMutationOptions = Apollo.BaseMutationOptions<
  DeleteFunderMutation,
  DeleteFunderMutationVariables
>;
export const DeleteProgramDocument = gql`
  mutation DeleteProgram($id: Int!) {
    deleteProgram(id: $id) {
      id
    }
  }
`;
export type DeleteProgramMutationFn = Apollo.MutationFunction<
  DeleteProgramMutation,
  DeleteProgramMutationVariables
>;

/**
 * __useDeleteProgramMutation__
 *
 * To run a mutation, you first call `useDeleteProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProgramMutation, { data, loading, error }] = useDeleteProgramMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProgramMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProgramMutation,
    DeleteProgramMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProgramMutation,
    DeleteProgramMutationVariables
  >(DeleteProgramDocument, options);
}
export type DeleteProgramMutationHookResult = ReturnType<
  typeof useDeleteProgramMutation
>;
export type DeleteProgramMutationResult =
  Apollo.MutationResult<DeleteProgramMutation>;
export type DeleteProgramMutationOptions = Apollo.BaseMutationOptions<
  DeleteProgramMutation,
  DeleteProgramMutationVariables
>;
export const LoginUserDocument = gql`
  mutation LoginUser($email: String!, $password: String!) {
    loginUser(email: $email, password: $password)
  }
`;
export type LoginUserMutationFn = Apollo.MutationFunction<
  LoginUserMutation,
  LoginUserMutationVariables
>;
///work on delete grant
// export const DeleteFunderMutationRefetchQueries = ["ListFunders"];
export const DeleteGrantDocument = gql`
  mutation DeleteGrant($id: Int!) {
    deleteGrant(id: $id) {
      id
    }
  }
`;
export type DeleteGrantMutationFn = Apollo.MutationFunction<
  DeleteGrantMutation,
  DeleteGrantMutationVariables
>;

/**
 * __useDeleteGrantMutation__
 *
 * To run a mutation, you first call `useDeleteGrantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGrantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGrantMutation, { data, loading, error }] = useDeleteGrantMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGrantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGrantMutation,
    DeleteGrantMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteGrantMutation, DeleteGrantMutationVariables>(
    DeleteGrantDocument,
    options,
  );
}
export type DeleteGrantMutationHookResult = ReturnType<
  typeof useDeleteGrantMutation
>;
export type DeleteGrantMutationResult =
  Apollo.MutationResult<DeleteGrantMutation>;
export type DeleteGrantMutationOptions = Apollo.BaseMutationOptions<
  DeleteGrantMutation,
  DeleteGrantMutationVariables
>;
///to delete a grant

//work on delete application s
// export const DeleteApplicationMutationRefetchQueries = ["ListFunders"];
export const DeleteApplicationDocument = gql`
  mutation DeleteApplication($id: Int!) {
    deleteApplication(id: $id) {
      id
    }
  }
`;
export type DeleteAppplicationMutationFn = Apollo.MutationFunction<
  DeleteApplicationMutation,
  DeleteApplicationMutationVariables
>;

/**
 * __useDeleteApplicationMutation__
 *
 * To run a mutation, you first call `useDeleteApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApplicationMutation, { data, loading, error }] = useDeleteApplicationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteApplicationMutation,
    DeleteApplicationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteApplicationMutation,
    DeleteApplicationMutationVariables
  >(DeleteApplicationDocument, options);
}
export type DeleteApplicationMutationHookResult = ReturnType<
  typeof useDeleteApplicationMutation
>;
export type DeleteApplicationMutationResult =
  Apollo.MutationResult<DeleteApplicationMutation>;
export type DeleteApplicationMutationOptions = Apollo.BaseMutationOptions<
  DeleteApplicationMutation,
  DeleteApplicationMutationVariables
>;
///to delete an application

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginUserMutation,
    LoginUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginUserMutation, LoginUserMutationVariables>(
    LoginUserDocument,
    options,
  );
}
export type LoginUserMutationHookResult = ReturnType<
  typeof useLoginUserMutation
>;
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<
  LoginUserMutation,
  LoginUserMutationVariables
>;
export const RequestResetPasswordDocument = gql`
  mutation RequestResetPassword($email: String!) {
    requestResetPassword(email: $email)
  }
`;
export type RequestResetPasswordMutationFn = Apollo.MutationFunction<
  RequestResetPasswordMutation,
  RequestResetPasswordMutationVariables
>;

/**
 * __useRequestResetPasswordMutation__
 *
 * To run a mutation, you first call `useRequestResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestResetPasswordMutation, { data, loading, error }] = useRequestResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestResetPasswordMutation,
    RequestResetPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestResetPasswordMutation,
    RequestResetPasswordMutationVariables
  >(RequestResetPasswordDocument, options);
}
export type RequestResetPasswordMutationHookResult = ReturnType<
  typeof useRequestResetPasswordMutation
>;
export type RequestResetPasswordMutationResult =
  Apollo.MutationResult<RequestResetPasswordMutation>;
export type RequestResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  RequestResetPasswordMutation,
  RequestResetPasswordMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation ResetPassword($password: String!, $token: String!) {
    resetPassword(password: $password, token: $token)
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult =
  Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const UpdateApplicationDocument = gql`
  mutation UpdateApplication($id: Int!, $params: UpdateApplicationParams!) {
    updateApplication(id: $id, params: $params) {
      id
      grant {
        funder {
          organization {
            id
          }
        }
      }
    }
  }
`;
export type UpdateApplicationMutationFn = Apollo.MutationFunction<
  UpdateApplicationMutation,
  UpdateApplicationMutationVariables
>;

/**
 * __useUpdateApplicationMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationMutation, { data, loading, error }] = useUpdateApplicationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApplicationMutation,
    UpdateApplicationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateApplicationMutation,
    UpdateApplicationMutationVariables
  >(UpdateApplicationDocument, options);
}
export type UpdateApplicationMutationHookResult = ReturnType<
  typeof useUpdateApplicationMutation
>;
export type UpdateApplicationMutationResult =
  Apollo.MutationResult<UpdateApplicationMutation>;
export type UpdateApplicationMutationOptions = Apollo.BaseMutationOptions<
  UpdateApplicationMutation,
  UpdateApplicationMutationVariables
>;
export const UpdateFunderDocument = gql`
  mutation UpdateFunder($id: Int!, $params: UpdateFunderParams!) {
    updateFunder(id: $id, params: $params) {
      id
      isPaused
    }
  }
`;
export type UpdateFunderMutationFn = Apollo.MutationFunction<
  UpdateFunderMutation,
  UpdateFunderMutationVariables
>;

/**
 * __useUpdateFunderMutation__
 *
 * To run a mutation, you first call `useUpdateFunderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFunderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFunderMutation, { data, loading, error }] = useUpdateFunderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateFunderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFunderMutation,
    UpdateFunderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateFunderMutation,
    UpdateFunderMutationVariables
  >(UpdateFunderDocument, options);
}
export type UpdateFunderMutationHookResult = ReturnType<
  typeof useUpdateFunderMutation
>;
export type UpdateFunderMutationResult =
  Apollo.MutationResult<UpdateFunderMutation>;
export type UpdateFunderMutationOptions = Apollo.BaseMutationOptions<
  UpdateFunderMutation,
  UpdateFunderMutationVariables
>;
export const UpdateOrganizationDocument = gql`
  mutation UpdateOrganization($id: Int!, $params: UpdateOrganizationParams!) {
    updateOrganization(id: $id, params: $params) {
      id
    }
  }
`;
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationMutation,
    UpdateOrganizationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrganizationMutation,
    UpdateOrganizationMutationVariables
  >(UpdateOrganizationDocument, options);
}
export type UpdateOrganizationMutationHookResult = ReturnType<
  typeof useUpdateOrganizationMutation
>;
export type UpdateOrganizationMutationResult =
  Apollo.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>;
export const UpdateReportDocument = gql`
  mutation UpdateReport($id: Int!, $params: UpdateReportParams!) {
    updateReport(id: $id, params: $params) {
      id
    }
  }
`;
export type UpdateReportMutationFn = Apollo.MutationFunction<
  UpdateReportMutation,
  UpdateReportMutationVariables
>;

/**
 * __useUpdateReportMutation__
 *
 * To run a mutation, you first call `useUpdateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReportMutation, { data, loading, error }] = useUpdateReportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateReportMutation,
    UpdateReportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateReportMutation,
    UpdateReportMutationVariables
  >(UpdateReportDocument, options);
}
export type UpdateReportMutationHookResult = ReturnType<
  typeof useUpdateReportMutation
>;
export type UpdateReportMutationResult =
  Apollo.MutationResult<UpdateReportMutation>;
export type UpdateReportMutationOptions = Apollo.BaseMutationOptions<
  UpdateReportMutation,
  UpdateReportMutationVariables
>;
export const UpdateYearlyGoalDocument = gql`
  mutation UpdateYearlyGoal($id: Int!, $params: UpdateProgramParams!) {
    updateProgram(id: $id, params: $params) {
      id
    }
  }
`;
export type UpdateYearlyGoalMutationFn = Apollo.MutationFunction<
  UpdateYearlyGoalMutation,
  UpdateYearlyGoalMutationVariables
>;

/**
 * __useUpdateYearlyGoalMutation__
 *
 * To run a mutation, you first call `useUpdateYearlyGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateYearlyGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateYearlyGoalMutation, { data, loading, error }] = useUpdateYearlyGoalMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateYearlyGoalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateYearlyGoalMutation,
    UpdateYearlyGoalMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateYearlyGoalMutation,
    UpdateYearlyGoalMutationVariables
  >(UpdateYearlyGoalDocument, options);
}
export type UpdateYearlyGoalMutationHookResult = ReturnType<
  typeof useUpdateYearlyGoalMutation
>;
export type UpdateYearlyGoalMutationResult =
  Apollo.MutationResult<UpdateYearlyGoalMutation>;
export type UpdateYearlyGoalMutationOptions = Apollo.BaseMutationOptions<
  UpdateYearlyGoalMutation,
  UpdateYearlyGoalMutationVariables
>;
export const GetApplicationDocument = gql`
  query GetApplication($id: Int!) {
    getApplication(id: $id) {
      id
      award {
        id
        amountAwarded
        receivementDate
      }
      status
      isSubmittedAsIneligible
      ineligibleReason
      deadline
      declined
      deadlineType
      declinedDetails {
        declinedDate
        declinedReason
      }
      submission {
        id
        submissionDate
        amountRequested
      }
      reports {
        id
        urgency
        deadline
      }
    }
  }
`;

/**
 * __useGetApplicationQuery__
 *
 * To run a query within a React component, call `useGetApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetApplicationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetApplicationQuery,
    GetApplicationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetApplicationQuery, GetApplicationQueryVariables>(
    GetApplicationDocument,
    options,
  );
}
export function useGetApplicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApplicationQuery,
    GetApplicationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetApplicationQuery, GetApplicationQueryVariables>(
    GetApplicationDocument,
    options,
  );
}
export type GetApplicationQueryHookResult = ReturnType<
  typeof useGetApplicationQuery
>;
export type GetApplicationLazyQueryHookResult = ReturnType<
  typeof useGetApplicationLazyQuery
>;
export type GetApplicationQueryResult = Apollo.QueryResult<
  GetApplicationQuery,
  GetApplicationQueryVariables
>;
export const GetAwardsByYearDocument = gql`
  query GetAwardsByYear($startAt: Date) {
    currentUser {
      id
      organization {
        id
        awardsByYear(startAt: $startAt) {
          total
          year
        }
      }
    }
  }
`;

/**
 * __useGetAwardsByYearQuery__
 *
 * To run a query within a React component, call `useGetAwardsByYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAwardsByYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAwardsByYearQuery({
 *   variables: {
 *      startAt: // value for 'startAt'
 *   },
 * });
 */
export function useGetAwardsByYearQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAwardsByYearQuery,
    GetAwardsByYearQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAwardsByYearQuery, GetAwardsByYearQueryVariables>(
    GetAwardsByYearDocument,
    options,
  );
}
export function useGetAwardsByYearLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAwardsByYearQuery,
    GetAwardsByYearQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAwardsByYearQuery,
    GetAwardsByYearQueryVariables
  >(GetAwardsByYearDocument, options);
}
export type GetAwardsByYearQueryHookResult = ReturnType<
  typeof useGetAwardsByYearQuery
>;
export type GetAwardsByYearLazyQueryHookResult = ReturnType<
  typeof useGetAwardsByYearLazyQuery
>;
export type GetAwardsByYearQueryResult = Apollo.QueryResult<
  GetAwardsByYearQuery,
  GetAwardsByYearQueryVariables
>;
export const GetCurrentYearGoalDocument = gql`
  query GetCurrentYearGoal {
    currentUser {
      id
      organization {
        id
        fiscalYearStart
        currentYearGoal {
          overall {
            achieved
            achievedRate
            goal
            missing
          }
          programs {
            id
            name
            achievedAmount
            missingAmount
            yearlyGoal
          }
        }
      }
    }
  }
`;

/**
 * __useGetCurrentYearGoalQuery__
 *
 * To run a query within a React component, call `useGetCurrentYearGoalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentYearGoalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentYearGoalQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentYearGoalQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentYearGoalQuery,
    GetCurrentYearGoalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCurrentYearGoalQuery,
    GetCurrentYearGoalQueryVariables
  >(GetCurrentYearGoalDocument, options);
}
export function useGetCurrentYearGoalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentYearGoalQuery,
    GetCurrentYearGoalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCurrentYearGoalQuery,
    GetCurrentYearGoalQueryVariables
  >(GetCurrentYearGoalDocument, options);
}
export type GetCurrentYearGoalQueryHookResult = ReturnType<
  typeof useGetCurrentYearGoalQuery
>;
export type GetCurrentYearGoalLazyQueryHookResult = ReturnType<
  typeof useGetCurrentYearGoalLazyQuery
>;
export type GetCurrentYearGoalQueryResult = Apollo.QueryResult<
  GetCurrentYearGoalQuery,
  GetCurrentYearGoalQueryVariables
>;
export const GetFunderDocument = gql`
  query GetFunder($id: Int!) {
    getFunder(id: $id) {
      id
      name
      keyContacts
      email
      phone
      type
      website
      howToApply
      complexityScore
      notes
      isPaused
      location {
        id
        city
        complement
        state
        streetAddress
        zipcode
      }
      grants {
        applications {
          id
          status
          deadline
          isSubmittedAsIneligible
          ineligibleReason
          submission {
            id
            submissionDate
            amountRequested
          }
          declinedDetails {
            declinedReason
            declinedDate
          }
          award {
            id
            isPrecisionByYear
            receivementDate
            amountAwarded
          }
          grant {
            program {
              id
              name
            }
          }
        }
      }
      awards {
        id
        isPrecisionByYear
        receivementDate
        amountAwarded
        program {
          id
          name
        }
        grantApplication {
          id
          submission {
            id
            submissionDate
            amountRequested
          }
        }
      }
    }
  }
`;

/**
 * __useGetFunderQuery__
 *
 * To run a query within a React component, call `useGetFunderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFunderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFunderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFunderQuery(
  baseOptions: Apollo.QueryHookOptions<GetFunderQuery, GetFunderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFunderQuery, GetFunderQueryVariables>(
    GetFunderDocument,
    options,
  );
}
export function useGetFunderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFunderQuery,
    GetFunderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFunderQuery, GetFunderQueryVariables>(
    GetFunderDocument,
    options,
  );
}
export type GetFunderQueryHookResult = ReturnType<typeof useGetFunderQuery>;
export type GetFunderLazyQueryHookResult = ReturnType<
  typeof useGetFunderLazyQuery
>;
export type GetFunderQueryResult = Apollo.QueryResult<
  GetFunderQuery,
  GetFunderQueryVariables
>;
export const GetFundersByTypeDocument = gql`
  query GetFundersByType($startAt: Date, $endAt: Date) {
    currentUser {
      id
      organization {
        id
        funderTypesCount(startAt: $startAt, endAt: $endAt) {
          corporate
          foundation
          government
          other
        }
      }
    }
  }
`;

/**
 * __useGetFundersByTypeQuery__
 *
 * To run a query within a React component, call `useGetFundersByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFundersByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFundersByTypeQuery({
 *   variables: {
 *      startAt: // value for 'startAt'
 *      endAt: // value for 'endAt'
 *   },
 * });
 */
export function useGetFundersByTypeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFundersByTypeQuery,
    GetFundersByTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFundersByTypeQuery, GetFundersByTypeQueryVariables>(
    GetFundersByTypeDocument,
    options,
  );
}
export function useGetFundersByTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFundersByTypeQuery,
    GetFundersByTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFundersByTypeQuery,
    GetFundersByTypeQueryVariables
  >(GetFundersByTypeDocument, options);
}
export type GetFundersByTypeQueryHookResult = ReturnType<
  typeof useGetFundersByTypeQuery
>;
export type GetFundersByTypeLazyQueryHookResult = ReturnType<
  typeof useGetFundersByTypeLazyQuery
>;
export type GetFundersByTypeQueryResult = Apollo.QueryResult<
  GetFundersByTypeQuery,
  GetFundersByTypeQueryVariables
>;
export const GetImpersonatedOrganizationAwardsByYearDocument = gql`
  query GetImpersonatedOrganizationAwardsByYear(
    $startAt: Date
    $organizationId: Int!
  ) {
    getOrganization(id: $organizationId) {
      id
      name
      awardsByYear(startAt: $startAt) {
        total
        year
      }
    }
  }
`;

/**
 * __useGetImpersonatedOrganizationAwardsByYearQuery__
 *
 * To run a query within a React component, call `useGetImpersonatedOrganizationAwardsByYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImpersonatedOrganizationAwardsByYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImpersonatedOrganizationAwardsByYearQuery({
 *   variables: {
 *      startAt: // value for 'startAt'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetImpersonatedOrganizationAwardsByYearQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetImpersonatedOrganizationAwardsByYearQuery,
    GetImpersonatedOrganizationAwardsByYearQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetImpersonatedOrganizationAwardsByYearQuery,
    GetImpersonatedOrganizationAwardsByYearQueryVariables
  >(GetImpersonatedOrganizationAwardsByYearDocument, options);
}
export function useGetImpersonatedOrganizationAwardsByYearLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetImpersonatedOrganizationAwardsByYearQuery,
    GetImpersonatedOrganizationAwardsByYearQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetImpersonatedOrganizationAwardsByYearQuery,
    GetImpersonatedOrganizationAwardsByYearQueryVariables
  >(GetImpersonatedOrganizationAwardsByYearDocument, options);
}
export type GetImpersonatedOrganizationAwardsByYearQueryHookResult = ReturnType<
  typeof useGetImpersonatedOrganizationAwardsByYearQuery
>;
export type GetImpersonatedOrganizationAwardsByYearLazyQueryHookResult =
  ReturnType<typeof useGetImpersonatedOrganizationAwardsByYearLazyQuery>;
export type GetImpersonatedOrganizationAwardsByYearQueryResult =
  Apollo.QueryResult<
    GetImpersonatedOrganizationAwardsByYearQuery,
    GetImpersonatedOrganizationAwardsByYearQueryVariables
  >;
export const GetImpersonatedOrganizationCurrentYearGoalDocument = gql`
  query GetImpersonatedOrganizationCurrentYearGoal($organizationId: Int!) {
    getOrganization(id: $organizationId) {
      id
      name
      fiscalYearStart
      currentYearGoal {
        overall {
          achieved
          achievedRate
          goal
          missing
        }
        programs {
          id
          name
          achievedAmount
          missingAmount
          yearlyGoal
        }
      }
    }
  }
`;

/**
 * __useGetImpersonatedOrganizationCurrentYearGoalQuery__
 *
 * To run a query within a React component, call `useGetImpersonatedOrganizationCurrentYearGoalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImpersonatedOrganizationCurrentYearGoalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImpersonatedOrganizationCurrentYearGoalQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetImpersonatedOrganizationCurrentYearGoalQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetImpersonatedOrganizationCurrentYearGoalQuery,
    GetImpersonatedOrganizationCurrentYearGoalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetImpersonatedOrganizationCurrentYearGoalQuery,
    GetImpersonatedOrganizationCurrentYearGoalQueryVariables
  >(GetImpersonatedOrganizationCurrentYearGoalDocument, options);
}
export function useGetImpersonatedOrganizationCurrentYearGoalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetImpersonatedOrganizationCurrentYearGoalQuery,
    GetImpersonatedOrganizationCurrentYearGoalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetImpersonatedOrganizationCurrentYearGoalQuery,
    GetImpersonatedOrganizationCurrentYearGoalQueryVariables
  >(GetImpersonatedOrganizationCurrentYearGoalDocument, options);
}
export type GetImpersonatedOrganizationCurrentYearGoalQueryHookResult =
  ReturnType<typeof useGetImpersonatedOrganizationCurrentYearGoalQuery>;
export type GetImpersonatedOrganizationCurrentYearGoalLazyQueryHookResult =
  ReturnType<typeof useGetImpersonatedOrganizationCurrentYearGoalLazyQuery>;
export type GetImpersonatedOrganizationCurrentYearGoalQueryResult =
  Apollo.QueryResult<
    GetImpersonatedOrganizationCurrentYearGoalQuery,
    GetImpersonatedOrganizationCurrentYearGoalQueryVariables
  >;
export const GetImpersonatedOrganizationFiscalYearDocument = gql`
  query GetImpersonatedOrganizationFiscalYear($organizationId: Int!) {
    getOrganization(id: $organizationId) {
      id
      name
      currentFiscalYear
      fiscalYearStart
    }
  }
`;

/**
 * __useGetImpersonatedOrganizationFiscalYearQuery__
 *
 * To run a query within a React component, call `useGetImpersonatedOrganizationFiscalYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImpersonatedOrganizationFiscalYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImpersonatedOrganizationFiscalYearQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetImpersonatedOrganizationFiscalYearQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetImpersonatedOrganizationFiscalYearQuery,
    GetImpersonatedOrganizationFiscalYearQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetImpersonatedOrganizationFiscalYearQuery,
    GetImpersonatedOrganizationFiscalYearQueryVariables
  >(GetImpersonatedOrganizationFiscalYearDocument, options);
}
export function useGetImpersonatedOrganizationFiscalYearLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetImpersonatedOrganizationFiscalYearQuery,
    GetImpersonatedOrganizationFiscalYearQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetImpersonatedOrganizationFiscalYearQuery,
    GetImpersonatedOrganizationFiscalYearQueryVariables
  >(GetImpersonatedOrganizationFiscalYearDocument, options);
}
export type GetImpersonatedOrganizationFiscalYearQueryHookResult = ReturnType<
  typeof useGetImpersonatedOrganizationFiscalYearQuery
>;
export type GetImpersonatedOrganizationFiscalYearLazyQueryHookResult =
  ReturnType<typeof useGetImpersonatedOrganizationFiscalYearLazyQuery>;
export type GetImpersonatedOrganizationFiscalYearQueryResult =
  Apollo.QueryResult<
    GetImpersonatedOrganizationFiscalYearQuery,
    GetImpersonatedOrganizationFiscalYearQueryVariables
  >;
export const GetImpersonatedOrganizationFundersByTypeDocument = gql`
  query GetImpersonatedOrganizationFundersByType(
    $startAt: Date
    $endAt: Date
    $organizationId: Int!
  ) {
    getOrganization(id: $organizationId) {
      id
      name
      funderTypesCount(startAt: $startAt, endAt: $endAt) {
        corporate
        foundation
        government
        other
      }
    }
  }
`;

/**
 * __useGetImpersonatedOrganizationFundersByTypeQuery__
 *
 * To run a query within a React component, call `useGetImpersonatedOrganizationFundersByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImpersonatedOrganizationFundersByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImpersonatedOrganizationFundersByTypeQuery({
 *   variables: {
 *      startAt: // value for 'startAt'
 *      endAt: // value for 'endAt'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetImpersonatedOrganizationFundersByTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetImpersonatedOrganizationFundersByTypeQuery,
    GetImpersonatedOrganizationFundersByTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetImpersonatedOrganizationFundersByTypeQuery,
    GetImpersonatedOrganizationFundersByTypeQueryVariables
  >(GetImpersonatedOrganizationFundersByTypeDocument, options);
}
export function useGetImpersonatedOrganizationFundersByTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetImpersonatedOrganizationFundersByTypeQuery,
    GetImpersonatedOrganizationFundersByTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetImpersonatedOrganizationFundersByTypeQuery,
    GetImpersonatedOrganizationFundersByTypeQueryVariables
  >(GetImpersonatedOrganizationFundersByTypeDocument, options);
}
export type GetImpersonatedOrganizationFundersByTypeQueryHookResult =
  ReturnType<typeof useGetImpersonatedOrganizationFundersByTypeQuery>;
export type GetImpersonatedOrganizationFundersByTypeLazyQueryHookResult =
  ReturnType<typeof useGetImpersonatedOrganizationFundersByTypeLazyQuery>;
export type GetImpersonatedOrganizationFundersByTypeQueryResult =
  Apollo.QueryResult<
    GetImpersonatedOrganizationFundersByTypeQuery,
    GetImpersonatedOrganizationFundersByTypeQueryVariables
  >;
export const GetImpersonatedOrganizationLargestFundersDocument = gql`
  query GetImpersonatedOrganizationLargestFunders(
    $type: FunderTypeEnum
    $startAt: Date
    $endAt: Date
    $organizationId: Int!
  ) {
    getOrganization(id: $organizationId) {
      id
      name
      programs {
        id
        name
      }
      largestFunders(startAt: $startAt, endAt: $endAt, type: $type) {
        overall {
          id
          name
          totalAwardAmount(startAt: $startAt, endAt: $endAt)
        }
        programFunders {
          funders {
            id
            name
            totalAwardAmount(startAt: $startAt, endAt: $endAt)
          }
          program {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useGetImpersonatedOrganizationLargestFundersQuery__
 *
 * To run a query within a React component, call `useGetImpersonatedOrganizationLargestFundersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImpersonatedOrganizationLargestFundersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImpersonatedOrganizationLargestFundersQuery({
 *   variables: {
 *      type: // value for 'type'
 *      startAt: // value for 'startAt'
 *      endAt: // value for 'endAt'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetImpersonatedOrganizationLargestFundersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetImpersonatedOrganizationLargestFundersQuery,
    GetImpersonatedOrganizationLargestFundersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetImpersonatedOrganizationLargestFundersQuery,
    GetImpersonatedOrganizationLargestFundersQueryVariables
  >(GetImpersonatedOrganizationLargestFundersDocument, options);
}
export function useGetImpersonatedOrganizationLargestFundersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetImpersonatedOrganizationLargestFundersQuery,
    GetImpersonatedOrganizationLargestFundersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetImpersonatedOrganizationLargestFundersQuery,
    GetImpersonatedOrganizationLargestFundersQueryVariables
  >(GetImpersonatedOrganizationLargestFundersDocument, options);
}
export type GetImpersonatedOrganizationLargestFundersQueryHookResult =
  ReturnType<typeof useGetImpersonatedOrganizationLargestFundersQuery>;
export type GetImpersonatedOrganizationLargestFundersLazyQueryHookResult =
  ReturnType<typeof useGetImpersonatedOrganizationLargestFundersLazyQuery>;
export type GetImpersonatedOrganizationLargestFundersQueryResult =
  Apollo.QueryResult<
    GetImpersonatedOrganizationLargestFundersQuery,
    GetImpersonatedOrganizationLargestFundersQueryVariables
  >;
export const GetImpersonatedOrganizationOldestAwardDateDocument = gql`
  query GetImpersonatedOrganizationOldestAwardDate($organizationId: Int!) {
    getOrganization(id: $organizationId) {
      id
      name
      fiscalYearStart
      oldestAward {
        id
        receivementDate
      }
    }
  }
`;

/**
 * __useGetImpersonatedOrganizationOldestAwardDateQuery__
 *
 * To run a query within a React component, call `useGetImpersonatedOrganizationOldestAwardDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImpersonatedOrganizationOldestAwardDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImpersonatedOrganizationOldestAwardDateQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetImpersonatedOrganizationOldestAwardDateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetImpersonatedOrganizationOldestAwardDateQuery,
    GetImpersonatedOrganizationOldestAwardDateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetImpersonatedOrganizationOldestAwardDateQuery,
    GetImpersonatedOrganizationOldestAwardDateQueryVariables
  >(GetImpersonatedOrganizationOldestAwardDateDocument, options);
}
export function useGetImpersonatedOrganizationOldestAwardDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetImpersonatedOrganizationOldestAwardDateQuery,
    GetImpersonatedOrganizationOldestAwardDateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetImpersonatedOrganizationOldestAwardDateQuery,
    GetImpersonatedOrganizationOldestAwardDateQueryVariables
  >(GetImpersonatedOrganizationOldestAwardDateDocument, options);
}
export type GetImpersonatedOrganizationOldestAwardDateQueryHookResult =
  ReturnType<typeof useGetImpersonatedOrganizationOldestAwardDateQuery>;
export type GetImpersonatedOrganizationOldestAwardDateLazyQueryHookResult =
  ReturnType<typeof useGetImpersonatedOrganizationOldestAwardDateLazyQuery>;
export type GetImpersonatedOrganizationOldestAwardDateQueryResult =
  Apollo.QueryResult<
    GetImpersonatedOrganizationOldestAwardDateQuery,
    GetImpersonatedOrganizationOldestAwardDateQueryVariables
  >;
export const GetImpersonatedOrganizationProgramsDocument = gql`
  query GetImpersonatedOrganizationPrograms($organizationId: Int!) {
    getOrganization(id: $organizationId) {
      id
      name
      funders {
        id
        name
      }
      programs {
        id
        name
      }
    }
  }
`;

/**
 * __useGetImpersonatedOrganizationProgramsQuery__
 *
 * To run a query within a React component, call `useGetImpersonatedOrganizationProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImpersonatedOrganizationProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImpersonatedOrganizationProgramsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetImpersonatedOrganizationProgramsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetImpersonatedOrganizationProgramsQuery,
    GetImpersonatedOrganizationProgramsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetImpersonatedOrganizationProgramsQuery,
    GetImpersonatedOrganizationProgramsQueryVariables
  >(GetImpersonatedOrganizationProgramsDocument, options);
}
export function useGetImpersonatedOrganizationProgramsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetImpersonatedOrganizationProgramsQuery,
    GetImpersonatedOrganizationProgramsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetImpersonatedOrganizationProgramsQuery,
    GetImpersonatedOrganizationProgramsQueryVariables
  >(GetImpersonatedOrganizationProgramsDocument, options);
}
export type GetImpersonatedOrganizationProgramsQueryHookResult = ReturnType<
  typeof useGetImpersonatedOrganizationProgramsQuery
>;
export type GetImpersonatedOrganizationProgramsLazyQueryHookResult = ReturnType<
  typeof useGetImpersonatedOrganizationProgramsLazyQuery
>;
export type GetImpersonatedOrganizationProgramsQueryResult = Apollo.QueryResult<
  GetImpersonatedOrganizationProgramsQuery,
  GetImpersonatedOrganizationProgramsQueryVariables
>;
export const GetImpersonatedOrganizationDocument = gql`
  query GetImpersonatedOrganization($organizationId: Int!) {
    getOrganization(id: $organizationId) {
      id
      name
    }
  }
`;

/**
 * __useGetImpersonatedOrganizationQuery__
 *
 * To run a query within a React component, call `useGetImpersonatedOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImpersonatedOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImpersonatedOrganizationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetImpersonatedOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetImpersonatedOrganizationQuery,
    GetImpersonatedOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetImpersonatedOrganizationQuery,
    GetImpersonatedOrganizationQueryVariables
  >(GetImpersonatedOrganizationDocument, options);
}
export function useGetImpersonatedOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetImpersonatedOrganizationQuery,
    GetImpersonatedOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetImpersonatedOrganizationQuery,
    GetImpersonatedOrganizationQueryVariables
  >(GetImpersonatedOrganizationDocument, options);
}
export type GetImpersonatedOrganizationQueryHookResult = ReturnType<
  typeof useGetImpersonatedOrganizationQuery
>;
export type GetImpersonatedOrganizationLazyQueryHookResult = ReturnType<
  typeof useGetImpersonatedOrganizationLazyQuery
>;
export type GetImpersonatedOrganizationQueryResult = Apollo.QueryResult<
  GetImpersonatedOrganizationQuery,
  GetImpersonatedOrganizationQueryVariables
>;
export const GetLargestFundersDocument = gql`
  query GetLargestFunders($type: FunderTypeEnum, $startAt: Date, $endAt: Date) {
    currentUser {
      id
      organization {
        id
        programs {
          id
          name
        }
        largestFunders(startAt: $startAt, endAt: $endAt, type: $type) {
          overall {
            id
            name
            totalAwardAmount(startAt: $startAt, endAt: $endAt)
          }
          programFunders {
            funders {
              id
              name
              totalAwardAmount(startAt: $startAt, endAt: $endAt)
            }
            program {
              id
              name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetLargestFundersQuery__
 *
 * To run a query within a React component, call `useGetLargestFundersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLargestFundersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLargestFundersQuery({
 *   variables: {
 *      type: // value for 'type'
 *      startAt: // value for 'startAt'
 *      endAt: // value for 'endAt'
 *   },
 * });
 */
export function useGetLargestFundersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLargestFundersQuery,
    GetLargestFundersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLargestFundersQuery,
    GetLargestFundersQueryVariables
  >(GetLargestFundersDocument, options);
}
export function useGetLargestFundersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLargestFundersQuery,
    GetLargestFundersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLargestFundersQuery,
    GetLargestFundersQueryVariables
  >(GetLargestFundersDocument, options);
}
export type GetLargestFundersQueryHookResult = ReturnType<
  typeof useGetLargestFundersQuery
>;
export type GetLargestFundersLazyQueryHookResult = ReturnType<
  typeof useGetLargestFundersLazyQuery
>;
export type GetLargestFundersQueryResult = Apollo.QueryResult<
  GetLargestFundersQuery,
  GetLargestFundersQueryVariables
>;
export const GetOldestAwardDateDocument = gql`
  query GetOldestAwardDate {
    currentUser {
      id
      organization {
        id
        fiscalYearStart
        oldestAward {
          id
          receivementDate
        }
      }
    }
  }
`;

/**
 * __useGetOldestAwardDateQuery__
 *
 * To run a query within a React component, call `useGetOldestAwardDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOldestAwardDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOldestAwardDateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOldestAwardDateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOldestAwardDateQuery,
    GetOldestAwardDateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOldestAwardDateQuery,
    GetOldestAwardDateQueryVariables
  >(GetOldestAwardDateDocument, options);
}
export function useGetOldestAwardDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOldestAwardDateQuery,
    GetOldestAwardDateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOldestAwardDateQuery,
    GetOldestAwardDateQueryVariables
  >(GetOldestAwardDateDocument, options);
}
export type GetOldestAwardDateQueryHookResult = ReturnType<
  typeof useGetOldestAwardDateQuery
>;
export type GetOldestAwardDateLazyQueryHookResult = ReturnType<
  typeof useGetOldestAwardDateLazyQuery
>;
export type GetOldestAwardDateQueryResult = Apollo.QueryResult<
  GetOldestAwardDateQuery,
  GetOldestAwardDateQueryVariables
>;
export const GetOrganizationFiscalYearDocument = gql`
  query GetOrganizationFiscalYear {
    currentUser {
      id
      organization {
        id
        currentFiscalYear
        fiscalYearStart
      }
    }
  }
`;

/**
 * __useGetOrganizationFiscalYearQuery__
 *
 * To run a query within a React component, call `useGetOrganizationFiscalYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationFiscalYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationFiscalYearQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationFiscalYearQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrganizationFiscalYearQuery,
    GetOrganizationFiscalYearQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOrganizationFiscalYearQuery,
    GetOrganizationFiscalYearQueryVariables
  >(GetOrganizationFiscalYearDocument, options);
}
export function useGetOrganizationFiscalYearLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganizationFiscalYearQuery,
    GetOrganizationFiscalYearQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrganizationFiscalYearQuery,
    GetOrganizationFiscalYearQueryVariables
  >(GetOrganizationFiscalYearDocument, options);
}
export type GetOrganizationFiscalYearQueryHookResult = ReturnType<
  typeof useGetOrganizationFiscalYearQuery
>;
export type GetOrganizationFiscalYearLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationFiscalYearLazyQuery
>;
export type GetOrganizationFiscalYearQueryResult = Apollo.QueryResult<
  GetOrganizationFiscalYearQuery,
  GetOrganizationFiscalYearQueryVariables
>;
export const GetOrganizationProgramsDocument = gql`
  query GetOrganizationPrograms {
    currentUser {
      id
      organization {
        id
        funders {
          id
          name
        }
        programs {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGetOrganizationProgramsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationProgramsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationProgramsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrganizationProgramsQuery,
    GetOrganizationProgramsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOrganizationProgramsQuery,
    GetOrganizationProgramsQueryVariables
  >(GetOrganizationProgramsDocument, options);
}
export function useGetOrganizationProgramsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganizationProgramsQuery,
    GetOrganizationProgramsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrganizationProgramsQuery,
    GetOrganizationProgramsQueryVariables
  >(GetOrganizationProgramsDocument, options);
}
export type GetOrganizationProgramsQueryHookResult = ReturnType<
  typeof useGetOrganizationProgramsQuery
>;
export type GetOrganizationProgramsLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationProgramsLazyQuery
>;
export type GetOrganizationProgramsQueryResult = Apollo.QueryResult<
  GetOrganizationProgramsQuery,
  GetOrganizationProgramsQueryVariables
>;
export const GetOrganizationDocument = gql`
  query GetOrganization {
    currentUser {
      id
      organization {
        id
      }
    }
  }
`;

/**
 * __useGetOrganizationQuery__
 *
 * To run a query within a React component, call `useGetOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrganizationQuery,
    GetOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(
    GetOrganizationDocument,
    options,
  );
}
export function useGetOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganizationQuery,
    GetOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrganizationQuery,
    GetOrganizationQueryVariables
  >(GetOrganizationDocument, options);
}
export type GetOrganizationQueryHookResult = ReturnType<
  typeof useGetOrganizationQuery
>;
export type GetOrganizationLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationLazyQuery
>;
export type GetOrganizationQueryResult = Apollo.QueryResult<
  GetOrganizationQuery,
  GetOrganizationQueryVariables
>;
export const GetReportDocument = gql`
  query GetReport($id: Int!) {
    getReport(id: $id) {
      id
      deadline
      submitted
      submissionDate
      urgency
      grantApplication {
        id
        grant {
          id
          funder {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useGetReportQuery__
 *
 * To run a query within a React component, call `useGetReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetReportQuery(
  baseOptions: Apollo.QueryHookOptions<GetReportQuery, GetReportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReportQuery, GetReportQueryVariables>(
    GetReportDocument,
    options,
  );
}
export function useGetReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReportQuery,
    GetReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReportQuery, GetReportQueryVariables>(
    GetReportDocument,
    options,
  );
}
export type GetReportQueryHookResult = ReturnType<typeof useGetReportQuery>;
export type GetReportLazyQueryHookResult = ReturnType<
  typeof useGetReportLazyQuery
>;
export type GetReportQueryResult = Apollo.QueryResult<
  GetReportQuery,
  GetReportQueryVariables
>;
export const GetUserAuthorizationDocument = gql`
  query GetUserAuthorization {
    currentUser {
      id
      authorized
      role
    }
  }
`;

/**
 * __useGetUserAuthorizationQuery__
 *
 * To run a query within a React component, call `useGetUserAuthorizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAuthorizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAuthorizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserAuthorizationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserAuthorizationQuery,
    GetUserAuthorizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserAuthorizationQuery,
    GetUserAuthorizationQueryVariables
  >(GetUserAuthorizationDocument, options);
}
export function useGetUserAuthorizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserAuthorizationQuery,
    GetUserAuthorizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserAuthorizationQuery,
    GetUserAuthorizationQueryVariables
  >(GetUserAuthorizationDocument, options);
}
export type GetUserAuthorizationQueryHookResult = ReturnType<
  typeof useGetUserAuthorizationQuery
>;
export type GetUserAuthorizationLazyQueryHookResult = ReturnType<
  typeof useGetUserAuthorizationLazyQuery
>;
export type GetUserAuthorizationQueryResult = Apollo.QueryResult<
  GetUserAuthorizationQuery,
  GetUserAuthorizationQueryVariables
>;
export const ListApplicationsDocument = gql`
  query ListApplications(
    $filters: FilterApplicationParams!
    $pagination: PaginationParams
  ) {
    listApplications(filters: $filters, pagination: $pagination) {
      id
      award {
        id
        amountAwarded
        receivementDate
      }
      grant {
        id
        frequency
        program {
          id
          name
        }
        funder {
          id
          name
          lastAward {
            id
            receivementDate
          }
        }
        responseAverage
      }
      status
      isSubmittedAsIneligible
      ineligibleReason
      deadline
      deadlineType
      declined
      declinedDetails {
        declinedDate
        declinedReason
      }
      submission {
        id
        submissionDate
        amountRequested
      }
      reports {
        id
        urgency
        deadline
      }
    }
  }
`;

/**
 * __useListApplicationsQuery__
 *
 * To run a query within a React component, call `useListApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListApplicationsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useListApplicationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListApplicationsQuery,
    ListApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListApplicationsQuery, ListApplicationsQueryVariables>(
    ListApplicationsDocument,
    options,
  );
}
export function useListApplicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListApplicationsQuery,
    ListApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListApplicationsQuery,
    ListApplicationsQueryVariables
  >(ListApplicationsDocument, options);
}
export type ListApplicationsQueryHookResult = ReturnType<
  typeof useListApplicationsQuery
>;
export type ListApplicationsLazyQueryHookResult = ReturnType<
  typeof useListApplicationsLazyQuery
>;
export type ListApplicationsQueryResult = Apollo.QueryResult<
  ListApplicationsQuery,
  ListApplicationsQueryVariables
>;
export const ListFundersDocument = gql`
  query ListFunders {
    currentUser {
      id
      organization {
        id
        funders {
          id
          name
          email
          type
          website
          totalAwardAmount
        }
      }
    }
  }
`;

/**
 * __useListFundersQuery__
 *
 * To run a query within a React component, call `useListFundersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFundersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFundersQuery({
 *   variables: {
 *   },
 * });
 */
export function useListFundersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListFundersQuery,
    ListFundersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListFundersQuery, ListFundersQueryVariables>(
    ListFundersDocument,
    options,
  );
}
export function useListFundersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListFundersQuery,
    ListFundersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListFundersQuery, ListFundersQueryVariables>(
    ListFundersDocument,
    options,
  );
}
export type ListFundersQueryHookResult = ReturnType<typeof useListFundersQuery>;
export type ListFundersLazyQueryHookResult = ReturnType<
  typeof useListFundersLazyQuery
>;
export type ListFundersQueryResult = Apollo.QueryResult<
  ListFundersQuery,
  ListFundersQueryVariables
>;
export const ListImpersonatedOrganizationFundersDocument = gql`
  query ListImpersonatedOrganizationFunders($organizationId: Int!) {
    getOrganization(id: $organizationId) {
      id
      name
      funders {
        id
        name
        email
        type
        website
        totalAwardAmount
      }
    }
  }
`;

/**
 * __useListImpersonatedOrganizationFundersQuery__
 *
 * To run a query within a React component, call `useListImpersonatedOrganizationFundersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListImpersonatedOrganizationFundersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListImpersonatedOrganizationFundersQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useListImpersonatedOrganizationFundersQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListImpersonatedOrganizationFundersQuery,
    ListImpersonatedOrganizationFundersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListImpersonatedOrganizationFundersQuery,
    ListImpersonatedOrganizationFundersQueryVariables
  >(ListImpersonatedOrganizationFundersDocument, options);
}
export function useListImpersonatedOrganizationFundersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListImpersonatedOrganizationFundersQuery,
    ListImpersonatedOrganizationFundersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListImpersonatedOrganizationFundersQuery,
    ListImpersonatedOrganizationFundersQueryVariables
  >(ListImpersonatedOrganizationFundersDocument, options);
}
export type ListImpersonatedOrganizationFundersQueryHookResult = ReturnType<
  typeof useListImpersonatedOrganizationFundersQuery
>;
export type ListImpersonatedOrganizationFundersLazyQueryHookResult = ReturnType<
  typeof useListImpersonatedOrganizationFundersLazyQuery
>;
export type ListImpersonatedOrganizationFundersQueryResult = Apollo.QueryResult<
  ListImpersonatedOrganizationFundersQuery,
  ListImpersonatedOrganizationFundersQueryVariables
>;
export const ListReportsDocument = gql`
  query ListReports(
    $filters: FilterReportParams!
    $pagination: PaginationParams
  ) {
    listReports(filters: $filters, pagination: $pagination) {
      id
      deadline
      submissionDate
      submitted
      urgency
      grantApplication {
        id
        award {
          id
          amountAwarded
        }
        grant {
          id
          funder {
            id
            name
          }
          program {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useListReportsQuery__
 *
 * To run a query within a React component, call `useListReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListReportsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useListReportsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListReportsQuery,
    ListReportsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListReportsQuery, ListReportsQueryVariables>(
    ListReportsDocument,
    options,
  );
}
export function useListReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListReportsQuery,
    ListReportsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListReportsQuery, ListReportsQueryVariables>(
    ListReportsDocument,
    options,
  );
}
export type ListReportsQueryHookResult = ReturnType<typeof useListReportsQuery>;
export type ListReportsLazyQueryHookResult = ReturnType<
  typeof useListReportsLazyQuery
>;
export type ListReportsQueryResult = Apollo.QueryResult<
  ListReportsQuery,
  ListReportsQueryVariables
>;
export const GetBaseCurrentOrganizationDocument = gql`
  query GetBaseCurrentOrganization {
    currentUser {
      id
      organization {
        id
      }
    }
  }
`;

/**
 * __useGetBaseCurrentOrganizationQuery__
 *
 * To run a query within a React component, call `useGetBaseCurrentOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaseCurrentOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaseCurrentOrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBaseCurrentOrganizationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBaseCurrentOrganizationQuery,
    GetBaseCurrentOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBaseCurrentOrganizationQuery,
    GetBaseCurrentOrganizationQueryVariables
  >(GetBaseCurrentOrganizationDocument, options);
}
export function useGetBaseCurrentOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBaseCurrentOrganizationQuery,
    GetBaseCurrentOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBaseCurrentOrganizationQuery,
    GetBaseCurrentOrganizationQueryVariables
  >(GetBaseCurrentOrganizationDocument, options);
}
export type GetBaseCurrentOrganizationQueryHookResult = ReturnType<
  typeof useGetBaseCurrentOrganizationQuery
>;
export type GetBaseCurrentOrganizationLazyQueryHookResult = ReturnType<
  typeof useGetBaseCurrentOrganizationLazyQuery
>;
export type GetBaseCurrentOrganizationQueryResult = Apollo.QueryResult<
  GetBaseCurrentOrganizationQuery,
  GetBaseCurrentOrganizationQueryVariables
>;
export const GetBaseImpersonatedOrganizationDocument = gql`
  query GetBaseImpersonatedOrganization($organizationId: Int!) {
    getOrganization(id: $organizationId) {
      id
      name
    }
  }
`;

/**
 * __useGetBaseImpersonatedOrganizationQuery__
 *
 * To run a query within a React component, call `useGetBaseImpersonatedOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaseImpersonatedOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaseImpersonatedOrganizationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetBaseImpersonatedOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBaseImpersonatedOrganizationQuery,
    GetBaseImpersonatedOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBaseImpersonatedOrganizationQuery,
    GetBaseImpersonatedOrganizationQueryVariables
  >(GetBaseImpersonatedOrganizationDocument, options);
}
export function useGetBaseImpersonatedOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBaseImpersonatedOrganizationQuery,
    GetBaseImpersonatedOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBaseImpersonatedOrganizationQuery,
    GetBaseImpersonatedOrganizationQueryVariables
  >(GetBaseImpersonatedOrganizationDocument, options);
}
export type GetBaseImpersonatedOrganizationQueryHookResult = ReturnType<
  typeof useGetBaseImpersonatedOrganizationQuery
>;
export type GetBaseImpersonatedOrganizationLazyQueryHookResult = ReturnType<
  typeof useGetBaseImpersonatedOrganizationLazyQuery
>;
export type GetBaseImpersonatedOrganizationQueryResult = Apollo.QueryResult<
  GetBaseImpersonatedOrganizationQuery,
  GetBaseImpersonatedOrganizationQueryVariables
>;

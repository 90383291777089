import React, { useContext } from "react";
import { useFormContext } from "react-hook-form";

import { ApplicationStatusEnum } from "generated/graphql";
import EditGrantFormFields from "components/EditGrantForm/EditGrantFormFields";
import { CreateGrantSchema } from "settings/yup/schemas/createGrantSchema";
// import { IsSubmittedEnum } from "components/EditReportForm/types";
import ReportsFieldArrayContext from "components/CreateGrantForm/ReportsFieldArrayContext";

const Status: React.FC = () => {
  const { errors, control, register, watch } =
    useFormContext<CreateGrantSchema>();

  // This was commented out as the submission has three possible values
  // 'Yes', 'No', and 'Ineligible' so a boolean was not sufficient
  // const isSubmitted = watch("isSubmitted") === IsSubmittedEnum.Yes;
  const isSubmitted = watch("isSubmitted");
  const isAwarded = watch("status") === ApplicationStatusEnum.Awarded;
  const isDeclined = watch("status") === ApplicationStatusEnum.Declined;
  const reportsFieldArray = useContext(ReportsFieldArrayContext);

  if (!reportsFieldArray) return null;

  return (
    <EditGrantFormFields
      errors={errors}
      control={control}
      register={register}
      isSubmitted={isSubmitted}
      isAwarded={isAwarded}
      isDeclined={isDeclined}
      reportsFieldArray={reportsFieldArray}
      marginBottom={6}
    />
  );
};

export default Status;

import { addYears, endOfDay, getDayOfYear, setYear, subDays } from "date-fns";

/**
 * Computes the start and end times for a given fiscal year based on the starting month configured per org.
 *
 * For example, if for a company fiscal year starts in July 1st, then FY 2023 starts in July 1st 2022 and ends
 * in June 30th 2023. If it starts in Jan 1st then FY 2023 starts in Jan 1st 2023 and ends in Dec 31st 2023.
 *
 * @param fiscalYearStart - The config from the org that specifies month/day in which
 *                          the fiscal year starts (year is irrelevant).
 * @param year - The fiscal year to get the range
 * @returns - The start and end of the given fiscal year.
 */
export default function fiscalYearRange(
  fiscalYearStart: Date,
  year: number,
): { start: Date; end: Date } {
  const start =
    getDayOfYear(fiscalYearStart) === 1
      ? new Date(year, 0, 1)
      : setYear(fiscalYearStart, year - 1);
  const end = endOfDay(subDays(addYears(start, 1), 1));
  return { start, end };
}

import { Flex, Grid, GridItem, Stack } from "@chakra-ui/react";
import BackButton from "components/BackButton";
import Loading from "components/Loading";
import { Button } from "components/_new/theme/Button";
import { Header } from "components/_new/theme/Header";
import { SearchInput } from "components/_new/theme/inputs/SearchInput";
import { Table } from "components/_new/theme/Table";
import { useModal } from "contexts/modal";

import { graphql } from "generated/graphql/v2";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import CreateFolderModal from "./Modals/CreateFolderModal";
import { FileRow } from "./Rows/FileRow";
import { FolderRow } from "./Rows/FolderRow";
import UploadFileModal from "./Modals/UploadFileModal";
import { useQueryParam } from "use-query-params";
import { useDebouncedState } from "hooks/_new/useDebouncedState";
import { useQuery } from "@apollo/client";

const getFunderFolder = graphql(`
  query getFunderFolder($funderId: Int!, $folder: String!, $search: String!) {
    getFolder(funderId: $funderId, folder: $folder) {
      name
      children(search: $search) {
        ... on Folder {
          __typename
          name
          ...FolderRowFragment
        }

        ... on File {
          __typename
          id
          ...FileRowFragment
        }
      }
    }
  }
`);

const PathSymb = Symbol("path");
export type Path = string[] & { [PathSymb]: never };

export const pathToUrl = (path: Path) => {
  const pathPieces = path[path.length - 1] === ".." ? path.slice(0, -2) : path;
  return "/" + pathPieces.join("/");
};
export const urlToPath = (maybeUrl?: string | (string | null)[] | null) => {
  const url = typeof maybeUrl === "string" ? maybeUrl : maybeUrl?.[0] ?? "";
  return url.split("/").filter(Boolean) as Path;
};

export default () => {
  const [t] = useTranslation();
  const [path, setPath] = useQueryParam("path", {
    encode: pathToUrl,
    decode: urlToPath,
  });

  const funderId = Number(useParams<{ id: string }>().id);
  const [searchVal, search, setSearch] = useDebouncedState("");
  const { data, error } = useQuery(getFunderFolder, {
    variables: { funderId, folder: pathToUrl(path), search },
  });
  if(error){
    if(error.message == 'unauthorized'){
      window.location.reload();
    }
  }

  const [showModal] = useModal();

  const openUploadModal = (type: "file" | "folder") => {
    showModal({
      title:
        type === "file"
          ? t(`modals.upload_file.title`)
          : t(`modals.upload_folder.title`),
      component: UploadFileModal,
      componentProps: {
        type,
        funderId,
        inFolder: pathToUrl(path),
      },
    });
  };

  const openCreateFolderModal = () => {
    showModal({
      title: t(`modals.create_folder.title`),
      component: CreateFolderModal,
      componentProps: {
        funderId,
        inFolder: pathToUrl(path),
      },
    });
  };

  return (
    <Stack spacing={8} margin={8}>
      <Grid gap={6} width="full" templateColumns="repeat(1, 1fr)">
        <GridItem>
          <Flex justifyContent="space-between">
            <Flex alignItems="center" gap={4}>
              <BackButton />
              <Header as="h1">{t("funder_documents.documents")}</Header>
            </Flex>
            <Flex gap={4}>
              <SearchInput
                search={searchVal}
                onChange={setSearch}
                placeholder={t("funder_documents.filter_in_folder")}
              />
              <Button
                onClick={() => openUploadModal("file")}
                background="primary.200"
              >
                {t("funder_documents.upload_file")}
              </Button>
              <Button
                onClick={() => openUploadModal("folder")}
                background="primary.200"
              >
                {t("funder_documents.upload_folder")}
              </Button>
              <Button
                onClick={() => openCreateFolderModal()}
                background="primary.200"
              >
                {t("funder_documents.create_folder")}
              </Button>
            </Flex>
          </Flex>
        </GridItem>
        <GridItem>
          {data?.getFolder ? (
            <Table
              data={data.getFolder.children}
              headers={[
                t("funder_documents.document_name"),
                t("common.created_at"),
                t("common.modified_at"),
                "",
              ]}
              rowKey={(fileOrFolder) =>
                fileOrFolder.__typename === "File"
                  ? fileOrFolder.id
                  : fileOrFolder.name
              }
              rowMapper={(fileOrFolder) =>
                fileOrFolder.__typename === "Folder" ? (
                  <FolderRow
                    folder={fileOrFolder}
                    funderId={funderId}
                    setPath={setPath}
                  />
                ) : (
                  <FileRow file={fileOrFolder} funderId={funderId} />
                )
              }
            />
          ) : (
            <Loading />
          )}
        </GridItem>
      </Grid>
    </Stack>
  );
};

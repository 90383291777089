import UpdateButton from "components/_new/objectViews/Grants/UpdateButton";
import { Card, CardTitle } from "components/_new/theme/Card";
import { Cell, PaginatedTable } from "components/_new/theme/Table";
import { FragmentType, graphql, useFragment } from "generated/graphql/v2";
import React from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "utils/dateFormats";
import getFiscalYear from "utils/getFiscalYear";

const FunderNextGrantsFragment = graphql(`
  fragment FunderNextGrantsFragment on Funder {
    grants {
      frequency
      program {
        id
      name
      }
      applications {
        id
        deadline
        status
      }
    }
  }
`);

export const FunderNextGrantsTable: React.FC<{
  funder: FragmentType<typeof FunderNextGrantsFragment>;
  fyStart: Date;
}> = (props) => {
  const funder = useFragment(FunderNextGrantsFragment, props.funder);
  const [t] = useTranslation();

  const grants = funder.grants ?? [];
  const applications =
    grants
      .filter((g) => g.frequency === "RECURRING" || g.frequency === "ONE_TIME")
      .reduce?.(
        (a, g) => [
          ...a,
          ...(g.applications ?? []).map((d) => ({ ...d, grant: g })),
        ],
        [] as NonNullable<
          (NonNullable<(typeof grants)[0]["applications"]>[0] & {
            grant: (typeof grants)[0];
          })[]
        >,
      )
      ?.filter((a) => a.status === "NOT_APPLY")
      ?.sort(
        (a, b) =>
          new Date(a.deadline ?? "").getTime() -
          new Date(b.deadline ?? "").getTime(),
      ) ?? [];

  return (
    <Card header={<CardTitle>{t("next_grants_card.title")}</CardTitle>}>
      <PaginatedTable
        headers={[
          t("tables.columns_titles.year"),
          t("tables.columns_titles.program"),
          t("tables.columns_titles.deadline"),
          t("tables.columns_titles.previous_awards"),
          t("tables.columns_titles.status"),
          "",
        ]}
        data={applications}
        rowKey="id"
        rowMapper={(application) => (
          <>
            <Cell>
              {getFiscalYear(new Date(application.deadline), props.fyStart)}
            </Cell>
            <Cell>{application.grant.program.name}</Cell>
            <Cell>{formatDate(application.deadline)}</Cell>
            <Cell>-</Cell>
            <Cell>
              {application.status
                ? t(`statuses.${application.status?.toLowerCase()}`)
                : "-"}
            </Cell>
            <Cell>
              <UpdateButton
                id={application.id}
                type="grant"
                label={t("actions.update_information")}
                color="blue.500"
                program={application.grant.program}
              />
            </Cell>
          </>
        )}
      />
    </Card>
  );
};

import { graphql, FragmentType, useFragment } from "generated/graphql/v2";
import React from "react";
import { range } from "utils/range";
import { Flex } from "@chakra-ui/react";
import { Option, Picklist } from "react-rainbow-components";

const SelectYearFragment = graphql(`
  fragment SelectYearFragment on Organization {
    firstFiscalYear
    currentFiscalYear
  }
`);

export const SelectYear = (props: {
  organization: FragmentType<typeof SelectYearFragment>;
  value: number;
  onChange: (value: number) => void;
}) => {
  const { value, onChange } = props;
  const { firstFiscalYear: first, currentFiscalYear: now } = useFragment(
    SelectYearFragment,
    props.organization,
  );

  return (
    <Flex maxWidth="140px">
      <Picklist
        value={{ value, label: `FY ${value}` }}
        onChange={({ value }) => onChange(value)}
        placeholder="Choose FY"
      >
        {range(now, first).map((year) => (
          <Option value={year} label={`FY ${year}`} name={year} key={year} />
        ))}
      </Picklist>
    </Flex>
  );
};

import {
  AllApplicationsEnum,
  AllRecordsEnum,
  ReportStatusEnum,
} from "constants/statuses";
import { FilterApplicationStatusEnum } from "generated/graphql";
import { Application } from "generated/graphql/v2/graphql";

export const getGrantStatus = (
  status: FilterApplicationStatusEnum | AllApplicationsEnum,
): FilterApplicationStatusEnum | undefined => {
  if (status === AllApplicationsEnum.AllApplications) {
    return undefined;
  }

  return status;
};

export const getReportStatus = (
  status: AllRecordsEnum,
): boolean | undefined => {
  if (status === AllRecordsEnum.AllRecords) {
    return undefined;
  }

  return status === ReportStatusEnum.Submitted;
};

export const getStatusFromGrantApplication = (application: Application) => {
  const status = application?.status?.toLowerCase();
  if (application?.submission) {
    //check if the submission was greater than 365 days
    const submissionDate = new Date(application.submission.submissionDate);
    const diffTime = Math.abs(new Date().getTime() - submissionDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays > 365 && !application.award && !application.declinedDetails) {
      //if it was not awarded and the days are more than 365
      return "did_not_respond";
    }
  }
  return status;
};

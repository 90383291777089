import React from "react";
import { VStack } from "@chakra-ui/react";

import EditGrantForm from "components/EditGrantForm";
import { useGetApplicationQuery } from "generated/graphql";
import Loading from "components/Loading";

import { UpdateGrantModalProps } from "./types";

const UpdateGrantModal: React.FC<UpdateGrantModalProps> = ({
  hideModal,
  componentProps: { id, buttonLabel, onClickDelete, program },
}) => {
  const { data: grantData, loading } = useGetApplicationQuery({
    variables: {
      id,
    },
  });

  const onHide = (success: boolean) => {
    if (success) {
      // window.location.reload();
    }
    hideModal();
  };

  return (
    <VStack spacing={6}>
      {loading && <Loading height="xxs" />}

      {!loading && (
        <EditGrantForm
          grantData={grantData}
          buttonLabel={buttonLabel}
          hideModal={onHide}
          onClickDeletGrantBtn={onClickDelete}
          program={program}
        />
      )}
    </VStack>
  );
};

export default UpdateGrantModal;

import yup from "settings/yup";

const updateHowToApplySchema = yup.object().shape({
  howToApply: yup.string(),
});

export interface UpdateHowToApplySchema {
  howToApply: string;
}

export default updateHowToApplySchema;

import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";

import configureSentry from "settings/sentry";

import "assets/scss/app.scss";
import "translations/i18n";

import App from "./App";
import { shimHistory } from "utils/shims";

configureSentry();
shimHistory();

ReactDOM.render(<App />, document.getElementById("root"));

import { AssertingTestOptions } from "yup";

import isValidPhoneNumber from "utils/isValidPhoneNumber";
import i18n from "translations/i18n";

/**
 * Applies phone number validation in one schema field.
 *
 * To be valid, a phone number must be equal or greater than 10 characters, excluding the +1.
 *
 * Only validates US phone numbers.
 */
export const makePhoneNumberTest = (
  isRequired = true,
): AssertingTestOptions<string> => ({
  name: "isValidPhoneNumber",
  exclusive: false,
  message: i18n.t("yup.invalid_phone_number"),
  test(value: string): value is string {
    if (!value && !isRequired) {
      return true;
    }

    return isValidPhoneNumber(value);
  },
});

import React from "react";
import { Flex, Grid, GridItem, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { parseISO } from "date-fns";
import { useQuery } from "@apollo/client";
import { Link, useParams } from "react-router-dom";

import BackButton from "components/BackButton";
import {
  useGetOrganizationFiscalYearQuery,
  useGetImpersonatedOrganizationFiscalYearQuery,
} from "generated/graphql";
import ContactInformationCard from "components/Cards/ContactInformationCard";
import NotesCard from "components/Cards/NotesCard";
import HowToApplyCard from "components/Cards/HowToApplyCard";
import useGetOrganization from "hooks/useGetOrganization";
import { graphql } from "generated/graphql/v2";
import Loading from "components/Loading";
import { FunderPreviousGrantsTable } from "./PreviousGrantsTable";
import { FunderNextGrantsTable } from "./NextGrantsTable";
import { PageWrapper } from "components/_new/theme/PageWrapper";
import Button from "components/Button";
import { pages } from "routes/pages";

const GetFunderProfile = graphql(`
  query GetFunderProfile($id: Int!) {
    getFunder(id: $id) {
      id
      name
      isPaused
      complexityScore
      notes
      howToApply
      ...FunderProfileNotesFragment
      ...FunderHowToApplyFragment
      ...FunderContactInformationFragment
      ...FunderPreviousGrantsFragment
      ...FunderNextGrantsFragment
      ...FunderLocationInformationFragment
    }
  }
`);

const FunderProfile: React.FC = () => {
  const [t] = useTranslation();

  const funderId = Number(useParams<{ id: string }>().id);

  const [organization] = useGetOrganization(
    useGetOrganizationFiscalYearQuery,
    useGetImpersonatedOrganizationFiscalYearQuery,
  );

  const fiscalYearStart = parseISO(
    organization?.fiscalYearStart || "2000-01-01",
  );

  const { data: funderData, error } = useQuery(GetFunderProfile, {
    variables: { id: funderId },
    fetchPolicy: "cache-and-network",
  });
  if(error){
    if(error.message == 'unauthorized'){
      window.location.reload();
    }
  }

  if (!funderData?.getFunder) {
    return <Loading />;
  }

  return (
    <PageWrapper>
      <Grid gap={6} width="full" templateColumns="repeat(14, 1fr)">
        <GridItem
          colSpan={14}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex alignItems="center">
            <BackButton />
            <Text
              as="h1"
              marginLeft={4}
              fontFamily="heading"
              fontSize="lg"
              color="primary.200"
              textTransform="capitalize"
            >
              {funderData?.getFunder?.name ??
                t("contact_information_card.not_informed")}
            </Text>
          </Flex>
          <Link to={pages.funderDocuments.pathWithParams(funderId)}>
            <Button background="primary.200">Documents</Button>
          </Link>
        </GridItem>
        <GridItem rowSpan={2} colSpan={4}>
          <ContactInformationCard
            funder={funderData.getFunder}
            funderLocation={funderData.getFunder}
          />
        </GridItem>
        <GridItem colSpan={10}>
          <FunderPreviousGrantsTable
            funder={funderData.getFunder}
            fyStart={fiscalYearStart}
          />
        </GridItem>
        <GridItem colSpan={10}>
          <FunderNextGrantsTable
            funder={funderData.getFunder}
            fyStart={fiscalYearStart}
          />
        </GridItem>
        <GridItem colSpan={7}>
          <NotesCard funder={funderData.getFunder} />
        </GridItem>
        <GridItem colSpan={7}>
          <HowToApplyCard funder={funderData.getFunder} />
        </GridItem>
      </Grid>
    </PageWrapper>
  );
};

export default FunderProfile;

import React, { useCallback, useState } from "react";
import { ModalBody, ModalFooter, Stack, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import deadlineReportSchema, {
  DeadlineReportSchema,
} from "settings/yup/schemas/deadlineReportSchema";
import Checkbox from "components/FormComponents/Checkbox";
import Button from "components/Button";

import useDeadlineReportValidation from "./hooks/useDeadlineReportValidation";
import options from "./options";
// import { OnClose } from "contexts/modal/types";
import { ModalComponentProps } from "contexts/modal/types";
import { grantFlowFormattedColumns } from "constants/grantFlowTableTitles";
import { reportCenterFormattedColumns } from "constants/reportCenterTableTitles";
import writeXlsxFile from 'write-excel-file';

const PRINT_TIMEOUT_MS = 800;

const DeadlineReportModal: React.FC<
  ModalComponentProps<{
    docType: string,
    grantDeadlinesData: any[],
    reportDeadlinesData: any[]
  }>
> = ({ hideModal, componentProps: { docType: docType, grantDeadlinesData: grantDeadlinesData, reportDeadlinesData: reportDeadlinesData } }) => {
  const [t] = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const convertToCSV = (dataArray: any[]) => {
    let str = '';
    for (let i = 0; i < dataArray.length; i++) {
      let line = '';
      for (const index in dataArray[i]) {
        if (line !== '') {
          line += ',';
        }
        line += dataArray[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  };

  const handlePrintPage = useCallback(async () => {
    setIsLoading(true);
    const formValues = form.getValues();
    if (docType == 'excell') {
      // https://www.npmjs.com/package/write-excel-file for excell
      const excellData: any[] = [];
      if (formValues.grantDeadlines) {
        const header: any[] = [];
        grantFlowFormattedColumns.forEach((c) => {
          if (c.label.indexOf("id") < 0) {
            header.push({
              value: c.label.toUpperCase(),
              fontWeight: 'bold'
            });
          }
        });
        excellData.push(header);
        //data
        grantDeadlinesData.forEach(application => {
          const entry: any[] = [];
          grantFlowFormattedColumns.forEach((c) => {
            if (c.label.indexOf("id") < 0) {
              if (Object.prototype.hasOwnProperty.call(application, c.field)) {
                let fieldValue = application[c.field];
                if(c.field == 'status'){
                  fieldValue = t(`statuses.${fieldValue?.toLowerCase()}`);
                }
                entry.push({
                  type: String,
                  value: fieldValue
                });
              } else {
                entry.push({
                  type: String,
                  value: "-"
                });
              }
            }
          });
          excellData.push(entry);
        });
      }
      if (formValues.reportDeadlines) {
        if (excellData.length > 0) {
          //add a two line separators
          excellData.push([]);
          excellData.push([]);
        }
        const header: any[] = [];
        reportCenterFormattedColumns.forEach((c) => {
          header.push({
            value: c.label.toUpperCase(),
            fontWeight: 'bold'
          });
        });
        excellData.push(header);
        //data
        reportDeadlinesData.forEach(report => {
          const entry: any[] = [];
          reportCenterFormattedColumns.forEach((c) => {
            if (Object.prototype.hasOwnProperty.call(report, c.field)) {
              let fieldValue = report[c.field];
              if(c.field == 'status'){
                fieldValue = t(`statuses.${fieldValue?.toLowerCase()}`);
              }
              entry.push({
                type: String,
                value: fieldValue
              });
            } else {
              entry.push({
                type: String,
                value: "-"
              });
            }
          });
          excellData.push(entry);
        });

      }
      await writeXlsxFile(excellData, {
        // columns, // (optional) column widths, etc.
        fileName: 'grantflow-next-90-days-data.xlsx'
      });
      setIsLoading(false);
      hideModal();
    } else if (docType == 'csv') {
      // https://medium.com/@gb.usmanumar/how-to-export-data-to-csv-json-in-react-js-ea45d940652a
      if (formValues.grantDeadlines) {
        const csvData: any[] = [[]];
        //header entry
        grantFlowFormattedColumns.forEach((c) => {
          csvData[0].push(c.label);
        });
        grantDeadlinesData.forEach(application => {
          const entry: any[] = [];
          grantFlowFormattedColumns.forEach((c) => {
            if (Object.prototype.hasOwnProperty.call(application, c.field)) {
              const fieldValue = application[c.field];
              entry.push(fieldValue);
            } else {
              entry.push("-");
            }
          });
          csvData.push(entry);
        });
        const csvFileData = convertToCSV(csvData);
        const jsonData = new Blob([csvFileData], { type: 'text/csv' });
        const jsonURL = URL.createObjectURL(jsonData);
        const link = document.createElement('a');
        link.href = jsonURL;
        link.download = `grant-deadlines-data.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      if (formValues.reportDeadlines) {
        const csvData: any[] = [[]];
        //header entry
        reportCenterFormattedColumns.forEach((c) => {
          csvData[0].push(c.label);
        });
        reportDeadlinesData.forEach(report => {
          const entry: any[] = [];
          reportCenterFormattedColumns.forEach((c) => {
            if (Object.prototype.hasOwnProperty.call(report, c.field)) {
              const fieldValue = report[c.field];
              entry.push(fieldValue);
            } else {
              entry.push("-");
            }
          });
          csvData.push(entry);
        });
        const csvFileData = convertToCSV(csvData);
        const jsonData = new Blob([csvFileData], { type: 'text/csv' });
        const jsonURL = URL.createObjectURL(jsonData);
        const link = document.createElement('a');
        link.href = jsonURL;
        link.download = `report-deadlines-data.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      setIsLoading(false);
      hideModal();
    } else {

      setTimeout(hideModal, PRINT_TIMEOUT_MS / 2);

      setTimeout(window.print, PRINT_TIMEOUT_MS);
    }

  }, [hideModal]);

  const form = useForm<DeadlineReportSchema>({
    resolver: yupResolver(deadlineReportSchema),
    mode: "onBlur",
    defaultValues: {
      grantDeadlines: true,
      reportDeadlines: true
    },
  });

  useDeadlineReportValidation(form);

  return (
    <>
      <ModalBody p={4}>
        <Text fontFamily="semibold" color="gray.400" fontSize="xs">
          {t(
            "board_report.select_all_the_information_you_would_like_to_be_on_the_report",
          )}
        </Text>

        <Stack spacing={3} mt={4}>
          {options.map((option) => (
            <Checkbox
              ref={form.register}
              name={option.name}
              key={option.name}
              defaultChecked
            >
              {option.title}
            </Checkbox>
          ))}
        </Stack>
      </ModalBody>

      <ModalFooter>
        <Button
          width="full"
          isLoading={isLoading}
          backgroundColor="primary.100"
          onClick={handlePrintPage}
        >
          {t("dashboard.next_90_days.download_btn_text")}
        </Button>
      </ModalFooter>
    </>
  );
};

export default DeadlineReportModal;

import React from "react";
import { ApolloProvider } from "@apollo/client";
import { Route, Router } from "react-router-dom";
import { ThemeProvider as ChakraProvider } from "@chakra-ui/system";
import { CSSReset } from "@chakra-ui/react";
import { QueryParamProvider } from "use-query-params";
import { Application } from "react-rainbow-components";

import CurrentUserContainer from "contexts/currentUser/CurrentUserContainer";
import ErrorBoundary from "components/ErrorBoundary";
import apolloClient from "settings/apolloClient";
import AppRouter from "components/AppRouter";
import history from "settings/history";
import ToastProvider from "contexts/toast/ToastContainer";
import ModalContainer from "contexts/modal/ModalContainer";

import RainbowTheme from "./settings/theme/RainbowTheme";
import ChakraTheme from "./settings/theme/ChakraTheme";
import "./assets/fonts/index.css";

const App: React.FC = () => (
  <ErrorBoundary>
    <ChakraProvider theme={ChakraTheme}>
      <CSSReset />
      <Application theme={RainbowTheme}>
        <ToastProvider>
          <ApolloProvider client={apolloClient}>
            <CurrentUserContainer>
              <Router history={history}>
                <QueryParamProvider ReactRouterRoute={Route}>
                  <ModalContainer>
                    <AppRouter />
                  </ModalContainer>
                </QueryParamProvider>
              </Router>
            </CurrentUserContainer>
          </ApolloProvider>
        </ToastProvider>
      </Application>
    </ChakraProvider>
  </ErrorBoundary>
);

export default App;

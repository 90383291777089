import { AssertingTestOptions } from "yup";

import { isValidCurrency } from "utils/isValidCurrency";
import i18n from "translations/i18n";

/**
 * Applies currency validation in one schema field.
 */
const buildCurrencyTest = (
  isRequired = true,
): AssertingTestOptions<string> => ({
  name: "isValidCurrency",
  exclusive: false,
  test(value: string): value is string {
    if (!value && !isRequired) {
      return true;
    }

    return isValidCurrency(value);
  },
  message: i18n.t("yup.mixed_required_field"),
});

export default buildCurrencyTest;

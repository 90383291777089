import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useToastWrapper } from "components/_new/hooks/useToastWrapper";
import updateHowToApplySchema, {
  UpdateHowToApplySchema,
} from "settings/yup/schemas/updateHowToApplySchema";

import CardTextArea from "../CardTextArea";
import { FragmentType, graphql, useFragment } from "generated/graphql/v2";
import { useMutation } from "@apollo/client";

const FunderHowToApplyFragment = graphql(`
  fragment FunderHowToApplyFragment on Funder {
    id
    howToApply
  }
`);

const UpdateFunderHowToMutation = graphql(`
  mutation FunderHowToApplyMutation($id: Int!, $howToApply: String!) {
    updateFunder(id: $id, params: { howToApply: $howToApply }) {
      id
      howToApply
    }
  }
`);

const HowToApplyCard: React.FC<{
  funder: FragmentType<typeof FunderHowToApplyFragment>;
}> = (props) => {
  const [t] = useTranslation();

  const { id: funderId, howToApply = "" } = useFragment(
    FunderHowToApplyFragment,
    props.funder,
  );

  const { control, reset, handleSubmit, formState } =
    useForm<UpdateHowToApplySchema>({
      resolver: yupResolver(updateHowToApplySchema),
      mode: "onBlur",
    });

  useEffect(() => {
    if (howToApply) {
      reset({ howToApply });
    }
  }, [reset, howToApply]);

  const [updateHowToApply, res] = useMutation<typeof UpdateFunderHowToMutation>(
    UpdateFunderHowToMutation,
  );

  const toastWrapper = useToastWrapper(
    t("how_to_apply_card.funder_how_to_apply_have_been_updated"),
  );
  const onSubmit = useCallback(
    (values: UpdateHowToApplySchema) => {
      if (!funderId || !formState.isDirty) {
        return;
      }

      toastWrapper(() =>
        updateHowToApply({
          variables: {
            id: funderId,
            howToApply: values?.howToApply ?? "",
          },
        }),
      );
    },
    [toastWrapper, funderId, updateHowToApply, formState.isDirty],
  );

  return (
    <CardTextArea
      isLoading={res.loading}
      handleSubmit={handleSubmit(onSubmit)}
      value={howToApply ?? ""}
      title={`${t("how_to_apply_card.how_to_apply")}:`}
      control={control}
      name="howToApply"
      funderId={funderId}
    />
  );
};

export default HowToApplyCard;

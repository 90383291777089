import { FunderTypeEnum } from "generated/graphql";
import { Status } from "constants/statuses";
import i18n from "translations/i18n";

export const funderTypes: Record<FunderTypeEnum, Status> = {
  [FunderTypeEnum.Corporate]: {
    label: i18n.t("Corporate"),
    value: FunderTypeEnum.Corporate,
  },
  [FunderTypeEnum.Foundation]: {
    label: i18n.t("Foundation"),
    value: FunderTypeEnum.Foundation,
  },
  [FunderTypeEnum.Government]: {
    label: i18n.t("Government"),
    value: FunderTypeEnum.Government,
  },
  [FunderTypeEnum.Organization]: {
    label: i18n.t("Organization"),
    value: FunderTypeEnum.Organization,
  },
  [FunderTypeEnum.Other]: {
    label: i18n.t("Other"),
    value: FunderTypeEnum.Other,
  },
};

export const funderTypesOptions = (
  Object.keys(funderTypes) as FunderTypeEnum[]
).map(
  (key): Status => ({
    label: funderTypes[key].label as string,
    value: key,
  }),
);

import React from "react";
import { SimpleGrid, Stack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { DatePicker } from "react-rainbow-components";
import { Controller, useFormContext } from "react-hook-form";

import getErrorMessage from "utils/getErrorMessage";
import { CreateGrantSchema } from "settings/yup/schemas/createGrantSchema";

import { SelectDeadlineType } from "components/_new/theme/inputs/SelectDeadlineType";

// import DatePicker from "react-datepicker";

const Deadlines: React.FC = () => {
  const [t] = useTranslation();

  const { errors, control } = useFormContext<CreateGrantSchema>();

  return (
    <Stack as="form" spacing={6} marginBottom={8} width="full">
      <SimpleGrid columns={2} gap={6} alignItems="baseline">
        <Controller
          as={SelectDeadlineType}
          control={control}
          name="applicationType"
          error={getErrorMessage("applicationType", errors)}
          required
        />
        <Controller
          as={DatePicker}
          control={control}
          name="applicationDeadline"
          label={t("create_grant.steps.deadlines.application_deadline")}
          error={getErrorMessage("applicationDeadline", errors)}
          required
        />
      </SimpleGrid>
    </Stack>
  );
};

export default Deadlines;

import { useMutation, useQuery } from "@apollo/client";
import { Stack } from "@chakra-ui/react";
import Loading from "components/Loading";
import { Button } from "components/_new/theme/Button";
import { useToast } from "contexts/toast";
import { FragmentType, graphql, useFragment } from "generated/graphql/v2";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Input, Select } from "react-rainbow-components";

const EditFileFragment = graphql(`
  fragment EditFileFragment on File {
    id
    name
    folder
  }
`);
export type EditFileFragmentType = FragmentType<typeof EditFileFragment>;

const EditFileMutation = graphql(`
  mutation EditFileMutation($id: Int!, $name: String, $folder: String) {
    updateFile(id: $id, params: { name: $name, folder: $folder }) {
      id
    }
  }
`);

const ListFolderOptions = graphql(`
  query ListFolderOptions($funderId: Int!) {
    listFolders(filters: { funderId: $funderId }) {
      name
    }
  }
`);

export default (props: {
  hideModal: () => void;
  componentProps: {
    funderId: number;
    file: FragmentType<typeof EditFileFragment>;
  };
}) => {
  const [t] = useTranslation();
  const file = useFragment(EditFileFragment, props.componentProps.file);
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<{ name: string; folder: string }>({
    defaultValues: { name: file.name, folder: file.folder },
  });
  const { addToast } = useToast();

  const { data, error } = useQuery(ListFolderOptions, {
    variables: { funderId: props.componentProps.funderId },
  });
  if(error){
    if(error.message == 'unauthorized'){
      window.location.reload();
    }
  }
  const [updateFile] = useMutation(EditFileMutation, {
    refetchQueries: ["getFunderFolder"],
  });

  if (!data?.listFolders) {
    return <Loading />;
  }

  return (
    <Stack
      as="form"
      marginBottom={8}
      padding={4}
      spacing={6}
      onSubmit={handleSubmit(async (data) => {
        try {
          await updateFile({ variables: { id: file.id, ...data } });
          addToast({
            title: t("file.edit_success"),
            description: t("file.edit_success_description"),
            type: "success",
          });
          props.hideModal();
        } catch {
          addToast({
            title: t("file.edit_error"),
            description: t("file.edit_error_description"),
            type: "error",
          });
        }
      })}
    >
      <Controller
        as={Input}
        control={control}
        name="name"
        label={t("file.name")}
        error={errors.name}
        labelAlignment="left"
      />
      <Controller
        as={Select}
        control={control}
        name="folder"
        label={t("file.folder")}
        error={errors.folder}
        labelAlignment="left"
        options={data.listFolders.map(({ name }) => ({
          value: name,
          label: name,
        }))}
      />

      <Button type="submit" background="primary.100" isLoading={isSubmitting}>
        {t("file.edit")}
      </Button>
    </Stack>
  );
};

import { TableTitlesEnum } from "constants/grantFlowTableTitles";
import { TableTypeEnum } from "constants/types";
import { FilterApplicationStatusEnum } from "generated/graphql";
import { ListApplicationDeadlinesQuery } from "generated/graphql/v2/graphql";
import i18n from "translations/i18n";

import { displayMonthDayYear, formatDate } from "./dateFormats";
import formatCurrency from "./formatCurrency";

export interface ApplicationProps {
  id: string;
  type: TableTypeEnum;
  funderId: string;
  [TableTitlesEnum.ApplicationDeadline]?: string | null;
  [TableTitlesEnum.Funder]?: string | null;
  [TableTitlesEnum.LastAward]?: string | null;
  [TableTitlesEnum.DateSubmitted]?: string | null;
  [TableTitlesEnum.AmountRequested]?: string | null;
  [TableTitlesEnum.Program]?: string | null;
  [TableTitlesEnum.DateReceived]?: string | null;
  [TableTitlesEnum.AmountAwarded]?: string | null;
  [TableTitlesEnum.ResponseAverage]?: string | null;
  [TableTitlesEnum.Status]?: string | null;
  [TableTitlesEnum.DeclinedDate]?: string | null;
}

/**
 * Receives tableData from the ListApplications query and
 * returns it formatted
 * @param tableData rawTableData
 */
const formatGrantFlowTableData = (
  tableData: ListApplicationDeadlinesQuery,
): ApplicationProps[] => {
  const applicationsList = tableData.listApplications;

  if (!tableData || !applicationsList) {
    return [];
  }

  const formattedTableData = applicationsList.map((application) => ({
    id: application.id.toString() ?? "-",

    type: TableTypeEnum.Grant,

    funderId: application.grant.funder.id.toString() ?? "-",

    [TableTitlesEnum.ApplicationDeadline]: formatDate(
      application.deadline,
      displayMonthDayYear,
    ),

    // [TableTitlesEnum.Frequency]: i18n.t(`table_frequencies.${application.grant?.frequency?.toLowerCase() ?? "-" }`),
    [TableTitlesEnum.DeadlineType]: i18n.t(`urgencies_deadline_types.${application?.deadlineType?.toLowerCase() ?? "-" }`),

    [TableTitlesEnum.Funder]: application.grant?.funder.name ?? "-",

    [TableTitlesEnum.LastAward]:
      formatDate(
        application.grant.funder.lastAward?.receivementDate,
        displayMonthDayYear,
      ) ?? i18n.t("tables.new"),

    [TableTitlesEnum.DateSubmitted]: formatDate(
      application.submission?.submissionDate,
      displayMonthDayYear,
    ),

    [TableTitlesEnum.AmountRequested]:
      formatCurrency(
        parseFloat(application.submission?.amountRequested || "0"),
      ) ?? "-",

    [TableTitlesEnum.Program]: application.grant.program.name ?? "-",
    ["programId"]: application.grant.program.id ?? "-",

    [TableTitlesEnum.DateReceived]: formatDate(
      application.award?.receivementDate,
      displayMonthDayYear,
    ),

    [TableTitlesEnum.AmountAwarded]:
      formatCurrency(parseFloat(application.award?.amountAwarded || "0")) ??
      "-",

    [TableTitlesEnum.DeclinedDate]:
      application.declinedDetails ? formatDate(application.declinedDetails?.declinedDate, displayMonthDayYear) : "-",

    [TableTitlesEnum.ResponseAverage]:
      i18n.t("tables.day", {
        count: Math.ceil(application.grant.responseAverage || 0),
      }) ?? "-",

    [TableTitlesEnum.Status]:
      application.isSubmittedAsIneligible === true
        ? i18n.t(FilterApplicationStatusEnum.Ineligible)
        : application.status,
  }));

  return formattedTableData;
};

export default formatGrantFlowTableData;

import React from "react";
import { matchPath, useHistory } from "react-router-dom";

import { pages } from "routes/pages";
import { useCurrentUser } from "contexts/currentUser";

const RouteSessionValidator: React.FC = ({ children }) => {
  const history = useHistory();

  const [currentUser, { loading: loadingCurrentUser }] = useCurrentUser();

  const pathname = history?.location?.pathname ?? "";

  const appRoute = Object.values(pages).find((route) =>
    matchPath(pathname, route),
  );

  const isInvalidRoute = !!(
    appRoute?.requireAuthentication &&
    !loadingCurrentUser &&
    !currentUser
  );

  if (appRoute?.requireAuthentication && loadingCurrentUser) {
    return null;
  }

  if (isInvalidRoute) {
    window.location.href = pages.root.pathWithParams();
    return null;
  }

  return <>{children}</>;
};

export default RouteSessionValidator;

import useAdminImpersonationStore, {
  readOrganization,
} from "components/AdminImpersonation/store";
import { useCurrentUser } from "contexts/currentUser";
import { AdminImpersonationFragmentFragment } from "generated/graphql/v2/graphql";

export const useOrganizationId = () => {
  const [currentUserData] = useCurrentUser();
  const aiStore = useAdminImpersonationStore();
  const org = aiStore.getOrganizationId(currentUserData?.organization?.id ?? 0);
  console.log("This one a", org);
  return org;
};

export const useOrganization = (): Omit<
  AdminImpersonationFragmentFragment,
  "$fragmentRefs"
> => {
  const [currentUserData] = useCurrentUser();
  const aiStore = useAdminImpersonationStore();

  const org = aiStore.organization ?? currentUserData?.organization;

  const orgObj = org
    ? readOrganization(org)
    : { id: 0, currentFiscalYear: new Date().getFullYear() };

  // if (currentUserData?.role == "MANAGER" && orgObj.currentFiscalYear == null) {
  //   orgObj.currentFiscalYear = new Date().getFullYear();
  // }
  const tempObj: any= {
    ... orgObj
  }
  if(!orgObj.currentFiscalYear && tempObj.current_fiscal_year){
    orgObj.currentFiscalYear = tempObj.current_fiscal_year
  }

  return orgObj;
};

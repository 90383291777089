import React from "react";

import AdminImpersonationSelect from "./AdminImpersonationSelect";
import ManagerImpersonationSelect from "./ManagerImpersonationSelect";
import { useCurrentUser } from "contexts/currentUser";

const AdminImpersonation: React.FC = () => {
  const [user] = useCurrentUser();

  return <>
    {user?.role === "ADMIN" && <AdminImpersonationSelect />}
    {user?.role === "MANAGER" && <ManagerImpersonationSelect />}
    {user?.role === "USER" && <b>{user.organization?.name??""}</b>}
  </>;
};

export default AdminImpersonation;

import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { contactMail } from "constants/contactMail";

const Unauthorized: React.FC = () => {
  const [t] = useTranslation();

  return (
    <Flex
      width="full"
      height="90vh"
      align="center"
      justify="center"
      flexDir="column"
    >
      <Text as="h1" fontSize="42px" color="primary.200" mb={5}>
        {t("unauthorized.your_free_trial_has_ended")}
      </Text>

      <Text as="p" fontSize="lg" color="primary.100">
        {`${t("unauthorized.in_order_to_keep_using")}: `}

        <Text
          as="a"
          href={`mailto:${contactMail}`}
          target="_blank"
          rel="noreferrer"
        >
          {contactMail}
        </Text>
      </Text>
    </Flex>
  );
};

export default Unauthorized;

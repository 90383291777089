import React from "react";
import { Heading, Stack } from "@chakra-ui/react";

import NumberCircle from "components/NumberCircle";

import { WizardHeaderProps } from "./types";
import useWizardStore from "./store";

const WizardHeader: React.FC<WizardHeaderProps> = ({
  colorScheme = "primary",
  step,
  ...rest
}) => {
  const stepIndex = useWizardStore((store) => store.stepIndex);

  return (
    <Stack alignItems="center" spacing={4} isInline {...rest}>
      <NumberCircle bgColor={`${colorScheme}.100`} value={stepIndex + 1} />

      <Stack spacing={1}>
        <Heading color={`${colorScheme}.100`} fontSize="md">
          {step.title}
        </Heading>
      </Stack>
    </Stack>
  );
};

export default WizardHeader;

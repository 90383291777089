import { useMutation } from "@apollo/client";
import { Stack } from "@chakra-ui/react";
import { Button } from "components/_new/theme/Button";
import { useToast } from "contexts/toast";
import { graphql } from "generated/graphql/v2";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Input } from "react-rainbow-components";

const CreateFolderMutation = graphql(`
  mutation CreateFolderMutation($funderId: Int!, $folder: String!) {
    createFolder(funderId: $funderId, folder: $folder) {
      name
    }
  }
`);

export default (props: {
  hideModal: () => void;
  componentProps: {
    funderId: number;
    inFolder: string;
  };
}) => {
  const [t] = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<{ folder: string }>({
    defaultValues: {
      folder: (props.componentProps.inFolder + "/").replace("//", "/"),
    },
  });
  const { addToast } = useToast();

  const { funderId } = props.componentProps;
  const [create] = useMutation(CreateFolderMutation, {
    refetchQueries: ["getFunderFolder"],
  });

  return (
    <Stack
      as="form"
      marginBottom={8}
      padding={4}
      spacing={6}
      onSubmit={handleSubmit(async ({ folder }) => {
        try {
          await create({
            variables: { funderId, folder },
          });
          addToast({
            type: "success",
            title: t("folder.create_success"),
          });
          props.hideModal();
        } catch {
          addToast({
            type: "error",
            title: t("folder.create_error"),
          });
        }
      })}
    >
      <Controller
        as={Input}
        control={control}
        name="folder"
        label={t("file.folder_name")}
        error={errors["folder"]}
        labelAlignment="left"
      />
      <Button type="submit" background="primary.100" isLoading={isSubmitting}>
        {t("folder.create")}
      </Button>
    </Stack>
  );
};

import yup from "settings/yup";

const createSupportSchema = yup.object().shape({
    name: yup.string().required(),
    organization: yup.string().required(),
    email: yup.string().email().required(),
    message: yup.string().required(),
});

export interface CreateSupportSchema {
  name: string;
  organization: string;
  email: string;
  message: string;
}

export default createSupportSchema;

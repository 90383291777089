import React from "react";
import { useTranslation } from "react-i18next";
import { Picklist, PicklistOption as Option } from "react-rainbow-components";
import type { PicklistProps } from "react-rainbow-components/components/Picklist";

export const SelectFunderType: React.FC<
  Pick<PicklistProps, "error" | "required" | "readOnly">
> = (props) => {
  const [t] = useTranslation();

  return (
    <Picklist
      label={t("create_funder.steps.personal.type")}
      placeholder={t("create_funder.steps.personal.select_a_funding_type")}
      labelAlignment="left"
      {...props}
    >
      <Option
        name="CORPORATION"
        value="CORPORATE"
        label={t("funder_types.CORPORATE")}
      />
      <Option
        name="FOUNDATION"
        value="FOUNDATION"
        label={t("funder_types.FOUNDATION")}
      />
      <Option
        name="GOVERNMENT"
        value="GOVERNMENT"
        label={t("funder_types.GOVERNMENT")}
      />
      <Option
        name="ORGANIZATION"
        value="ORGANIZATION"
        label={t("funder_types.ORGANIZATION")}
      />
      <Option name="OTHER" value="OTHER" label={t("funder_types.OTHER")} />
    </Picklist>
  );
};

import React from "react";
import { useTranslation } from "react-i18next";
import { Input } from "react-rainbow-components";
import { Controller, useFormContext } from "react-hook-form";

import { CreateFunderSchema } from "settings/yup/schemas/createFunderSchema";
import getErrorMessage from "utils/getErrorMessage";
import { FormStepContainer } from "components/_new/theme/forms/FormStepContainer";
import FieldTitle from "components/FormComponents/MaskedInput/FieldTitle";

const Address: React.FC = () => {
  const [t] = useTranslation();
  const { errors, control } = useFormContext<CreateFunderSchema>();

  return (
    <FormStepContainer>
      <Controller
        as={Input}
        control={control}
        name="location.streetAddress"
        label={t("location_information_card.street_address")}
        error={getErrorMessage("location.streetAddress", errors)}
        labelAlignment="left"
      />
      <Controller
        as={Input}
        control={control}
        name="location.city"
        label={t("location_information_card.city")}
        error={getErrorMessage("location.city", errors)}
        labelAlignment="left"
      />
      <Controller
        as={Input}
        control={control}
        name="location.state"
        label={t("location_information_card.state")}
        error={getErrorMessage("location.state", errors)}
        labelAlignment="left"
      />
      <Controller
        name="location.zipcode"
        control={control}
        labelAlignment="left"
        render={({ value, onChange }) => {
          value = value.substring(0, 10).replace(/\D/g, '');
          if (value.length > 5) {
            const position = 5;
            value = value.substring(0, position) + "-" + value.substring(position);
          }
          value = value.substring(0, 10);
          return (
            <Input
              value={value}
              onChange={onChange}
              error={errors.name}
              label={t("location_information_card.zipcode")}
              labelAlignment="left"
              type="text"
            />
          );
        }}
        error={getErrorMessage("location.zipcode", errors)}
      />
    </FormStepContainer>
  );
};

export default Address;

import * as React from "react";
import { IconProps } from "@chakra-ui/icons";
import { Icon } from "@chakra-ui/react";

const VerticalEllipsisIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} viewBox="0 0 192 512">
    <path
      fill="currentColor"
      d="M96 184c39.8 0 72 32.2 72 72s-32.2 72-72 72-72-32.2-72-72 32.2-72 72-72zM24 80c0 39.8 32.2 72 72 72s72-32.2 72-72S135.8 8 96 8 24 40.2 24 80zm0 352c0 39.8 32.2 72 72 72s72-32.2 72-72-32.2-72-72-72-72 32.2-72 72z"
    />
  </Icon>
);

export default VerticalEllipsisIcon;

import React, { useEffect, useMemo }  from "react";
import { SimpleGrid, Text, Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";

import Card from "views/Dashboard/SummaryCard/Card";
import ArrowRightUpIcon from "settings/theme/ChakraTheme/icons/ArrowRightUpIcon";
import ClockIcon from "settings/theme/ChakraTheme/icons/ClockIcon";

import elementIds from "../elementIds";
import PrintableDetails from "./PrintableDetail";
import { FragmentType, graphql, useFragment } from "generated/graphql/v2";
import { pages } from "routes/pages";


import {
  FilterApplicationStatusEnum,
  ListApplicationsQuery,
  useListApplicationsLazyQuery,
} from "generated/graphql";
import maybeApply from "utils/maybeApply";
import { format, parseISO } from "date-fns";
import fiscalYearRange from "utils/fiscalYearRange";
import useFilter, { filters } from "hooks/useFilter";
import { useFiscalYear } from "hooks/useFiscalYear";

const breakAfterContainerCss = {
  "@media print": {
    pageBreakAfter: "always",
    "*": {
      boxShadow: "none !important",
    },
  },
} as const;

const DashboardApplicationsFragment = graphql(`
  fragment DashboardApplicationsFragment on Application {
    ...PrintDetailsFragment
    status
  }
`);

const DashboardSummaryFragment = graphql(`
  fragment DashboardSummaryFragment on AwardByYear {
    awarded
    awardedCount
    pending
    pendingCount
    requested
    requestedCount
  }
`);

const SummaryCard = (props: {
  summary: FragmentType<typeof DashboardSummaryFragment>;
  applications: FragmentType<typeof DashboardApplicationsFragment>[];
  fiscalYear: number;
}) => {
  const [t] = useTranslation();
  const summary = useFragment(DashboardSummaryFragment, props.summary);
  const submissionYear = String(props.fiscalYear);
  const applications = useFragment(
    DashboardApplicationsFragment,
    props.applications,
  );

  //The data printed out
  const { currentFiscalYearStart, organizationId } = useFiscalYear();

  const [listApplications, { data: tableData, loading: tableLoading }] =
    useListApplicationsLazyQuery();

  const [startAt, endAt] = useMemo(
    () =>
      maybeApply(currentFiscalYearStart, (year) => {
        const { start, end } = fiscalYearRange(
          parseISO(year),
          props.fiscalYear as number,
        );
        return [format(start, "yyyy-MM-dd"), format(end, "yyyy-MM-dd")];
      }) || [null, null],
    [currentFiscalYearStart, submissionYear],
  );

  useEffect(() => {
    if (!organizationId || !startAt || !endAt) {
      return;
    }

    listApplications({
      variables: {
        filters: {
          organizationId,
          status: FilterApplicationStatusEnum.Submitted,
          startAt,
          endAt,
        },
      },
    });
  }, [listApplications, organizationId, startAt, endAt]);

  const rawTableData = (tableData ?? {}) as ListApplicationsQuery;

  return (
    <SimpleGrid columns={{ base: 1, md: 1, lg: 3 }} gap={6} width="full">
      <Box css={breakAfterContainerCss} id={`for-print-${elementIds.applicationSent}`}>
        <Text mb={2} fontSize="md" className="show-on-print">
          Application Sent
        </Text>
        <Link
          to={pages.grantList.pathWithParams({
            grantStatus: "SUBMITTED",
            submissionYear,
          })}
        >
          <Card
            value={summary.requested}
            subtitle={t("dashboard.summary.applications_sent", {
              count: summary.requestedCount,
            })}
            icon={<ArrowRightUpIcon boxSize={10} color="primary.100" />}
          />
        </Link>

        <PrintableDetails
          id={`for-print-table-${elementIds.applicationSent}`}
          title={t("dashboard.summary.applications_list")}
          // needed to convert readonly array to mutable
          // details={[...applications]}
          grantStatus={FilterApplicationStatusEnum.Submitted}
          data={rawTableData}
        />
      </Box>

      <Box css={breakAfterContainerCss} id={`for-print-${elementIds.grantsAwarded}`}>
        <Text mb={2} fontSize="md" className="show-on-print">
          Grants Awarded
        </Text>
        <Link
          to={pages.grantList.pathWithParams({
            grantStatus: "AWARDED",
            submissionYear,
          })}
        >
          <Card
            value={summary.awarded}
            subtitle={t("dashboard.summary.grants_awarded", {
              count: summary.awardedCount,
            })}
            icon={<CheckCircleIcon boxSize={10} color="primary.100" />}
          />
        </Link>

        <PrintableDetails
          id={`for-print-table-${elementIds.grantsAwarded}`}
          title={t("dashboard.summary.grants_list")}
          // details={applications.filter((a) => a.status === "AWARDED")}
          grantStatus={FilterApplicationStatusEnum.Awarded}
          data={rawTableData}
        />
      </Box> 

      <Box css={breakAfterContainerCss} id={`for-print-${elementIds.grantsPending}`}>
        <Text mb={2} fontSize="md" className="show-on-print">
          Grants Pending
        </Text>
        <Link
          to={pages.grantList.pathWithParams({
            grantStatus: "PENDING",
            submissionYear,
          })}
        >
          <Card
            value={summary.pending}
            subtitle={t("dashboard.summary.grants_pending", {
              count: summary.pendingCount,
            })}
            icon={<ClockIcon boxSize={10} color="primary.100" />}
          />
        </Link>

        <PrintableDetails
          id={`for-print-table-${elementIds.grantsPending}`}
          title={t("dashboard.summary.grants_list")}
          // details={applications.filter((a) => a.status === "PENDING")}
          grantStatus={FilterApplicationStatusEnum.Pending}
          data={rawTableData}
        />
      </Box>
    </SimpleGrid>
  );
};

export default SummaryCard;

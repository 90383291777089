import { Flex } from "@chakra-ui/react";
import { Cell } from "components/_new/theme/Table";
import { Button } from "components/_new/theme/Button";
import { FragmentType, graphql, useFragment } from "generated/graphql/v2";
import React from "react";
import { useTranslation } from "react-i18next";
import EditFileModal, { EditFileFragmentType } from "../Modals/EditFileModal";
import { useMutation } from "@apollo/client";
import useDelete from "hooks/useDelete";
import { useModal } from "contexts/modal";

const FileRowFragment = graphql(`
  fragment FileRowFragment on File {
    id
    name
    insertedAt
    updatedAt
    signedUrl
    ...EditFileFragment
  }
`);

const deleteFileMutation = graphql(`
  mutation deleteFile($id: Int!) {
    deleteFile(id: $id) {
      id
    }
  }
`);

export const FileRow: React.FC<{
  file: FragmentType<typeof FileRowFragment>;
  funderId: number;
}> = (props) => {
  const [t] = useTranslation();
  const file = useFragment(FileRowFragment, props.file);
  const { funderId } = props;

  const [showModal] = useModal();
  const openEditFileModal = (file: EditFileFragmentType) => {
    showModal({
      title: t(`modals.edit_file.title`),
      component: EditFileModal,
      componentProps: {
        funderId,
        file,
      },
    });
  };

  const [deleteFile] = useMutation(deleteFileMutation, {
    refetchQueries: ["getFunderUploads"],
  });
  const deleteFileCb = useDelete({
    mutate: deleteFile,
    entryType: "file",
    modalTitle: t("modals.delete_file.title"),
  });

  return (
    <>
      <Cell>{file.name}</Cell>
      <Cell>{file.insertedAt}</Cell>
      <Cell>{file.updatedAt}</Cell>
      <Cell>
        <Flex justifyContent="center" width="full" gap={4}>
          <a href={file.signedUrl}>
            <Button background="primary.200">
              {t("funder_documents.download")}
            </Button>
          </a>
          <Button
            onClick={() => openEditFileModal(file)}
            background="primary.200"
          >
            {t("actions.move")}
          </Button>
          <Button
            onClick={() =>
              deleteFileCb(file.name, {
                variables: { id: file.id },
              })
            }
            background="primary.100"
          >
            {t("actions.delete")}
          </Button>
        </Flex>
      </Cell>
    </>
  );
};

import React, { useCallback, useState } from "react";
import { addDays, formatISO } from "date-fns";
import useDate from "react-use-date";
import { useTranslation } from "react-i18next";
import { useModal } from "contexts/modal";
import DeadlineReportModal from "components/Modal/DeadlineReportModal";
import {
  FilterApplicationStatusEnum,
  SortingOrder,
  useListApplicationsQuery,
  useListReportsQuery,
} from "generated/graphql";
import { grantFlowFormattedColumns } from "constants/grantFlowTableTitles";
import Table from "components/Table";
import Button from "components/Button";
import formatGrantFlowTableData from "utils/formatGrantFlowTableData";
import TableHeader from "components/Table/TableHeader";
import { titleFontSize } from "components/Table/TableHeader"
import { useFiscalYear } from "hooks/useFiscalYear";
import formatReportsTableData from "utils/formatReportsTableData";
import { reportCenterFormattedColumns } from "constants/reportCenterTableTitles";
import { ListApplicationDeadlinesQuery } from "generated/graphql/v2/graphql";
import { Text, Box, Flex } from "@chakra-ui/react";
import elementIds from "./elementIds";
import { Option, Picklist } from "react-rainbow-components";

const breakAfterContainerCss = {
  "@media print": {
    pageBreakAfter: "always",
    "*": {
      boxShadow: "none !important",
    },
  },
} as const;

const Deadlines: React.FC = () => {
  const { organizationId = 0 } = useFiscalYear();
  const [nextDaysOptions, setNextDaysOptions] = useState([
    {
      value: 7,
      label: "7 Days"
    },
    {
      value: 30,
      label: "30 Days"
    },
    {
      value: 60,
      label: "60 Days"
    },
    {
      value: 90,
      label: "90 Days"
    },
    {
      value: 180,
      label: "180 Days"
    },
    {
      value: 365,
      label: "365 Days"
    }
  ]);
  // by default select the 90 days option
  const [selectedNextDaysOption, setSelectedNextDaysOption] = useState(nextDaysOptions[3]);

  const onSelectNextDays = (nextDays: number) => {
    let selected = nextDaysOptions.find(item => item.value == nextDays);
    if (selected == undefined) {
      selected = nextDaysOptions[0];
    }
    setSelectedNextDaysOption(selected)
  }


  const today = useDate({ interval: 1000 * 60 * 60 * 24 });
  const { t } = useTranslation();

  const { data: grants = {}, loading: grantsLoading } =
    useListApplicationsQuery({
      variables: {
        filters: {
          startAt: formatISO(today, { representation: "date" }),
          endAt: formatISO(addDays(today, selectedNextDaysOption.value), { representation: "date" }),
          organizationId,
          status: FilterApplicationStatusEnum.NotApply,
        },
        pagination: {
          orderBy: "deadline",
          sortingOrder: SortingOrder.Asc,
        },
      },
    });

  const { data: reports = {}, loading: reportsLoading } = useListReportsQuery({
    variables: {
      filters: {
        organizationId,
        startAt: formatISO(today, { representation: "date" }),
        endAt: formatISO(addDays(today, selectedNextDaysOption.value), { representation: "date" }),
        submitted: false,
      },
    },
  });

  const grantDeadlinesData = formatGrantFlowTableData(
    grants as ListApplicationDeadlinesQuery,
  );

  const reportDealinesData = formatReportsTableData(reports);

  const [showModal] = useModal();
  const [dataReady, setDataReady] = useState(true);
  const openDeadlineReport = useCallback((docType) => {
    showModal({
      title: t("dashboard.next_90_days.report_title").replace("90", selectedNextDaysOption.value.toString()),
      component: DeadlineReportModal,
      closeOnOverlayClick: false,
      componentProps: {
        docType: docType,
        grantDeadlinesData: grantDeadlinesData,
        reportDeadlinesData: reportDealinesData
      }
      // onClose: () => {}
    });
  }, [showModal, t, grantDeadlinesData, reportDealinesData]);

  return (
    <>
      <Flex flexDirection="column" overflow="hidden">
        <Box className="hide-on-print" >
          <TableHeader
            margin={8}
            title={t("dashboard.next_90_days.title").replace("90", selectedNextDaysOption.value.toString())}
            flexDirection={{ base: "column", md: "row" }}
            maxWidth={"94vw"}
          >
            <Flex flexDirection="row">
              <div className="pr-30">
                <Picklist
                  value={selectedNextDaysOption}
                  onChange={({ value }) => onSelectNextDays(value)}
                  placeholder="Choose Next Days"
                >
                  {nextDaysOptions.map((daysItem) => (
                    <Option value={daysItem.value} label={daysItem.label} name={daysItem.value} key={daysItem.value} />
                  ))}
                </Picklist>
              </div>


              <Button
                background="primary.100"
                fontSize={{ base: "xxs", md: "xs", lg: "xs" }}
                onClick={() => openDeadlineReport("pdf")}
                disabled={!dataReady}
                className="hide-on-print"
                marginRight="2rem"
              >
                {t("dashboard.next_90_days.download_report_in_pdf")}
              </Button>

              <Button
                background="primary.100"
                fontSize={{ base: "xxs", md: "xs", lg: "xs" }}
                onClick={() => openDeadlineReport("excell")}
                disabled={!dataReady}
                className="hide-on-print"
              >
                {t("dashboard.next_90_days.download_report_in_excell")}
              </Button>
            </Flex>
          </TableHeader>
        </Box>

        <Text
          mb={2}
          className="show-on-print"
          color="primary.200"
          fontFamily="heading"
          fontSize={titleFontSize("md")}
          textTransform="uppercase"  >
          {t("dashboard.next_90_days.title").replace("90", selectedNextDaysOption.value.toString())}
        </Text>

        <Box css={breakAfterContainerCss} id={'for-print-' + elementIds.grantDeadlines}>

          <Table
            table={grantDeadlinesData}
            tableColumns={grantFlowFormattedColumns}
            isLoading={grantsLoading}
            className="hide-on-print"
          >
            <TableHeader
              title={t("dashboard.next_90_days.grants_table_title").replace("90", selectedNextDaysOption.value.toString())}
              flexDirection={{ base: "column", md: "row" }}
              titleSize="md"
            >

            </TableHeader>
          </Table>
          {/* for printing out */}
          <Box display="none" css={{ "@media print": { display: "block" } }}>
            <Table
              table={grantDeadlinesData}
              tableColumns={grantFlowFormattedColumns}
              isLoading={grantsLoading}
              pageSize={1000}
              className="printable-detail-table"
            >
              <TableHeader
                title={t("dashboard.next_90_days.grants_table_title").replace("90", selectedNextDaysOption.value.toString()) + ' printable'}
                flexDirection={{ base: "column", md: "row" }}
                titleSize="md"
              />
            </Table>
          </Box>
        </Box>
        <Box css={breakAfterContainerCss} id={'for-print-' + elementIds.reportDeadlines}>
          
          <Table
            table={formatReportsTableData(reports)}
            tableColumns={reportCenterFormattedColumns}
            isLoading={reportsLoading}
            className="hide-on-print"
          >
            <TableHeader
              title={t("dashboard.next_90_days.reports_table_title").replace("90", selectedNextDaysOption.value.toString())}
              flexDirection={{ base: "column", md: "row" }}
              titleSize="md"
            />
          </Table>
          {/* for printing out */}
          <Box display="none" css={{ "@media print": { display: "block" } }}>
            <Table
              table={formatReportsTableData(reports)}
              tableColumns={reportCenterFormattedColumns}
              isLoading={reportsLoading}
              pageSize={1000}
            >
              <TableHeader
                title={t("dashboard.next_90_days.reports_table_title").replace("90", selectedNextDaysOption.value.toString()) + ' printable'}
                flexDirection={{ base: "column", md: "row" }}
                titleSize="md"
              />
            </Table>
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export default Deadlines;

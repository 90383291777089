import { Box } from "@chakra-ui/react";
import React from "react";

import { CardContainerProps } from "./types";

const CardContainer: React.FC<CardContainerProps> = ({
  children,
  ...props
}) => (
  <Box
    {...props}
    width="full"
    boxShadow="none"
    borderRadius="4px"
    background="white"
    padding={5}
    color="rgba(0,0,0,0.87)"
    border="0.0625rem solid rgba(223,223,223,1)"
    _hover={{
      boxShadow: "0 2px 12px 0 rgba(173,173,173,1)",
      transition: "all 150ms cubic-bezier(0.4,0,0.2,1) 0ms",
    }}
  >
    {children}
  </Box>
);

export default CardContainer;

import * as React from "react";
import { IconProps } from "@chakra-ui/icons";
import { Icon } from "@chakra-ui/react";

const ClockIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} viewBox="0 0 800 800">
    <path
      fill="currentColor"
      d="M400 0C179.032 0 0 179.032 0 400C0 620.968 179.032 800 400 800C620.968 800 800 620.968 800 400C800 179.032 620.968 0 400 0ZM549.177 504.839L516.919 545.161C514.802 547.808 512.185 550.012 509.216 551.647C506.248 553.282 502.986 554.316 499.617 554.691C496.249 555.065 492.84 554.772 489.585 553.829C486.329 552.886 483.292 551.311 480.645 549.194L372.581 469C365.03 462.955 358.935 455.289 354.747 446.57C350.56 437.851 348.386 428.302 348.387 418.629V167.742C348.387 160.898 351.106 154.334 355.946 149.494C360.785 144.654 367.349 141.935 374.194 141.935H425.806C432.651 141.935 439.215 144.654 444.054 149.494C448.894 154.334 451.613 160.898 451.613 167.742V400L545.161 468.548C547.809 470.667 550.014 473.286 551.648 476.257C553.283 479.227 554.317 482.491 554.69 485.861C555.063 489.232 554.768 492.642 553.822 495.899C552.876 499.155 551.298 502.193 549.177 504.839Z"
    />
  </Icon>
);

export default ClockIcon;

import { Stack } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import { useModal } from "contexts/modal";
import CreateGrantForm from "components/CreateGrantForm";
import CreateFunderForm from "components/CreateFunderForm";
import { useCreateProgramModal } from "modals/CreateProgram";
import useNavigate from "hooks/useNavigate";
import { CreateFunderMutation } from "generated/graphql";
import { pages } from "routes/pages";

const GrantFlowActions: React.FC = () => {
  const [t] = useTranslation();
  const [showModal] = useModal();
  const navigate = useNavigate();

  const buttonsFontSize = { base: "xxs", md: "xxs", lg: "xs" };

  const openCreateNewGrantModal = useCallback(() => {
    showModal({
      title: t("create_grant.title"),
      component: CreateGrantForm,
      closeOnOverlayClick: false,
    });
  }, [t, showModal]);

  const successCallback = useCallback(
    (funder?: CreateFunderMutation["createFunder"]) => {
      const funderPageUrl = funder?.id
        ? pages.funderProfile.pathWithParams(funder.id)
        : pages.fundersList.pathWithParams();

      navigate(funderPageUrl)();
    },
    [navigate],
  );

  const openCreateNewFunderModal = useCallback(() => {
    showModal({
      title: t("create_funder.title"),
      component: CreateFunderForm,
      componentProps: {
        successCallback,
      },
      closeOnOverlayClick: false,
    });
  }, [t, showModal, successCallback]);

  const openCreateNewProgramModal = useCreateProgramModal();

  return (
    <Stack
      spacing={6}
      direction={{ base: "column", md: "row" }}
      mt={{ md: "0 !important" }}
    >
      <Button
        background="#6364ED"
        fontSize={buttonsFontSize}
        onClick={openCreateNewFunderModal}
      >
        {t("buttons.add_new_funder")}
      </Button>

      <Button
        background="secondary.200"
        fontSize={buttonsFontSize}
        onClick={openCreateNewProgramModal}
      >
        {t("buttons.add_new_program")}
      </Button>

      <Button
        background="#0C006E"
        fontSize={buttonsFontSize}
        onClick={openCreateNewGrantModal}
      >
        {t("buttons.add_new_grant")}
      </Button>
    </Stack>
  );
};

export default GrantFlowActions;

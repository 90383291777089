import { FunderTypeEnum } from "generated/graphql";
import yup from "settings/yup";

const createFunderSchema = yup.object().shape({
  name: yup.string().required(),
  type: yup.object().required(),
  email: yup.string(),
  phone: yup.string(),
  keyContacts: yup.string(),
  website: yup.string(),
  complexityScore:
    yup
      .string()
      .trim()
      .test(
        'valid-complexity-score',
        'Input a number between 0 and 20',
        (value) => {
          if (!value || value.length === 0) return true; // Allow empty values
          const validValues = Array.from({ length: 21 }, (_, i) => String(i)); // Generate ["0", "1", ..., "20"]
          return validValues.includes(value); // Validate only against allowed values
        }
      ),
  location: yup.object().shape({
    city: yup.string(),
    complement: yup.string(),
    state: yup.string(),
    streetAddress: yup.string(),
    zipcode: yup.string(),
  })
});

export interface CreateFunderSchema {
  name: string;
  type: FunderTypeOption;
  email: string;
  phone: string;
  keyContacts: string;
  website: string;
  location: FunderLocation;
  complexityScore: string;
}

export interface FunderLocation {
  city: string;
  complement: string;
  state: string;
  streetAddress: string;
  zipcode: string;
}

export interface FunderTypeOption {
  value: FunderTypeEnum;
  name?: string;
  icon?: unknown;
  label: string;
}

export default createFunderSchema;

import { useState, useEffect } from "react";

export const useDebouncedState = <T>(
  initialValue: T,
  wait = 1000,
): [T, T, (value: T) => void] => {
  const [value, setValue] = useState(initialValue);
  const [debouncedValue, setDebouncedValue] = useState(initialValue);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValue(value);
    }, wait);

    return () => {
      clearTimeout(timeout);
    };
  }, [value, wait]);

  return [value, debouncedValue, setValue];
};

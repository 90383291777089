import React, { useCallback } from "react";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { LockIcon } from "@chakra-ui/icons";
import { Input, Button } from "react-rainbow-components";

import getErrorMessage from "utils/getErrorMessage";
import CustomCard from "components/CustomCard";
import useSession from "hooks/useSession";
import { useToast } from "contexts/toast";
import resetPasswordSchema, {
  ResetPasswordSchema,
} from "settings/yup/schemas/resetPasswordSchema";
import { useResetPasswordMutation } from "generated/graphql";
import EmojiWrapper from "components/EmojiWrapper";
import MailIcon from "settings/theme/ChakraTheme/icons/MailIcon";
import { pages } from "routes/pages";

const ResetPassword: React.FC = () => {
  const [createSession] = useSession();
  const [t] = useTranslation();
  const { addToast } = useToast();

  const resetPasswordToken = queryString.parse(window.location.search)
    ?.token as string;

  const { errors, formState, handleSubmit, control } =
    useForm<ResetPasswordSchema>({
      resolver: yupResolver(resetPasswordSchema),
      mode: "onBlur",
      defaultValues: {
        password: "",
        passwordConfirmation: "",
      },
    });

  const [resetPassword, { loading }] = useResetPasswordMutation();

  const onSubmit = useCallback(
    (values) => {
      resetPassword({
        variables: {
          password: values.password,
          token: resetPasswordToken,
        },
      })
        .then((response) => {
          const token = response?.data?.resetPassword;

          if (token) {
            addToast({
              title: t("reset_password.youre_back"),
              description: t("reset_password.your_password_has_been_reset"),
              type: "success",
            });

            createSession(token, pages.dashboard.pathWithParams());
          }
        })
        .catch((error) => {
          addToast({
            title: error?.message,
            type: "error",
          });
        });
    },
    [t, addToast, resetPassword, createSession, resetPasswordToken],
  );

  return (
    <form className="reset-password-wrapper">
      <CustomCard
        title={t("reset_password.title")}
        shouldAnimate
        shouldShowLogo
      >
        <h6 className="reset-password-info">
          {t("reset_password.password_are_a_major_defense")}{" "}
          <EmojiWrapper ariaLabel="shushing face" emoji="🤫" />
        </h6>

        <Controller
          as={Input}
          control={control}
          name="password"
          type="password"
          required
          icon={<MailIcon />}
          label={t("reset_password.new_password")}
          placeholder={t("reset_password.enter_your_new_password")}
          className="reset-password-input-field"
          error={getErrorMessage("password", errors)}
          iconPosition="right"
        />

        <Controller
          as={Input}
          control={control}
          name="passwordConfirmation"
          type="password"
          required
          icon={<LockIcon />}
          label={t("reset_password.password_confirmation")}
          placeholder={t("reset_password.confirm_your_new_password")}
          className="reset-password-input-field"
          error={getErrorMessage("passwordConfirmation", errors)}
          iconPosition="right"
        />

        <div className="reset-password-actions-container">
          <Button
            label={t("reset_password.reset_password")}
            variant="brand"
            type="submit"
            onClick={handleSubmit(onSubmit)}
            isLoading={loading}
            disabled={!formState.isValid}
          />
        </div>
      </CustomCard>
    </form>
  );
};

export default ResetPassword;

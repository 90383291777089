import { IsSubmittedEnum } from "components/EditGrantForm/types";
import { ComfirmDeleteEnum } from "components/DeleteGrantForm/types";

export const submitOptions = [
  { value: IsSubmittedEnum.Yes, label: "Yes" },
  { value: IsSubmittedEnum.No, label: "No" },
  { value: IsSubmittedEnum.Ineligible, label: "Ineligible" },
];

export const deleteOptions = [
  { value: ComfirmDeleteEnum.Yes, label: "Yes" },
  { value: ComfirmDeleteEnum.No, label: "No" },
];

import { Input } from "react-rainbow-components";
import type { InputProps } from "react-rainbow-components/components/Input";
import React from "react";
import formatCurrency from "utils/formatCurrency";

export const MoneyInput: React.FC<
  InputProps & {
    value: string;
    onChange: (value: string) => void;
  }
> = (props) => (
  <Input
    labelAlignment="left"
    {...props}
    value={formatCurrency(props.value)}
    onChange={(e) => props.onChange(e.target.value.replace(/[^0-9.]/g, ""))}
  />
);

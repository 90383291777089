import { Flex } from "@chakra-ui/react";
import React, { useMemo, memo, useEffect } from "react";
import { Option, Picklist } from "react-rainbow-components";

import getFiscalYearsInInterval from "utils/getFiscalYearsInInterval";
import useFilter, { filters } from "hooks/useFilter";

import { YearFilterProps } from "./types";

const YearFilter: React.FC<YearFilterProps> = ({
  endDate,
  startDate,
  filter,
  fiscalYearStart,
}) => {
  const [currentYear, setFilter] = useFilter(filter);
  // eslint-disable-next-line
  const [_awardsComparisonYear, setAwardsComparisonYear] = useFilter(
    filters.awardsComparisonYear,
  );
  // eslint-disable-next-line
  const [_fundingByTypeYear, setFundingByTypeYear] = useFilter(
    filters.fundingByTypeYear,
  );

  const options = useMemo(
    () =>
      getFiscalYearsInInterval(
        startDate,
        endDate || new Date(),
        fiscalYearStart || new Date(1900, 1, 1),
      ),
    [endDate, startDate, fiscalYearStart],
  );

  useEffect(() => {
    if (!currentYear) {
      // setFilter(options?.[options?.length - 1]);

      setFilter(options?.[0]);
    }
  }, [currentYear, options, setFilter]);

  const handleChange = (option: { value?: unknown }): void => {
    setAwardsComparisonYear(option?.value);
    setFundingByTypeYear(option?.value);
    setFilter(option?.value);
  };

  return (
    <Flex maxWidth="125px">
      <Picklist
        value={{ value: currentYear, label: `FY ${currentYear}` }}
        onChange={handleChange}
      >
        {options?.map((year) => (
          <Option value={year} label={`FY ${year}`} name={year} key={year} />
        ))}
      </Picklist>
    </Flex>
  );
};
export default memo(YearFilter);

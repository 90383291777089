import React from "react";
import {
  Text,
  TextProps,
  Button as CButton,
  ButtonProps,
} from "@chakra-ui/react";

export const Button: React.FC<
  ButtonProps & {
    isLoading?: boolean;
    textProps?: TextProps;
    disabled?: boolean;
  }
> = ({ isLoading = false, children, textProps, disabled, ...rest }) => (
  <CButton
    padding="0px 16px"
    outline="none"
    fontSize="xs"
    fontFamily="heading"
    color="white"
    borderRadius={2}
    disabled={disabled}
    _hover={{ color: "white" }}
    _focus={{ boxShadow: "none", color: "white" }}
    _disabled={{ background: "gray.200", color: "white" }}
    isLoading={isLoading}
    _active={{
      transform: "scale(0.98)",
      transition: "transform 0.1s",
    }}
    {...rest}
  >
    <>
      {typeof children === "string" ? (
        <Text as="span" {...textProps}>
          {children}
        </Text>
      ) : (
        children
      )}
    </>
  </CButton>
);

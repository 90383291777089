import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Chart, Dataset } from "react-rainbow-components";
import datalabels from "chartjs-plugin-datalabels";
import { LabelOptions } from "chartjs-plugin-datalabels/types/options";

import CardContainer from "components/Cards/CardContainer";
import CardHeader from "components/Cards/CardHeader";
import { FragmentType, graphql, useFragment } from "generated/graphql/v2";

const colors = ["#4949ff", "#4949ff", "#4949ff", "#4949ff"];

const datalabelsConfigs: LabelOptions = {
  color: "black",
  anchor: "end",
  align: "right",
  offset: 0,
  padding: 2,
};

const options = {
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        ticks: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          callback: (value) => (Math.floor(value * 10) % 10 ? null : value),
        },
        gridLines: {
          display: false,
        },
      },
    ],
  },
};

const FunderTypesCountFragment = graphql(`
  fragment FunderTypesCountFragment on FunderTypesCount {
    foundation
    corporate
    government
    other
  }
`);

const FundingsByType = (props: {
  funderTypes: FragmentType<typeof FunderTypesCountFragment>;
  SelectYear: React.ReactElement;
}) => {
  const [t] = useTranslation();
  const funderTypes = useFragment(FunderTypesCountFragment, props.funderTypes);

  // gotta pick the keys out for future compatibility
  // if we want to extend this for the future, the query should return a list
  const funderCounts = {
    corporate: funderTypes.corporate || 0,
    foundation: funderTypes.foundation || 0,
    government: funderTypes.government || 0,
    other: funderTypes.other || 0,
  };

  return (
    <CardContainer height="full" display="flex" flexDirection="column">
      <CardHeader title={t("dashboard.funding_by_type.funding_by_type")}>
        {props.SelectYear}
      </CardHeader>

      <Chart
        type="horizontalBar"
        plugins={[datalabels]}
        labels={Object.keys(funderCounts).map((type) =>
          t(`funder_types.${type.toUpperCase()}`),
        )}
        datalabels={datalabelsConfigs}
        disableXAxisGridLines
        disableYAxisGridLines
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        options={options}
      >
        <Dataset
          values={Object.values(funderCounts)}
          backgroundColor={colors}
          title=""
        />
      </Chart>
    </CardContainer>
  );
};

export default memo(FundingsByType);

import React, { useCallback, useMemo } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";

import FormWizard from "components/FormWizard";
import createGrantSchema, {
  CreateGrantSchema,
} from "settings/yup/schemas/createGrantSchema";
import { graphql } from "generated/graphql/v2";
import type { CreateGrantParams, Funder } from "generated/graphql/v2/graphql";
import { createFunderRefetchQueries } from "graphql/mutations/createFunderMutation";
import { useToast } from "contexts/toast";
import useWizardStore from "components/Wizard/store";
import { getFloat } from "utils/getFloat";
import { formatDate, yearMonthDay } from "utils/dateFormats";
import { IsSubmittedEnum } from "components/EditReportForm/types";
import useNavigate from "hooks/useNavigate";
import getFunderQuery from "graphql/queries/getFunderQuery";
import capitalizeStringFirstLetter from "utils/capitalizeStringFirstLetter";

import createGrantSteps from "./steps";
import ReportsFieldArrayContext from "./ReportsFieldArrayContext";
import { ModalComponentProps } from "contexts/modal/types";
import { pages } from "routes/pages";

const CREATE_GRANT = graphql(`
  mutation CreateGrant($params: CreateGrantParams!) {
    createGrant(params: $params) {
      id
      funder {
        organization {
          id
          __typename
        }
        __typename
      }
      __typename
    }
  }
`);

type CreateGrantFormProps = ModalComponentProps<{
  redirectPath?: string;
  selectedFunder?: Pick<Funder, "id" | "name">;
}>;

const CreateGrantForm: React.FC<CreateGrantFormProps> = ({
  hideModal,
  componentProps,
}) => {
  const { addToast } = useToast();
  const navigate = useNavigate();

  const [t] = useTranslation();

  const { setStepIndex } = useWizardStore((store) => ({
    setStepIndex: store.setStepIndex,
  }));

  const { redirectPath, selectedFunder } = componentProps || {};

  const funder = useMemo(
    (): Record<string, unknown> | undefined =>
      selectedFunder
        ? {
          label: selectedFunder?.name,
          value: selectedFunder?.id,
        }
        : undefined,
    [selectedFunder],
  );

  const form = useForm<CreateGrantSchema>({
    resolver: yupResolver(createGrantSchema),
    mode: "onChange",
    defaultValues: {
      funder: funder ?? { value: undefined },
      recurrenceCount: { value: undefined },
      program: { value: undefined },
      frequency: undefined,
      applicationType: undefined,
      applicationDeadline: undefined,
      reports: [],
      isSubmitted: undefined,
      dateSubmitted: undefined,
      amountRequested: "",
      deadline: undefined,
      status: undefined,
      receivementDate: undefined,
      declinedDate: undefined,
      declinedReason: "",
      amountAwarded: "",
    },
    shouldUnregister: false,
  });

  const reportsFieldArray = useFieldArray({
    control: form.control,
    name: "reports",
  });

  const [createGrant, { loading }] = useMutation(CREATE_GRANT, {
    update: (cache, data) => {
      const organization = data.data?.createGrant?.funder.organization;
      if (!organization) return;

      cache.evict({ id: cache.identify(organization) });
      cache.evict({ fieldName: "listApplications" });
      cache.gc();
    },
  });

  const onSubmit = useCallback(
    (valuesForSchema: CreateGrantSchema) => {
      const values = { ...valuesForSchema };

      if (
        values?.isSubmitted &&
        values.isSubmitted === IsSubmittedEnum.Ineligible
      ) {
        values.status = values.isSubmitted;
        values.isSubmitted = IsSubmittedEnum.No;
      }
      let declinedDetails: CreateGrantParams["application"]["declinedDetails"];

      if (values?.declinedDate) {
        declinedDetails = {
          declinedReason: values?.declinedReason,
          declinedDate: formatDate(values?.declinedDate, yearMonthDay),
        };
      }

      const notApply =
        values.status === "NOT_APPLY" || values.status === "INELIGIBLE";

      const params: CreateGrantParams = {
        funderId: values.funder.value,
        programId: values.program.value,
        frequency: values.frequency,
        recurrenceCount: values.recurrenceCount.value,
        application: {
          deadline: formatDate(values?.applicationDeadline, yearMonthDay),
          deadlineType: values?.applicationType?.value,
          declined: values?.status === "DECLINED",
          notApply,
          submission: {
            amountRequested:
              getFloat(values?.amountRequested)?.toString() || "0",
            submissionDate: formatDate(values?.dateSubmitted, yearMonthDay),
          },
          reports: values.reports.map(({ urgency, deadline }) => ({
            urgency,
            deadline: formatDate(deadline, yearMonthDay),
          })),
          award: {
            amountAwarded: getFloat(values?.amountAwarded)?.toString() || "0",
            receivementDate:
              formatDate(values?.receivementDate, yearMonthDay) || "",
          },
          declinedDetails,
          isSubmittedAsIneligible: values?.status === "INELIGIBLE",
          ineligibleReason: values?.ineligibleReason ?? "",
        },
      };

      if (values?.isSubmitted !== IsSubmittedEnum.Yes) {
        params.application.submission = undefined;
        params.application.declined = undefined;
      }

      if (!values?.amountAwarded) {
        params.application.award = undefined;
      }

      createGrant({
        variables: {
          params,
        },
        refetchQueries: [
          {
            query: getFunderQuery,
            variables: { id: params.funderId },
          },
          ...createFunderRefetchQueries,
        ],
      })
        .then(() => {

          // https://github.com/orgs/Grantflow2/projects/1/views/3?pane=issue&itemId=63045728
          const funderPageUrl = pages.funderProfile.pathWithParams(values.funder.value);
          navigate(funderPageUrl)();

          addToast({
            title: t("create_grant.success"),
            type: "success",
          });

          hideModal();

          setStepIndex(0);
        })
        .catch((error: Error) => {
          addToast({
            title: capitalizeStringFirstLetter(error.message),
            type: "error",
          });
        });
    },
    [t, addToast, navigate, hideModal, createGrant, setStepIndex, redirectPath],
  );

  return (
    <ReportsFieldArrayContext.Provider value={reportsFieldArray}>
      <FormProvider {...form}>
        <FormWizard
          steps={createGrantSteps}
          isLoading={loading}
          onSubmit={form.handleSubmit(onSubmit)}
        />
      </FormProvider>
    </ReportsFieldArrayContext.Provider>
  );
};

export default CreateGrantForm;

import React from "react";
import { VStack } from "@chakra-ui/react";

// import EditGrantForm from "components/EditGrantForm";
import DeleteGrantForm from "components/DeleteGrantForm";
import { useGetApplicationQuery } from "generated/graphql";
import Loading from "components/Loading";

import { DeleteGrantModalProps } from "./types";

const DeleteGrantModal: React.FC<DeleteGrantModalProps> = ({
  hideModal,
  componentProps: { id, buttonLabel, onClickDelete },
}) => {
  const { data: grantData, loading } = useGetApplicationQuery({
    variables: {
      id,
    },
  });

  return (
    <VStack spacing={6}>
      {loading && <Loading height="xxs" />}

      {!loading && (
        <DeleteGrantForm
          grantData={grantData}
          buttonLabel={buttonLabel}
          hideModal={hideModal}
          onClickDeletGrantBtn={onClickDelete}
        />
      )}
    </VStack>
  );
};

export default DeleteGrantModal;

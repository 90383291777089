import React from "react";
import { Redirect } from "react-router-dom";

import { useCurrentUser } from "contexts/currentUser";
import { pages } from "routes/pages";

const Home: React.FC = () => {
  const [currentUser, { loading: loadingCurrentUser }] = useCurrentUser();

  if (loadingCurrentUser) {
    return null;
  }

  if (currentUser) {
    return <Redirect to={pages.dashboard.pathWithParams()} />;
  }

  return <Redirect to={pages.login.pathWithParams()} />;
};

export default Home;

import * as React from "react";

import type { CurrentUserContextPayload } from "./CurrentUserContainer";

const CurrentUserContext = React.createContext<CurrentUserContextPayload>([
  undefined,
  {
    loading: false,
    error: undefined,
  },
]);

export const CurrentUserProvider = CurrentUserContext.Provider;

/**
 * Exposes the current user data.
 */
export const useCurrentUser = (): CurrentUserContextPayload => {
  const context = React.useContext(CurrentUserContext);

  if (!context) {
    throw new Error("useCurrentUser should be within CurrentUserProvider");
  }

  return context;
};

import React, { useCallback } from "react";
import { Flex, ModalBody, ModalFooter } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Checkbox from "components/FormComponents/Checkbox";

import Button from "components/Button";
import grantUpdateSchema, {
  GrantUpdateSchema,
} from "settings/yup/schemas/grantUpdateSchema";
import {
  ApplicationStatusEnum,
  DeadlineTypeEnum,
  UpdateApplicationParams,
  useUpdateApplicationMutation,
} from "generated/graphql";
import {
  formatDate,
  displayMonthDayYear,
  yearMonthDay,
} from "utils/dateFormats";
import { useToast } from "contexts/toast";
import { getFloat } from "utils/getFloat";
import { IsSubmittedEnum } from "components/EditReportForm/types";
import capitalizeStringFirstLetter from "utils/capitalizeStringFirstLetter";

import { Controller } from "react-hook-form";
import {
  Picklist,
  Option
} from "react-rainbow-components";
import getErrorMessage from "utils/getErrorMessage";

import { EditGrantFormProps } from "./types";
import EditGrantFormFields from "./EditGrantFormFields";
import { Application } from "generated/graphql/v2/graphql";

import { graphql } from "generated/graphql/v2";
import { useOrganizationId } from "hooks/_new/useOrganization";
import { useQuery } from "@apollo/client";
import { SelectDeadlineType } from "components/_new/theme/inputs/SelectDeadlineType";


const ListProgramAndFunderOptionsQuery = graphql(`
  query ListProgramAndFunderOptions($organizationId: Int!) {
    listFunders(filters: { organizationId: $organizationId }) {
      id
      name
    }

    listPrograms(organizationId: $organizationId) {
      id
      name
    }
  }
`);



const EditGrantForm: React.FC<EditGrantFormProps> = ({
  hideModal,
  buttonLabel,
  grantData,
  onClickDeletGrantBtn,
  program
}) => {

  const organizationId = useOrganizationId();
  const { data } = useQuery(ListProgramAndFunderOptionsQuery, {
    variables: { organizationId },
  });
  const listPrograms = data?.listPrograms ?? [];

  const grantId = grantData?.getApplication?.id;

  const initialFormValues = grantData?.getApplication;

  const { addToast } = useToast();

  const [t] = useTranslation();

  const initialIsSubmitted = initialFormValues?.isSubmittedAsIneligible
    ? IsSubmittedEnum.Ineligible
    : initialFormValues?.submission?.submissionDate
      ? IsSubmittedEnum.Yes
      : IsSubmittedEnum.No;

  const initialStatus = initialIsSubmitted == IsSubmittedEnum.Yes ?
    (initialFormValues?.status != ApplicationStatusEnum.Awarded &&
      initialFormValues?.status != ApplicationStatusEnum.Declined
      ? ApplicationStatusEnum.Pending : initialFormValues?.status
    )
    : initialFormValues?.status;

  const { handleSubmit, errors, control, register, watch } =
    useForm<GrantUpdateSchema>({
      resolver: yupResolver(grantUpdateSchema),
      mode: "onChange",
      defaultValues: {
        isSubmitted: initialIsSubmitted,
        dateSubmitted:
          initialFormValues?.submission?.submissionDate &&
          formatDate(
            initialFormValues?.submission?.submissionDate,
            displayMonthDayYear,
          ),
        amountRequested: initialFormValues?.submission?.amountRequested,
        receivementDate:
          initialFormValues?.award?.receivementDate &&
          formatDate(
            initialFormValues?.award?.receivementDate,
            displayMonthDayYear,
          ),
        deadline:
          initialFormValues?.deadline &&
          formatDate(initialFormValues?.deadline, displayMonthDayYear),
        status: initialStatus,
        amountAwarded: initialFormValues?.award?.amountAwarded,
        reports: grantData?.getApplication?.reports,
        declinedDate: initialFormValues?.declinedDetails?.declinedDate,
        declinedReason:
          initialFormValues?.declinedDetails?.declinedReason || "",
        ineligibleReason: initialFormValues?.ineligibleReason,
        program: { label: program?.name, value: program?.id },
        deadlineType: {
          label: capitalizeStringFirstLetter(
            (
              (initialFormValues?.deadlineType === DeadlineTypeEnum.Required) ? "Hard" :
                (initialFormValues?.deadlineType ?? "")
            ).toLocaleLowerCase()
          ),
          value: initialFormValues?.deadlineType
        },
      },
    });

  const reportsFieldArray = useFieldArray({
    control,
    name: "reports",
  });

  const [updateApplication, { loading: updateApplicationLoading }] =
    useUpdateApplicationMutation({
      update(cache, data) {
        const organization =
          data.data?.updateApplication?.grant.funder.organization;
        if (!organization) return;

        cache.evict({ id: cache.identify(organization) });
        cache.evict({ fieldName: "listApplications" });
        cache.evict({ fieldName: "getFunder" });
        cache.gc();
      },
    });

  const onSubmit = useCallback(
    (values: GrantUpdateSchema) => {
      if (!grantId || !values) {
        return;
      }

      let declinedDetails: UpdateApplicationParams["declinedDetails"];

      if (values?.declinedDate) {
        declinedDetails = {
          declinedReason: values?.declinedReason,
          declinedDate: formatDate(values?.declinedDate, yearMonthDay),
        };
      }

      const params: UpdateApplicationParams = {
        submission: {
          amountRequested: getFloat(values.amountRequested)?.toString(),
          submissionDate: formatDate(values.dateSubmitted, yearMonthDay),
        },
        declined: values?.status === ApplicationStatusEnum.Declined,
        deadline: formatDate(values?.deadline, yearMonthDay),
        declinedDetails,
        reports:
          values.reports?.map(({ urgency, deadline }) => ({
            urgency,
            deadline: formatDate(deadline, yearMonthDay),
          })) || [],
        ineligibleReason: values?.ineligibleReason ?? "",
        programId: values.program.value ?? 0,
        deadlineType: values?.deadlineType.value ?? (initialFormValues?.deadlineType)
      };



      if (values?.amountAwarded) {
        params.award = {
          amountAwarded: getFloat(values?.amountAwarded)?.toString(),
          receivementDate:
            formatDate(values?.receivementDate, yearMonthDay) || undefined,
        };
      }

      if (values?.status === ApplicationStatusEnum.Pending) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        params.award = null as any;
      }

      if (values?.isSubmitted === IsSubmittedEnum.No) {
        // when the form Application Submitted is set to No

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        params.award = null as any;
        params.deadline = formatDate(values?.deadline, yearMonthDay);
        params.declined = undefined;
        params.declinedDetails = undefined;
        params.reports = undefined;
        params.submission = undefined;
        // the message is to inform the backend that is updating is setting the application to not submitted
        // at the backend, the ineligibleReason will be set to an empty string, here its acting like a flag
        params.ineligibleReason = "updated_:with:_is_submitted:_no";
      } else if (values?.isSubmitted === IsSubmittedEnum.Ineligible) {
        // when the form Application Submitted is set to Ineligible

        params.deadline = formatDate(values?.deadline, yearMonthDay);
        params.ineligibleReason = values?.ineligibleReason ?? "";

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        params.award = null as any;
        params.declined = undefined;
        params.declinedDetails = undefined;
        params.reports = undefined;
        params.submission = undefined;

      } else if (values?.isSubmitted === IsSubmittedEnum.Yes) {
        // when the form Application Submitted is set to Yes

        params.deadline = formatDate(values?.deadline, yearMonthDay);
        params.submission = {
          submissionDate: formatDate(values.dateSubmitted, yearMonthDay),
          amountRequested: getFloat(values.amountRequested)?.toString(),
        };
        //current status
        if (values?.status == ApplicationStatusEnum.Awarded) {
          params.award = {
            receivementDate:
              formatDate(values?.receivementDate, yearMonthDay) || undefined,
            amountAwarded: getFloat(values?.amountAwarded)?.toString(),
          };
          params.reports = values.reports?.map(({ urgency, deadline }) => ({
            urgency,
            deadline: formatDate(deadline, yearMonthDay),
          })) || [];
          params.declined = undefined;
          params.declinedDetails = undefined;
          // the message is to inform the backend that is updating is setting the application to not submitted
          // at the backend, the ineligibleReason will be set to an empty string, here its acting like a flag
          params.ineligibleReason = "updated_:with:_is_submitted:_yes:_awarded";
        } else if (values?.status == ApplicationStatusEnum.Declined) {
          params.declined = true;
          params.declinedDetails = {
            declinedDate: formatDate(values?.declinedDate, yearMonthDay),
            declinedReason: values?.declinedReason
          };
          params.award = undefined;
          params.reports = [];
          params.ineligibleReason = "";
          // the message is to inform the backend that is updating is setting the application to not submitted
          // at the backend, the ineligibleReason will be set to an empty string, here its acting like a flag
          params.ineligibleReason = "updated_:with:_is_submitted:_yes:_declined";
        } else if (values?.status == ApplicationStatusEnum.Pending) {
          params.declined = undefined;
          params.declinedDetails = undefined;
          params.award = undefined;
          params.reports = [];
          params.ineligibleReason = "updated_:with:_is_submitted:_yes:_pending";
        }
      }

      // console.log("THere we go ", params);
      updateApplication({
        variables: {
          id: grantId,
          params,
        },
      })
        .then(() => {

          addToast({
            title: t("actions.application_has_been_updated"),
            type: "success",
          });

          hideModal(true);
        })
        .catch((error) =>
          addToast({
            title: capitalizeStringFirstLetter(error.message),
            type: "error",
          }),
        );
    },
    [t, grantId, addToast, hideModal, updateApplication],
  );

  // This was commented out as the submission has three possible values
  // 'Yes', 'No', and 'Ineligible' so a boolean was not sufficient
  // const isSubmitted = watch("isSubmitted") === IsSubmittedEnum.Yes;
  const isSubmitted = watch("isSubmitted");
  const isAwarded = watch("status") === ApplicationStatusEnum.Awarded;
  const isDeclined = watch("status") === ApplicationStatusEnum.Declined;
  const shouldShowDeadlineField =
    initialFormValues?.isSubmittedAsIneligible == false;
  const isIneligible = initialFormValues?.isSubmittedAsIneligible;

  const [showChangeProgram, setShowChangeProgram] = React.useState(false)
  const [showChangeDeadlineTypes, setShowChangeDeadlineTypes] = React.useState(false)

  return (
    <>
      <ModalBody as="form" width="full">
        <Flex justifyContent="end" alignItems={"center"} paddingY={"10px"}>

          <Button
            onClick={onClickDeletGrantBtn}
            backgroundColor={"transparent"}
            color="primary.100"
            _hover={{ color: "primary.80" }}
            _active={{ color: "primary.100" }}
            _focus={{ color: "primary.100" }}
            isLoading={updateApplicationLoading}
            border={"2px"}
            borderRadius={"full"}
          >
            {t("modals.update_grant.delete_grant")}
          </Button>
        </Flex>

        <Flex flexDirection="row" justifyContent="start" paddingY="5" >
          <Checkbox
            name="change_program"
            isChecked={showChangeProgram}
            onChange={(e) => setShowChangeProgram(e.target.checked)}

          >
            {t("modals.update_grant.change_program")}
          </Checkbox>

          <Checkbox
            name="change_deadline_type"
            isChecked={showChangeDeadlineTypes}
            onChange={(e) => setShowChangeDeadlineTypes(e.target.checked)}

          >
            Change Deadline Type
          </Checkbox>
        </Flex>

        {showChangeProgram &&
          <Flex width="full" flexDirection="column" paddingTop={"5px"} paddingBottom={"15px"}>
            <Controller
              as={Picklist}
              control={control}
              name="program"
              error={getErrorMessage("program", errors)}
              label={t("create_grant.steps.funder.program_being_funded")}
              placeholder={t("create_grant.steps.funder.select_a_program")}
              labelAlignment="left"
            >
              {listPrograms?.map((p) => (
                <Option
                  name={p.id}
                  value={p.id}
                  label={p.name}
                  key={p.id}
                />
              ))}
            </Controller>
          </Flex>
        }

        {showChangeDeadlineTypes &&
          <Flex width="full" flexDirection="column" paddingTop={"5px"} paddingBottom={"15px"}>
            <Controller
              as={Picklist}
              control={control}
              name="deadlineType"
              error={getErrorMessage("deadlineType", errors)}
              label={'Urgency'}
              placeholder={'Select urgency'}
              labelAlignment="left"
            >
              <Option name={"Hard"} value="REQUIRED" label="Hard" />
              <Option name={"Rolling"} value="ROLLING" label="Rolling" />
            </Controller>
          </Flex>
        }

        <EditGrantFormFields
          control={control}
          register={register}
          errors={errors}
          isSubmitted={isSubmitted}
          isAwarded={isAwarded}
          isDeclined={isDeclined}
          shouldShowDeadlineField={shouldShowDeadlineField}
          reportsFieldArray={reportsFieldArray}
          isIneligible={isIneligible}
          listPrograms={listPrograms}
        />
      </ModalBody>

      <ModalFooter width="full">
        <Button
          onClick={handleSubmit(onSubmit)}
          width="full"
          backgroundColor="secondary.200"
          isLoading={updateApplicationLoading}
        >
          {buttonLabel}
        </Button>
      </ModalFooter>
    </>
  );
};

export default EditGrantForm;

import { Funder } from "generated/graphql/v2/graphql";
import React from "react";
import { Link } from "react-router-dom";
import { pages } from "routes/pages";

import DefaultCell from "./DefaultCell";
import { FunderCellProps } from "./types";

const FunderCell: React.FC<FunderCellProps> = ({ value, row }) => {
  //The row is an object, we need to pick which column contains the value
  //The value should not be null
  let url = null;
  if(value && row && row.funderId){
    url = pages.funderProfile.pathWithParams(parseInt(row.funderId));
  }else if (value && row) {
    for (const key in row) {
      if (Object.prototype.hasOwnProperty.call(row, key) && row[key]) {
        const cellObject = row[key] as unknown as Funder;
        if (
          typeof cellObject === "object" &&
          cellObject.id &&
          cellObject.id > 0 &&
          cellObject.name === value
        ) {
          url = pages.funderProfile.pathWithParams(cellObject.id);
        }
      }
    }
  }
  return (
    <DefaultCell value={value ?? "-"} color="primary.100">
      {url && (
        <Link
          to={url}
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            backgroundColor: "transparent",
          }}
        />
      )}
    </DefaultCell>
  );
};

export default FunderCell;

import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ButtonMenu, MenuItem } from "react-rainbow-components";

import useSession from "hooks/useSession";
import VerticalEllipsisIcon from "settings/theme/ChakraTheme/icons/VerticalEllipsisIcon";

/**
 * TODO: Add HeaderMenu options and general logic
 */
const HeaderMenu: React.FC = () => {
  const [t] = useTranslation();

  const [, endSession] = useSession();


  return (
    <ButtonMenu
      buttonVariant="base"
      icon={<VerticalEllipsisIcon />}
      menuSize="small"
      buttonSize="large"
      menuAlignment="right"
      assistiveText={t("header.user_menu")}
    >
      <MenuItem label={t("buttons.logout")} onClick={endSession} />
    </ButtonMenu>
  );
};

export default HeaderMenu;

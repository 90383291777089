import { Flex } from "@chakra-ui/react";
import React from "react";
import { Spinner } from "react-rainbow-components";

import { LoadingProps } from "./types";

const Loading: React.FC<LoadingProps> = ({ ...props }) => (
  <Flex position="relative" width="full" height="sm" {...props}>
    <Spinner />
  </Flex>
);

export default Loading;

import { Button } from "components/_new/theme/Button";
import { Cell, PaginatedTable } from "components/_new/theme/Table";
import { FragmentType, graphql, useFragment } from "generated/graphql/v2";
import React from "react";
import { useTranslation } from "react-i18next";
import formatCurrency from "utils/formatCurrency";
import { useEditGoalModal } from "modals/EditGoal";

// yay, magic global variables and the configuration option that is made to avoid them
// not being available in the preset
const ListProgramsFragment = graphql(`
  fragment ListProgramsFragment on Program {
    id
    name
    yearlyGoal(fiscalYear: $fy)
    achievedAmount(fiscalYear: $fy)
    ...EditProgramGoalFragment
  }
`);

export const ListPrograms: React.FC<{
  programs: FragmentType<typeof ListProgramsFragment>[];
  year: number;
}> = (props) => {
  const programs = useFragment(ListProgramsFragment, props.programs);
  const [t] = useTranslation();
  const openEditModal = useEditGoalModal();

  return (
    <PaginatedTable
      pageSize={10}
      data={programs}
      headers={[
        t("programs.name"),
        t("programs.yearlyGoal"),
        t("programs.achievedAmount"),
        "",
      ]}
      rowKey={"id"}
      rowMapper={(program) => (
        <>
          <Cell>{program.name}</Cell>
          <Cell>{formatCurrency(program.yearlyGoal)}</Cell>
          <Cell>{formatCurrency(program.achievedAmount ?? 0)}</Cell>
          <Cell>
            <Button
              backgroundColor="secondary.200"
              onClick={() => openEditModal(program, props.year)}
            >
              {t("programs.edit_year_goal")}
            </Button>
          </Cell>
        </>
      )}
    />
  );
};

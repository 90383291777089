import { useMutation } from "@apollo/client";
import { Stack } from "@chakra-ui/react";
import Progress from "components/Progress";
import { Button } from "components/_new/theme/Button";
import { useToast } from "contexts/toast";
import { graphql } from "generated/graphql/v2";
import React, { RefObject, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FileSelector, Input } from "react-rainbow-components";

const joinPath = (a: string, b: string) => {
  if (a.endsWith("/") || !b) {
    return a + b;
  } else {
    return a + "/" + b;
  }
};

const UploadFileMutation = graphql(`
  mutation UploadDocumentMutation(
    $funderId: Int!
    $file: Upload!
    $folder: String!
  ) {
    uploadFile(params: { funderId: $funderId, file: $file, folder: $folder }) {
      id
    }
  }
`);

export default (props: {
  hideModal: () => void;
  componentProps: {
    type: "file" | "folder";
    funderId: number;
    inFolder: string;
  };
}) => {
  const [t] = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<{ folder: string; files: FileList }>({
    defaultValues: { folder: props.componentProps.inFolder },
  });
  const e = useToast();

  const [isUploading, setIsUploading] = React.useState(false);
  const [currentUpload, setCurrentUpload] = React.useState("");
  const [totalUploads, setTotalUploads] = React.useState(0);
  const [successfulUploads, setSuccessfulUploads] = React.useState(0);

  const { type, funderId } = props.componentProps;
  const [upload] = useMutation(UploadFileMutation, {
    refetchQueries: ["getFunderFolder"],
  });

  // hack to force webkitdirectory support into the component library
  const fileSelectorRef = useRef<HTMLFormElement>(null);
  useEffect(() => {
    if (type === "folder" && fileSelectorRef.current?.files) {
      fileSelectorRef.current.files.webkitdirectory = true;
    }
  }, [type]);

  return (
    <Stack
      as="form"
      marginBottom={8}
      padding={4}
      spacing={6}
      ref={
        fileSelectorRef as RefObject<HTMLDivElement> &
          ((instance: HTMLFormElement | null) => void) // why are the types like this?
      }
      onSubmit={handleSubmit(async ({ files, folder }) => {
        setTotalUploads(files.length);
        setIsUploading(true);

        try {
          for (const file of Array.from(files)) {
            console.log(file);
            debugger;
            setCurrentUpload(file.name);
            await upload({
              variables: {
                funderId: funderId,
                file,
                folder: joinPath(
                  folder,
                  file.webkitRelativePath.match(/.*(?=\/)/)?.[0] ?? "",
                ),
              },
            });
            e.addToast({
              type: "success",
              title: `${file.name} uploaded successfully`,
            });
            setSuccessfulUploads((s) => s + 1);
          }
        } catch {
          e.addToast({
            type: "error",
            title: "Some of the uploads were not successful",
          });
        }
        props.hideModal();
        setIsUploading(false);
      })}
    >
      <Controller
        as={FileSelector}
        control={control}
        name="files"
        label={t("file.name")}
        multiple={type === "file"}
        error={errors["files"]}
        labelAlignment="left"
      />
      <Controller
        as={Input}
        control={control}
        name="folder"
        label={t("file.folder")}
        error={errors["folder"]}
        labelAlignment="left"
      />
      {isUploading && (
        <>
          <p>Uploading {currentUpload}...</p>
          <Progress value={(successfulUploads / totalUploads) * 100} />
        </>
      )}
      <Button type="submit" background="primary.100" isLoading={isSubmitting}>
        Upload
      </Button>
    </Stack>
  );
};

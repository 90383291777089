import { useToast } from "contexts/toast";
import { useTranslation } from "react-i18next";
import capitalizeStringFirstLetter from "utils/capitalizeStringFirstLetter";

const defaultErrorMapper = (error: unknown, t: (tkey: string) => string) => {
  if (typeof error === "string") return capitalizeStringFirstLetter(error);
  if (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    typeof error.message === "string"
  ) {
    return capitalizeStringFirstLetter(error.message);
  }
  return t("errors.unexpected");
};

export const useToastWrapper = (
  successMessage: string,
  errorMapper: (
    error: unknown,
    t: (tkey: string) => string,
  ) => string = defaultErrorMapper,
) => {
  const { addToast } = useToast();
  const [t] = useTranslation();

  return <T>(pf: () => Promise<T>) =>
    pf()
      .then(() => {
        addToast({
          title: successMessage,
          type: "success",
        });
      })
      .catch((error) => {
        addToast({
          title: errorMapper(error, t),
          type: "error",
        });
      });
};

const isBrowser = typeof window !== "undefined";

export const shimHistory = () => {
  const patchHistoryMethod = (method: "pushState" | "replaceState") => {
    const history = window.history;
    const original = history[method];

    history[method] = function (state, ...rest) {
      const result = original.apply(history, [state, ...rest]);

      const event: Event & { state?: string } = new Event(method.toLowerCase());
      event.state = state;

      window.dispatchEvent(event);

      return result;
    };
  };

  if (isBrowser) {
    patchHistoryMethod("pushState");
    patchHistoryMethod("replaceState");
  }
};

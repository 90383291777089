import { ReportUrgencyEnum } from "generated/graphql";
import i18n from "translations/i18n";

import { Status } from "./statuses";

export const urgencies: Record<ReportUrgencyEnum, Status<ReportUrgencyEnum>> = {
  [ReportUrgencyEnum.Courtesy]: {
    label: i18n.t("urgencies.courtesy"),
    value: ReportUrgencyEnum.Courtesy,
  },
  [ReportUrgencyEnum.Required]: {
    label: i18n.t("urgencies.required"),
    value: ReportUrgencyEnum.Required,
  },
};

export const urgenciesSelectOptions = (
  Object.keys(urgencies) as ReportUrgencyEnum[]
).map(
  (key): Status<ReportUrgencyEnum> => ({
    label: urgencies[key].label,
    value: key,
  }),
);

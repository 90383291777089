import React, { useCallback } from "react";
import { Flex } from "@chakra-ui/react";
import { Picklist, Option } from "react-rainbow-components";

import { SelectedValueProps, StatusFilterProps } from "./types";

const StatusFilter: React.FC<StatusFilterProps> = ({
  isDisabled,
  tableStatuses,
  statusOptions,
  setStatus,
  currentStatus,
  ...props
}) => {
  const handleStatusChange = useCallback(
    (selectedStatus: SelectedValueProps) => {
      setStatus(selectedStatus.name as string);
    },
    [setStatus],
  );

  return (
    <Flex {...props}>
      <Picklist
        disabled={isDisabled}
        onChange={handleStatusChange}
        value={tableStatuses[currentStatus]}
      >
        {statusOptions.map((statusOption) => (
          <Option
            name={statusOption.value}
            value={statusOption.value}
            label={statusOption.label}
            key={statusOption.label}
          />
        ))}
      </Picklist>
    </Flex>
  );
};

export default StatusFilter;

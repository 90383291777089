import { CheckboxToggleProps } from "react-rainbow-components/components/CheckboxToggle";
import React from "react";
import { CheckboxToggle } from "react-rainbow-components";

export const ToggleInput: React.FC<
  CheckboxToggleProps & {
    value: boolean;
    onChange: (value: boolean) => void;
  }
> = (props) => (
  <CheckboxToggle
    labelAlignment="left"
    {...props}
    value={props.value}
    onChange={() => props.onChange(!props.value)}
  />
);

import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Text } from "@chakra-ui/react";
import { isThisMonth, parseISO } from "date-fns";

import TableCellContainer from "components/Table/TableCell";
import Button from "components/Button";

import { DefaultCellProps, FunderCellProps } from "./types";
import DefaultCell from "./DefaultCell";
import { pages } from "routes/pages";

export const GrantCell: React.FC<DefaultCellProps> = ({
  value,
  children,
  ...props
}) => {
  const isCurrentMonth =
    props.row?.lastAward && isThisMonth(parseISO(props.row.lastAward));
  return (
    <TableCellContainer>
      <Text
        textAlign="center"
        wordBreak="break-word"
        whiteSpace="normal"
        lineHeight="none"
        fontWeight={isCurrentMonth ? "bold" : "normal"}
        {...props}
      >
        {value || "-"}
      </Text>

      {children}
    </TableCellContainer>
  );
};

export const FunderCell: React.FC<FunderCellProps> = ({ value, row }) => {
  const history = useHistory();

  const handleClick = useCallback(
    (funderId) => () => {
      history.push(pages.funderProfile.pathWithParams(funderId));
    },
    [history],
  );

  const isCurrentMonth = row?.lastAward && isThisMonth(parseISO(row.lastAward));

  return (
    <DefaultCell
      value={value}
      color="primary.100"
      fontWeight={isCurrentMonth ? "bold" : "normal"}
    >
      <Button
        width="full"
        height="90%"
        position="absolute"
        backgroundColor="transparent"
        padding={0}
        onClick={handleClick(row?.funderId)}
      />
    </DefaultCell>
  );
};

import i18n from "translations/i18n";

export enum PeriodsEnum {
  All = "ALL",
  Year = "YEAR",
}

interface Period {
  label: string;
  value: string;
}

const periods: Record<PeriodsEnum, Period> = {
  [PeriodsEnum.All]: {
    label: i18n.t("periods.all"),
    value: PeriodsEnum.All,
  },
  [PeriodsEnum.Year]: {
    label: i18n.t("periods.year"),
    value: PeriodsEnum.Year,
  },
};

export interface PeriodOptionProps {
  value: string;
  label: string;
}

/**
 * Defines possible select options for submission periods.
 */
export const periodsSelectOptions: PeriodOptionProps[] = (
  Object.keys(periods) as PeriodsEnum[]
).filter((k) => k === PeriodsEnum.Year).map((key) => ({
  value: key,
  label: periods[key].label as string,
}));

import { Card, CardTitle } from "components/_new/theme/Card";
import { FragmentType, graphql, useFragment } from "generated/graphql/v2";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Flex, Text } from "@chakra-ui/react";

import { Button } from "components/_new/theme/Button";
import { Cell, PaginatedTable } from "components/_new/theme/Table";
import { formatDate } from "utils/dateFormats";
import getFiscalYear from "utils/getFiscalYear";
import formatCurrency from "utils/formatCurrency";
import CreateGrantForm from "components/CreateGrantForm";
import { useModal } from "contexts/modal";
import UpdateButton from "components/_new/objectViews/Grants/UpdateButton";
import {
  Application,
  ApplicationStatusEnum,
} from "generated/graphql/v2/graphql";
import { Select } from "components/_new/theme/Select";
import { pages } from "routes/pages";
import { getStatusFromGrantApplication } from "utils/getStatus";

const FunderPreviousGrantsFragment = graphql(`
  fragment FunderPreviousGrantsFragment on Funder {
    id
    name
    grants {
      program {
        id
        name
      }
      applications {
        id
        deadline
        award {
          amountAwarded
          receivementDate
          responseDays
        }
        declinedDetails {
          declinedDate
        }
        submission {
          submissionDate
          amountRequested
        }
        status
      }
    }
    totalAwardAmount
  }
`);

const StatusOptions = [
  "AWARDED",
  "DECLINED",
  "DID_NOT_RESPOND",
  "INELIGIBLE",
  "PENDING",
] as ApplicationStatusEnum[];

export const FunderPreviousGrantsTable: React.FC<{
  funder: FragmentType<typeof FunderPreviousGrantsFragment>;
  fyStart: Date;
}> = (props) => {
  const [t] = useTranslation();
  const funder = useFragment(FunderPreviousGrantsFragment, props.funder);

  const [showModal] = useModal();
  const [status, setStatus] = useState<ApplicationStatusEnum | undefined>();

  const openCreateGrantModal = useCallback(() => {
    showModal({
      title: t("create_grant.title"),
      component: CreateGrantForm,
      componentProps: {
        redirectPath: pages.grantFlow.pathWithParams({
          grantStatus: "NOT_APPLY",
        }),
        selectedFunder: funder,
      },
      closeOnOverlayClick: false,
    });
  }, [t, showModal, funder]);

  // HUGE TO-DO: do this server-side, where it belongs
  const grants = funder.grants ?? [];
  const applications =
    (grants
      .reduce?.(
        (a, g) => [
          ...a,
          ...(g.applications ?? []).map((d) => ({ ...d, grant: g })),
        ],
        [] as NonNullable<
          (NonNullable<(typeof grants)[0]["applications"]>[0] & {
            grant: (typeof grants)[0];
          })[]
        >,
      )
      ?.filter((a) => {
        return a.submission?.submissionDate || a.status === "INELIGIBLE" ;
      })
      ?.filter((a) => {
        if (!status) {
          return true;
        }
        // if did not respond || declined is choosen in the dropdown
        if (
          (status as string) === "DID_NOT_RESPOND" &&
          a.submission &&
          !a.award &&
          !a.declinedDetails
        ) {
          //check if the submission was greater than 365 days
          const submissionDate = new Date(a.submission.submissionDate);
          const diffTime = Math.abs(
            new Date().getTime() - submissionDate.getTime(),
          );
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          return diffDays > 365;
        }
        if (status === "DECLINED" && !a.declinedDetails) {
          // if there are no declined details, this application is not yet declined
          return false;
        }
        return a.status === status;
      })
      ?.sort(
        (a, b) =>
          new Date(a.submission?.submissionDate ?? "").getTime() -
          new Date(b.submission?.submissionDate ?? "").getTime(),
      ) as Application[]) ?? [];

  const getResponseDays = (application: Application) => {
    const status = getStatus(application);
    if (status === t(`statuses.declined`) && application.declinedDetails) {
      const declineDate = new Date(application.declinedDetails.declinedDate);
      if (application.submission) {
        const submissionDate = new Date(application.submission.submissionDate);
        const diffTime = Math.abs(
          declineDate.getTime() - submissionDate.getTime(),
        );
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
      }
    } else if (status === t(`statuses.did_not_respond`)) {
      return "365";
    } else if (application.award) {
      return application.award.responseDays;
    }
    return "-";
  };

  const getAmount = (application: Application) => {
    if (application.award) {
      return formatCurrency(application.award.amountAwarded);
    }

    if (application.submission) {
      return formatCurrency(application.submission.amountRequested);
    }

    return "-";
  };

  const getStatus = (application: Application) => {
    const status = getStatusFromGrantApplication(application);
    return t(`statuses.${status}`);
  };

  const getFiscalYearBasedOnStatus = (
    application: Application,
    organizationFyStart: Date,
  ) => {
    //see: https://github.com/Grantflow2/grantflow/issues/47#issuecomment-1918002193
    const status = getStatus(application);
    switch (status) {
      case t(`statuses.pending`):
        return getFiscalYear(
          new Date(application.deadline),
          organizationFyStart,
        );
      case t(`statuses.submitted`):
        return getFiscalYear(
          new Date(
            application.submission?.submissionDate ?? application.deadline,
          ),
          organizationFyStart,
        );
      case t(`statuses.not_submitted`):
        return getFiscalYear(
          new Date(application.deadline),
          organizationFyStart,
        );
      case t(`statuses.ineligible`):
        return getFiscalYear(
          new Date(application.deadline),
          organizationFyStart,
        );
      case t(`statuses.did_not_respond`):
        return getFiscalYear(
          new Date(application.deadline),
          organizationFyStart,
        );
      case t(`statuses.declined`):
        return getFiscalYear(
          new Date(
            application.declinedDetails?.declinedDate ?? application.deadline,
          ),
          organizationFyStart,
        );
      case t(`statuses.awarded`):
        return getFiscalYear(
          new Date(application.award?.receivementDate ?? application.deadline),
          organizationFyStart,
        );
      default:
        return getFiscalYear(
          new Date(application.deadline),
          organizationFyStart,
        );
    }
  };

  return (
    <Card
      header={
        <>
          <CardTitle>{`Previous Grants`}</CardTitle>
          <Flex direction="row" gap={4} alignItems="center">
            <Select
              options={StatusOptions.map((value) => ({
                label: t(`statuses.${value.toLowerCase()}`),
                value,
              }))}
              onSelect={setStatus}
              selected={status}
              placeholder={t("statuses.all_grants")}
            />
            <Button background="primary.100" onClick={openCreateGrantModal}>
              {t("buttons.add_new_grant")}
            </Button>
          </Flex>
        </>
      }
    >
      <PaginatedTable
        headers={[
          t("tables.columns_titles.year"),
          t("tables.columns_titles.program"),
          t("tables.columns_titles.previous_awards"),
          t("tables.columns_titles.date_submitted"),
          t("tables.columns_titles.date_awarded"),
          t("tables.columns_titles.response_days"),
          t("tables.columns_titles.status"),
          "",
        ]}
        rowKey="id"
        data={applications.reverse()}
        rowMapper={(application) => (
          <>
            <Cell>
              {getFiscalYearBasedOnStatus(application, props.fyStart)}
            </Cell>
            <Cell>{application.grant.program.name}</Cell>
            <Cell>{getAmount(application)}</Cell>
            <Cell>{formatDate(application.submission?.submissionDate)}</Cell>
            <Cell>
              {formatDate(
                application.award?.receivementDate ??
                  application.declinedDetails?.declinedDate,
              )}
            </Cell>
            <Cell>{getResponseDays(application)}</Cell>
            <Cell>{getStatus(application)}</Cell>
            <Cell>
              <UpdateButton
                id={application.id}
                type="grant"
                label={t("actions.update_information")}
                color="blue.500"
                program={application.grant.program}
              />
            </Cell>
          </>
        )}
      />
      <Flex direction="row" gap={2} color="primary.200">
        <Text fontSize="medium" fontWeight="semibold">
          {t("previous_awards_card.total_award")}
          {": "}
        </Text>
        <Text fontSize="medium">
          {formatCurrency(funder.totalAwardAmount ?? 0)}
        </Text>
      </Flex>
    </Card>
  );
};

import React from "react";
import { useTransition } from "react-spring";

import { ToastContainerProps } from "./types";
import Toast from "./Toast";
import toastAnimation from "./styles";

/**
 * Wrap all toasts and inject animations
 */
const ToastsContainer: React.FC<ToastContainerProps> = ({ toasts }) => {
  const messagesWithTransitions = useTransition(
    toasts,
    (toast) => toast.id,
    toastAnimation,
  );

  return (
    <div className="toasts-wrapper">
      {messagesWithTransitions.map(({ item, key, props }) => (
        <Toast key={key} toast={item} style={props} className="toast" />
      ))}
    </div>
  );
};

export default ToastsContainer;

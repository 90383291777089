import { Cell } from "components/_new/theme/Table";
import { Button } from "components/_new/theme/Button";
import { FragmentType, graphql, useFragment } from "generated/graphql/v2";
import React, { MouseEventHandler } from "react";
import { Text } from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import useDelete from "hooks/useDelete";
import { useTranslation } from "react-i18next";
import { Path, urlToPath } from "..";
import { Link } from "react-router-dom";

const FolderRowFragment = graphql(`
  fragment FolderRowFragment on Folder {
    name
  }
`);

const deleteFolderMutation = graphql(`
  mutation deleteFolder($folder: String!, $funderId: Int!) {
    deleteFolder(folder: $folder, funderId: $funderId) {
      id
    }
  }
`);

export const FolderRow: React.FC<{
  folder: FragmentType<typeof FolderRowFragment>;
  funderId: number;
  setPath: (path: Path) => void;
}> = (props) => {
  const [t] = useTranslation();
  const folderData = useFragment(FolderRowFragment, props.folder);
  const folderPath = urlToPath(folderData.name);

  const { funderId, setPath } = props;

  const [deleteFolder] = useMutation(deleteFolderMutation, {
    refetchQueries: ["getFunderUploads"],
  });
  const deleteFolderCb = useDelete({
    mutate: deleteFolder,
    entryType: "folder",
    modalTitle: t("modals.delete_folder.title"),
  });

  const folderDisplayName = folderPath[folderPath.length - 1];

  const navigateToFolder: MouseEventHandler = (e) => {
    e.preventDefault();
    setPath(folderPath);
  };

  return (
    <>
      <Cell>
        <Link to="#" onClick={navigateToFolder}>
          <Text padding={2}>
            {folderDisplayName === ".."
              ? t("funder_documents.up_one_folder")
              : folderDisplayName}
          </Text>
        </Link>
      </Cell>
      <Cell></Cell>
      <Cell></Cell>
      <Cell>
        {!(folderData.name === "/" || folderDisplayName === "..") && (
          <Button
            onClick={() =>
              deleteFolderCb(folderData.name, {
                variables: {
                  folder: folderData.name,
                  funderId,
                },
              })
            }
            background="primary.100"
          >
            Delete
          </Button>
        )}
      </Cell>
    </>
  );
};

import React, { useCallback } from "react";
import { Stack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import BoardReportModal from "components/Modal/BoardReportModal";
import { useModal } from "contexts/modal";
import Button from "components/Button";
import TableHeader from "components/Table/TableHeader";
import CreateGrantForm from "components/CreateGrantForm";

import { pages } from "routes/pages";

const DashboardHeader = (props: {
  SelectYear: React.ReactElement;
  dataReady: boolean;
}) => {
  const [showModal] = useModal();
  const [t] = useTranslation();

  const openBoardReport = useCallback(() => {
    showModal({
      title: t("board_report.title"),
      component: BoardReportModal,
    });
  }, [showModal, t]);

  const openCreateGrantModal = useCallback(() => {
    showModal({
      title: t("create_grant.title"),
      component: CreateGrantForm,
      componentProps: {
        redirectPath: pages.grantFlow.pathWithParams({
          grantStatus: "NOT_APPLY",
        }),
      },
      closeOnOverlayClick: false,
    });
  }, [t, showModal]);

  return (
    <TableHeader
      title={t("dashboard.title")}
      pt={8}
      px={8}
      mb={0}
      className="hide-on-print"
    >
      <Stack spacing={6} direction={{ base: "column", sm: "row" }}>

      {props.SelectYear}
        
        <Button
          background="#4949FF"
          onClick={openCreateGrantModal}
          fontSize={{ base: "xxs", md: "xs", lg: "xs" }}
        >
          {t("buttons.add_new_grant")}
        </Button>

        <Button
          background="primary.100"
          fontSize={{ base: "xxs", md: "xs", lg: "xs" }}
          onClick={openBoardReport}
          disabled={!props.dataReady}
        >
          {t("board_report.title")}
        </Button>
      </Stack>
    </TableHeader>
  );
};

export default DashboardHeader;

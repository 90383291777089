import React from "react";
import {
  Checkbox as ChakraCheckbox,
  FormControl,
  Text,
} from "@chakra-ui/react";
import ConditionalWrap from "conditional-wrap";

import FieldErrorMessage from "components/FormComponents/FieldErrorMessage";
import getErrorMessage from "utils/getErrorMessage";
import theme from "settings/theme/ChakraTheme";

import { CheckboxProps } from "./types";

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      renderChildrenAsText = true,
      showErrorMessage = true,
      colorScheme = "primary",
      fontSize = "xxs",
      color = "black",
      isReadOnly,
      children,
      errors,
      name,
      ...rest
    },
    ref,
  ) => {
    const error = getErrorMessage(name, errors);

    return (
      <FormControl>
        <ChakraCheckbox
          {...rest}
          colorScheme={colorScheme}
          isReadOnly={isReadOnly}
          isInvalid={!!error}
          name={name}
          ref={ref}
          css={{
            ".chakra-checkbox__control": {
              borderColor: theme.colors.gray[400],
            },
          }}
        >
          <ConditionalWrap
            condition={renderChildrenAsText}
            wrap={(content) => (
              <Text fontSize={fontSize} color={color}>
                {content}
              </Text>
            )}
          >
            <>{children}</>
          </ConditionalWrap>
        </ChakraCheckbox>

        {showErrorMessage && <FieldErrorMessage error={error} />}
      </FormControl>
    );
  },
);

export default Checkbox;

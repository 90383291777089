import { extendTheme } from "@chakra-ui/react";

import fontSizes from "./fontSizes";
import colors from "./colors";
import fonts from "./fonts";
import sizes from "./sizes";
import components from "./components";

export default extendTheme({
  fontSizes,
  colors,
  fonts,
  sizes,
  components,
});

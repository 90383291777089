import * as React from "react";
import { IconProps } from "@chakra-ui/icons";
import { Icon } from "@chakra-ui/react";

const MoneyBagIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} viewBox="0 0 512 512">
    <path
      fill="#4CAF50"
      d="M306.347,131.264c-2.011-2.086-4.783-3.264-7.68-3.264h-85.333c-2.897,0-5.669,1.178-7.68,3.264
    C190.059,147.413,53.333,290.859,53.333,352c0,88.235,90.923,160,202.667,160s202.667-71.765,202.667-160
    C458.667,290.859,321.941,147.413,306.347,131.264z"
    />
    <path
      fill="#4CAF50"
      d="M392,14.272c-17.771-20.096-64,6.272-90.539,24.128C289.301,21.12,271.296,0,256,0
      c-15.296,0-33.301,21.12-45.461,38.4C184.064,20.523,137.749-5.845,120,14.272c-4.92,5.673-7.241,13.146-6.4,20.608
      c3.435,39.509,83.861,104.811,93.013,112.107c1.905,1.529,4.277,2.357,6.72,2.347h85.333c2.418,0.002,4.765-0.818,6.656-2.325
      c9.152-7.317,89.6-72.533,93.013-112.107C399.209,27.441,396.91,19.956,392,14.272z"
    />
    <path
      fill="#388E3C"
      d="M341.333,149.333H170.667c-5.891,0-10.667-4.776-10.667-10.667S164.776,128,170.667,128h170.667
    c5.891,0,10.667,4.776,10.667,10.667S347.224,149.333,341.333,149.333z"
    />
    <path
      fill="#FAFAFA"
      d="M256,426.667c-25.365,2.505-48.469-14.703-53.333-39.723c-0.495-5.891,3.88-11.068,9.771-11.563
      c5.891-0.495,11.068,3.88,11.563,9.771c0.939,11.136,15.296,20.181,32,20.181c17.365,0,32-9.771,32-21.333
      c0-10.411-10.112-17.067-31.808-21.12l-2.325-0.427c-34.624-6.4-51.2-20.544-51.2-42.453c3.337-26.292,26.95-45.182,53.333-42.667
      c25.365-2.505,48.469,14.703,53.333,39.723c0.495,5.891-3.88,11.068-9.771,11.563c-5.891,0.495-11.068-3.88-11.563-9.771l0,0
      c-0.896-11.136-15.253-20.181-32-20.181c-17.365,0-32,9.771-32,21.333c0,10.411,10.133,17.067,31.851,21.12l2.347,0.448
      c34.56,6.4,51.2,20.523,51.2,42.453C306.049,410.332,282.4,429.22,256,426.667z"
    />
    <path
      fill="#FAFAFA"
      d="M256,448c-5.891,0-10.667-4.776-10.667-10.667V266.667c0-5.891,4.776-10.667,10.667-10.667
    c5.891,0,10.667,4.776,10.667,10.667v170.667C266.667,443.224,261.891,448,256,448z"
    />
  </Icon>
);

export default MoneyBagIcon;

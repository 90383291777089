import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";

import { TOKEN_KEY } from "constants/auth";
import { pages } from "routes/pages";

import { UseSessionPayload } from "./types";

/**
 * Hook that exposes session handling functions.
 */
const useSession = (): UseSessionPayload => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const history = useHistory();

  const currentToken = cookies[TOKEN_KEY];

  const createSession = useCallback(
    (token: string, redirectTo: string = pages.root.pathWithParams()) => {
      setCookie(TOKEN_KEY, token, {
        path: pages.root.pathWithParams(),
      });

      history.push(redirectTo);
    },
    [setCookie, history],
  );

  const endSession = useCallback(() => {
    removeCookie(TOKEN_KEY, {
      path: pages.root.pathWithParams(),
    });

    window.location.href = pages.root.pathWithParams();
  }, [removeCookie]);

  const payload = useMemo<UseSessionPayload>(
    () => [createSession, endSession, currentToken],
    [currentToken, createSession, endSession],
  );

  return payload;
};

export default useSession;

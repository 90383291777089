import { Text } from "@chakra-ui/react";
import React from "react";

import { HeaderExternalLinkProps } from "../types";

const HeaderExternalLink: React.FC<HeaderExternalLinkProps> = ({
  href,
  label,
}) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    <Text as="span">{label}</Text>
  </a>
);

export default HeaderExternalLink;

import * as React from "react";
import { IconProps } from "@chakra-ui/icons";
import { Icon } from "@chakra-ui/react";

const MailIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} viewBox="0 0 512.001 512.001">
    <path
      fill="#1E88E5"
      d="M499.628,407.468c-10.128,12.16-25.134,19.194-40.96,19.2H53.334
c-15.826-0.006-30.832-7.04-40.96-19.2l195.627-162.987l20.267,13.867c16.695,11.52,38.772,11.52,55.467,0l20.267-13.867
L499.628,407.468z"
    />
    <path
      fill="#64B5F6"
      d="M502.614,108.588L304.001,244.481l-20.267,13.867c-16.695,11.52-38.772,11.52-55.467,0
l-20.267-13.867L9.388,108.374c9.994-14.411,26.409-23.017,43.947-23.04h405.333C476.285,85.241,492.781,93.969,502.614,108.588z"
    />
    <g>
      <path
        fill="#2196F3"
        d="M208.001,244.481L12.374,407.468c-8.007-9.57-12.388-21.655-12.373-34.133V138.668
  c-0.065-10.823,3.214-21.403,9.387-30.293L208.001,244.481z"
      />
      <path
        fill="#2196F3"
        d="M512.001,138.668v234.667c0.014,12.478-4.366,24.563-12.373,34.133L304.001,244.481l198.613-135.893
  C508.756,117.414,512.033,127.915,512.001,138.668z"
      />
    </g>
  </Icon>
);

export default MailIcon;

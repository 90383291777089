import { useTranslation } from "react-i18next";

import DeleteModal from "components/Modal/DeleteModal";
import { useModal } from "contexts/modal";
import { useToast } from "contexts/toast";
import capitalizeStringFirstLetter from "utils/capitalizeStringFirstLetter";
import type {
  ApolloCache,
  MutationFunctionOptions,
  MutationTuple,
} from "@apollo/client";

const useDelete = <
  TD,
  TV,
  TC,
  TS,
  TCh extends ApolloCache<TS>,
  OV extends MutationFunctionOptions<TD, TV, TC, TCh>,
>(props: {
  entryType: string;
  modalTitle: string;
  mutate: MutationTuple<TD, TV, TC, TCh>[0];
  onSuccess?: () => void;
  onFinish?: () => void;
}): ((entryName: string, v: OV) => void) => {
  const [t] = useTranslation();
  const [showModal, hideModal] = useModal();
  const { addToast } = useToast();

  const handleDelete = (variables: OV) => () => {
    props
      .mutate(variables)
      .then(() => {
        addToast({
          title: t("actions.this_entry_has_been_deleted", {
            entryType: props.entryType,
          }),
          type: "success",
        });
        props.onSuccess?.();
      })
      .catch((error: Record<string, string>) => {
        addToast({
          title: capitalizeStringFirstLetter(error.message),
          type: "error",
        });
      })
      .finally(() => {
        hideModal();
        props.onFinish?.();
      });
  };

  return (entryName, variables) => {
    showModal({
      title: props.modalTitle,
      component: DeleteModal,
      componentProps: {
        entryName,
        onDelete: handleDelete(variables),
      },
    });
  };
};

export default useDelete;

import colors from "settings/theme/RainbowTheme/colors";

const theme = {
  rainbow: {
    palette: {
      ...colors,
    },
  },
};

export default theme;

import * as Sentry from "@sentry/browser";

/**
 * Applies configuration for Sentry.
 */
const configureSentry = (): void => {
  if (process?.env?.REACT_APP_ENV === "production") {
    Sentry.init({
      environment: process?.env?.REACT_APP_ENV,
      dsn: "https://d4cc48cabc7c46e1b136cf410bd31cc0@sentry.jungsoft.com.br/39",
    });

    Sentry.configureScope((scope) => {
      scope.setTag("app", "frontend");
    });
  }
};

export default configureSentry;

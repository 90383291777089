import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import updateNotesSchema, {
  UpdateNotesSchema,
} from "settings/yup/schemas/updateNotesSchama";

import CardTextArea from "../CardTextArea";
import { useToastWrapper } from "components/_new/hooks/useToastWrapper";
import { FragmentType, graphql, useFragment } from "generated/graphql/v2";
import { useMutation } from "@apollo/client";

const FunderProfileNotesFragment = graphql(`
  fragment FunderProfileNotesFragment on Funder {
    id
    notes
  }
`);

const UpdateFunderNotesMutation = graphql(`
  mutation FunderProfileNotesMutation($id: Int!, $notes: String!) {
    updateFunder(id: $id, params: { notes: $notes }) {
      id
      notes
    }
  }
`);

const NotesCard: React.FC<{
  funder: FragmentType<typeof FunderProfileNotesFragment>;
}> = (props) => {
  const [t] = useTranslation();
  const { id: funderId, notes = "" } = useFragment(
    FunderProfileNotesFragment,
    props.funder,
  );

  const { control, reset, handleSubmit, formState } =
    useForm<UpdateNotesSchema>({
      resolver: yupResolver(updateNotesSchema),
      mode: "onBlur",
    });

  useEffect(() => {
    if (notes) {
      reset({ notes });
    }
  }, [reset, notes]);

  const [updateNotes, { loading: isLoading }] = useMutation(
    UpdateFunderNotesMutation,
  );

  const toastWrapper = useToastWrapper(
    t("notes_card.funder_notes_have_been_updated"),
  );
  const onSubmit = useCallback(
    (values: UpdateNotesSchema) => {
      if (!funderId || !formState.isDirty) {
        return;
      }

      toastWrapper(() =>
        updateNotes({
          variables: {
            id: funderId,
            notes: values?.notes ?? "",
          },
        }),
      );
    },
    [toastWrapper, updateNotes, funderId, formState.isDirty],
  );

  return (
    <CardTextArea
      isLoading={isLoading}
      handleSubmit={handleSubmit(onSubmit)}
      value={notes ?? ""}
      title={`${t("notes_card.notes")}:`}
      control={control}
      name="notes"
      funderId={funderId}
    />
  );
};

export default NotesCard;

import React from "react";

import { EmojiWrapperProps } from "./types";

const EmojiWrapper: React.FC<EmojiWrapperProps> = ({
  ariaLabel,
  emoji,
  ...props
}) => (
  <span role="img" aria-label={ariaLabel} {...props}>
    {emoji}
  </span>
);

export default EmojiWrapper;

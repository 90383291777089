import { PicklistProps } from "react-rainbow-components/components/Picklist";
import { endOfDay } from "date-fns";
import { ReactNode } from "react";

import { IsSubmittedEnum } from "components/EditGrantForm/types";
import yup from "settings/yup";
import i18n from "translations/i18n";

import buildCurrencyTest from "../tests/currencyFieldTest";
import {
  ApplicationStatusEnum,
  GrantFrequencyEnum,
  ReportUrgencyEnum,
} from "generated/graphql/v2/graphql";
import type { Schema } from "yup";

const requiredPastDate = yup
  .date()
  .max(endOfDay(new Date()).toDateString(), i18n.t("yup.string_max"))
  .required();

const requiredCurrencyString = yup
  .string()
  .test(buildCurrencyTest())
  .required();

const reportSchema = yup.object().shape({
  urgency: yup.string().required(),
  deadline: yup.date().required(),
});

const picklistValue = <T>(type: Schema<T>) =>
  yup.object().shape({
    value: type,
  });

type PicklistValue<T> = {
  value: T;
};

const createGrantSchema = yup.object().shape(
  {
    funder: picklistValue(yup.number()).required(),
    program: picklistValue(yup.number()).required(),
    frequency: yup.string().required(),
    recurrenceCount: picklistValue(yup.number()).required(),
    applicationType: yup.object().required(),
    applicationDeadline: yup.date().required(),
    isSubmitted: yup.string(),
    status: yup.string(),
    reports: yup.array().of(reportSchema),
    dateSubmitted: yup.date().when("isSubmitted", {
      is: (value) => value === IsSubmittedEnum.Yes,
      then: requiredPastDate,
      otherwise: yup.date(),
    }),
    amountRequested: yup.string().when("isSubmitted", {
      is: (value) => value === IsSubmittedEnum.Yes,
      then: requiredCurrencyString,
      otherwise: yup.string(),
    }),
    // https://github.com/orgs/Grantflow2/projects/1/views/1?pane=issue&itemId=60759301
    // receivementDate: yup.date().when("status", {
    //   is: (value: ApplicationStatusEnum) => value === "AWARDED",
    //   then: requiredPastDate,
    //   otherwise: yup.date(),
    // })
    receivementDate: yup.date().when(["frequency", "applicationDeadline", "status"], {
      is: (frequency, applicationDeadline, status: ApplicationStatusEnum) => { 
        if(frequency === "RECURRING" && applicationDeadline &&  status === "AWARDED"){
          return applicationDeadline.getFullYear() > (new Date()).getFullYear();
        }
        return  false;
      },
      then: requiredPastDate,
      otherwise: yup.date(),
    }),
    amountAwarded: yup.string().when("status", {
      is: (value: ApplicationStatusEnum) => value === "AWARDED",
      then: requiredCurrencyString,
      otherwise: yup.string(),
    }),
    declinedReason: yup.string(),
    declinedDate: yup.date().when("status", {
      is: (value: ApplicationStatusEnum) => value === "DECLINED",
      then: requiredPastDate,
      otherwise: yup.date(),
    }),
    ineligibleReason: yup.string(),
  },
  [["reportType", "reportDeadline"]],
);

export interface PicklistFunderProps {
  label?: string;
  name?: string | number;
  icon?: ReactNode;
  value?: unknown;
}

interface ReportSchema {
  urgency: ReportUrgencyEnum;
  deadline: Date;
}

export interface CreateGrantSchema {
  funder: PicklistValue<number>;
  program: PicklistValue<number>;
  frequency: GrantFrequencyEnum;
  recurrenceCount: PicklistValue<number>;
  applicationType: PicklistProps["value"];
  applicationDeadline: Date;
  reports: ReportSchema[];
  isSubmitted?: IsSubmittedEnum;
  dateSubmitted: Date;
  amountRequested: string;
  deadline: Date;
  status: ApplicationStatusEnum;
  receivementDate?: Date;
  declinedDate?: Date;
  declinedReason: string;
  amountAwarded: string;
  ineligibleReason: string;
}

export default createGrantSchema;

import { useCallback, useMemo } from "react";
import {
  UrlUpdateType,
  useQueryParam,
  StringParam,
  withDefault,
  NumberParam,
} from "use-query-params";
import { getYear, format } from "date-fns";

import { AllRecordsEnum } from "constants/statuses";

import { Filter, UseFilterResult } from "./types";

/**
 * Exposes possible filters.
 */
export const filters = {
  search: {
    name: "search",
    type: withDefault(StringParam, undefined),
  },
  grantStatus: {
    name: "grantStatus",
    type: withDefault(StringParam, undefined),
  },
  reportStatus: {
    name: "reportStatus",
    type: withDefault(StringParam, AllRecordsEnum.AllRecords),
  },
  submissionMonth: {
    name: "submissionMonth",
    type: withDefault(StringParam, format(new Date(), "MMMM").toUpperCase()),
  },
  submissionYear: {
    name: "submissionYear",
    type: withDefault(StringParam, getYear(new Date()).toString()),
  },
  stepIndex: {
    name: "step",
    type: withDefault(NumberParam, 1),
  },
  fundingByTypeYear: {
    name: "fundingByTypeYear",
    type: withDefault(StringParam, format(new Date(), "yyyy").toUpperCase()),
  },
  largestFunderYear: {
    name: "largestFunderYear",
    type: withDefault(StringParam, format(new Date(), "yyyy").toUpperCase()),
  },
  largestFunderType: {
    name: "largestFunderType",
    type: StringParam,
  },
  awardsComparisonYear: {
    name: "awardsComparisonYear",
    type: withDefault(StringParam, ""),
  },
  dashboardYear: {
    name: "dashboardYear",
    type: withDefault(StringParam, ""),
  },
};

/**
 * Exposes the useQueryParam hook as used by filters.
 * @param filter The filter.
 */
const useFilter = <T>(
  filterObject: Filter,
  updateType: UrlUpdateType = "replaceIn",
): UseFilterResult<T> => {
  const [filter, setFilter] = useQueryParam(
    filterObject.name,
    filterObject.type,
  );

  const handleSetFilter = useCallback(
    (value: unknown) => {
      setFilter(value, updateType);
    },
    [setFilter, updateType],
  );

  const payload = useMemo<UseFilterResult<T>>(
    () => [filter, handleSetFilter],
    [filter, handleSetFilter],
  );

  return payload;
};

export default useFilter;

import { WizardStep } from "components/Wizard/types";
import i18n from "translations/i18n";

import Personal from "./Steps/Personal";
import Address from "./Steps/Address";

enum CreateFunderStep {
  PersonalStep,
  AddressStep
}

const createFunderSteps: WizardStep[] = [
  {
    id: CreateFunderStep.PersonalStep,
    component: Personal,
    title: i18n.t("create_funder.steps.personal.title"),
  },
  {
    id: CreateFunderStep.AddressStep,
    component: Address,
    title: i18n.t("create_funder.steps.address.title"),
  }
];

export default createFunderSteps;

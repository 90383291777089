import { Select as CSelect } from "@chakra-ui/react";
import React, { useMemo } from "react";

export const Select = <T,>({
  options,
  selected,
  onSelect,
  placeholder,
}: {
  options: {
    value: T | undefined;
    label: string;
  }[];
  onSelect: (value: T | undefined) => void;
  placeholder?: string;
  selected: T | undefined;
}) => {
  const optionMap = useMemo(
    () => new Map(options.map(({ value, label }) => [label, value] as const)),
    [options],
  );
  const optionRMap = useMemo(
    () => new Map(options.map(({ value, label }) => [value, label] as const)),
    [options],
  );

  return (
    <CSelect
      variant="outline"
      size="sm"
      borderRadius="full"
      placeholder={placeholder}
      width="auto"
      onChange={(e) => {
        onSelect(optionMap.get(e.target.value));
      }}
      defaultValue={optionRMap.get(selected)}
    >
      {options.map(({ label }) => (
        <option key={label} value={label}>
          {label}
        </option>
      ))}
    </CSelect>
  );
};

import { Flex, Text } from "@chakra-ui/react";
import React, { useCallback } from "react";

import EditButton from "components/EditButton";

import { CardHeaderWithEditProps } from "./types";

const CardHeaderWithEdit: React.FC<CardHeaderWithEditProps> = ({
  isEditing,
  setIsEditing,
  handleSubmit,
  isDisabled = false,
  isLoading = false,
  title,
}) => {
  const handleClick = useCallback(() => {
    if (isEditing) {
      handleSubmit();
    }

    setIsEditing(!isEditing);
  }, [isEditing, handleSubmit, setIsEditing]);

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Text fontFamily="heading" fontSize="xs">
        {title}
      </Text>

      <EditButton
        isEditing={isEditing}
        handleClick={handleClick}
        isDisabled={isDisabled}
        isLoading={isLoading}
      />
    </Flex>
  );
};

export default CardHeaderWithEdit;

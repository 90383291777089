import { FragmentType, graphql, useFragment } from "generated/graphql/v2";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { FormContainer } from "components/_new/theme/forms/FormContainer";
import React from "react";
import { Button } from "components/_new/theme/Button";
import { useMutation } from "@apollo/client";
import { useToast } from "contexts/toast";
import { useModal } from "contexts/modal";
import { MoneyInput } from "components/_new/theme/inputs/MoneyInput";
import { ToggleInput } from "components/_new/theme/inputs/ToggleInput";

export const EditProgramGoalFragment = graphql(`
  fragment EditProgramGoalFragment on Program {
    id
    yearlyGoal(fiscalYear: $fy)
  }
`);

const EditProgramGoalMutation = graphql(`
  mutation EditProgramGoalMutation(
    $programId: Int!
    $year: Int!
    $goal: Decimal!
    $singleYear: Boolean
  ) {
    updateProgramYearlyGoal(
      id: $programId
      params: { goal: $goal, year: $year, singleYear: $singleYear }
    ) {
      id
    }
  }
`);

const EditGoalModal = (props: {
  hideModal: () => void;
  componentProps: {
    program: FragmentType<typeof EditProgramGoalFragment>;
    year: number;
  };
}) => {
  const [t] = useTranslation();
  const program = useFragment(
    EditProgramGoalFragment,
    props.componentProps.program,
  );
  const { addToast } = useToast();

  const [updateProgramGoal] = useMutation(EditProgramGoalMutation, {
    refetchQueries: ["ListProgramsQuery"],
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<{
    goal: string;
    singleYear: boolean;
  }>({
    defaultValues: {
      goal: program.yearlyGoal,
      singleYear: false,
    },
  });

  return (
    <FormContainer
      onSubmit={handleSubmit(async (data) => {
        try {
          await updateProgramGoal({
            variables: {
              ...data,
              programId: program.id,
              year: props.componentProps.year,
            },
          });
          addToast({
            title: t("programs.edit_year_goal_success"),
            description: t("programs.edit_year_goal_success_description"),
            type: "success",
          });
          props.hideModal();
        } catch {
          addToast({
            title: t("programs.edit_year_goal_error"),
            description: t("programs.edit_year_goal_error_description"),
            type: "error",
          });
        }
      })}
    >
      <Controller
        name="goal"
        control={control}
        render={({ onChange, value }) => (
          <MoneyInput
            {...{ onChange, value }}
            label={t("programs.new_goal")}
            error={errors.goal}
          />
        )}
      />
      <Controller
        name="singleYear"
        control={control}
        render={({ onChange, value }) => (
          <ToggleInput
            {...{ onChange, value }}
            label={t("programs.edit_single_year")}
            labelAlignment="left"
          />
        )}
      />

      <Button
        type="submit"
        isLoading={isSubmitting}
        backgroundColor="secondary.200"
      >
        {t("programs.edit_year_goal")}
      </Button>
    </FormContainer>
  );
};

export const useEditGoalModal = () => {
  const [t] = useTranslation();
  const [showModal] = useModal();

  return (
    program: FragmentType<typeof EditProgramGoalFragment>,
    year: number,
  ) =>
    showModal({
      title: t("modals.edit_program_goal.title"),
      component: EditGoalModal,
      componentProps: {
        program,
        year,
      },
    });
};

import getFiscalYear from "./getFiscalYear";

/**
 * Receives two dates and returns the years in this range
 * @param start first date of the range
 * @param end last date of the range
 * @param fiscalYearStart The fiscal year start from org configuration.
 * @returns A list of fiscal years that includes all the dates in the interval.
 */
const getFiscalYearsInInterval = (
  start: Date,
  end: Date,
  fiscalYearStart: Date,
): string[] => {
  const firstFiscalYear = getFiscalYear(start, fiscalYearStart);
  const lastFiscalYear = getFiscalYear(end, fiscalYearStart);

  if (firstFiscalYear > lastFiscalYear) return [];

  return Array.from(
    { length: lastFiscalYear - firstFiscalYear + 1 },
    (_, index) => (lastFiscalYear - index).toString(),
  );
};

export default getFiscalYearsInInterval;

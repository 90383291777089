import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { graphql } from "generated/graphql/v2";
import { useOrganizationId } from "hooks/_new/useOrganization";
import { Cell, PaginatedTable } from "components/_new/theme/Table";
import Loading from "components/Loading";
import formatCurrency from "utils/formatCurrency";
import { Flex, Heading, Link } from "@chakra-ui/react";
import { Link as RLink } from "react-router-dom";
import ExternalLink from "components/_new/theme/ExternalLink";
import { SearchInput } from "components/_new/theme/inputs/SearchInput";
import { useQueryParam, StringParam } from "use-query-params";
import { useDebouncedQuery } from "hooks/_new/useDebouncedQuery";
import Button from "components/Button";
import { useModal } from "contexts/modal";
import CreateFunderForm from "components/CreateFunderForm";
import { CreateFunderMutation } from "generated/graphql";
import { pages } from "routes/pages";
import useNavigate from "hooks/useNavigate";

const buttonsFontSize = { base: "xxs", md: "xxs", lg: "xs" };

const ListFundersQuery = graphql(`
  query ListFundersQuery($organizationId: Int!, $search: String) {
    listFunders(filters: { organizationId: $organizationId, search: $search }) {
      id
      name
      email
      type
      website
      totalAwardAmount
    }
  }
`);

const FundersList: React.FC = () => {
  const [t] = useTranslation();
  const organizationId = useOrganizationId() ?? 0;
  const navigate = useNavigate();

  const [search, setSearch] = useQueryParam("search", StringParam);
  const { data, loading, debouncedOn } = useDebouncedQuery(
    ListFundersQuery,
    [search, organizationId],
    { variables: { organizationId, search } },
  );

  const [showModal] = useModal();

  const successCallback = useCallback(
    (funder?: CreateFunderMutation["createFunder"]) => {
      const funderPageUrl = funder?.id
        ? pages.funderProfile.pathWithParams(funder.id)
        : pages.fundersList.pathWithParams();
      navigate(funderPageUrl)();
    },
    [navigate],
  );

  const openCreateNewFunderModal = useCallback(() => {
    showModal({
      title: t("create_funder.title"),
      component: CreateFunderForm,
      componentProps: {
        successCallback,
      },
      closeOnOverlayClick: false,
    });
  }, [t, showModal, successCallback]);

  return (
    <Flex padding={8} direction="column" gap={8}>
      <Heading
        color="primary.200"
        fontFamily="heading"
        fontSize="xl"
        textTransform="uppercase"
      >
        <Flex
          direction="row"
          gap={8}
          alignItems={"center"}
          justifyContent={"space-between"}
        >

          {t("tables.titles.funders")}

          <Flex
            direction="row"
            gap={8}
            alignItems={"center"}
            justifyContent={"end"}
            flexGrow={1}
          >

            <SearchInput search={search ?? ""} onChange={setSearch} width="50%" />

            <Button
            background="primary.100"
            fontSize={buttonsFontSize}
            onClick={openCreateNewFunderModal}
          >
            {t("buttons.add_new_funder")}
          </Button>
          </Flex>
        </Flex>
      </Heading >
      {loading || !data ? (
        <Loading />
      ) : (
        <PaginatedTable
          key={`funders-list-${debouncedOn.join("-")}`}
          headers={[
            t("tables.columns_titles.name"),
            t("tables.columns_titles.email"),
            t("tables.columns_titles.type"),
            t("tables.columns_titles.website"),
            t("tables.columns_titles.total_award_amount"),
          ]}
          pageSize={12}
          data={data.listFunders ?? []}
          rowKey="id"
          rowMapper={(funder) => (
            <>
              <Cell>
                <Link
                  as={RLink}
                  color="primary.100"
                  _hover={{ color: "primary.100" }}
                  padding={2}
                  to={`/funder/${funder.id}`}
                >
                  {funder.name}
                </Link>
              </Cell>
              <Cell>{funder.email}</Cell>
              <Cell>{t(`funder_types.${funder?.type}`)}</Cell>
              <Cell>
                {funder.website ? (
                  <ExternalLink
                    color="primary.100"
                    _hover={{ color: "primary.100" }}
                    padding={2}
                    url={funder.website}
                  />
                ) : (
                  "-"
                )}
              </Cell>
              <Cell>{formatCurrency(funder.totalAwardAmount ?? 0)}</Cell>
            </>
          )}
        />
      )}
    </Flex >
  );
};

export default FundersList;

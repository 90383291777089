import React from "react";
import { ModalBody, ModalFooter, Text } from "@chakra-ui/react";
import { useTranslation, Trans } from "react-i18next";

import Button from "components/Button";

import { DeleteFunderModalProps } from "./types";

const DeleteModal: React.FC<DeleteFunderModalProps> = ({
  componentProps: { entryName, onDelete },
}) => {
  const [t] = useTranslation();

  return (
    <>
      <ModalBody>
        <Text fontSize="sm" textAlign="center">
          <Trans
            i18nKey="modals.delete.are_you_sure_that_you_want_to_delete"
            values={{ entryName }}
            components={{
              HighLightedText: (
                <Text
                  as="span"
                  fontFamily="heading"
                  color="secondary.100"
                  textTransform="uppercase"
                />
              ),
            }}
          />
        </Text>
      </ModalBody>

      <ModalFooter>
        <Button width="full" backgroundColor="status.error" onClick={onDelete}>
          {t("actions.delete")}
        </Button>
      </ModalFooter>
    </>
  );
};

export default DeleteModal;

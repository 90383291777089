/**
 * Defines element ids for dashboard containers.
 */
const elementIds = {
  fundraisngTracker: "dashboard-fundraisng-tracker",
  comparisonByYear: "dashboard-comparison-by-year",
  fundingByType: "dashboard-funding-by-type",
  grantsAwarded: "dashboard-grants-awarded",
  grantsPending: "dashboard-grants-pending",
  applicationSent: "dashboard-application-sent",
};

export default elementIds;

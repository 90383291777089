import React, { useCallback, useState } from "react";
import { ModalBody, ModalFooter, Stack, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import boardReportSchema, {
  BoardReportSchema,
} from "settings/yup/schemas/boardReportSchema";
import Checkbox from "components/FormComponents/Checkbox";
import Button from "components/Button";

import useBoardReportValidation from "./hooks/useBoardReportValidation";
import options from "./options";
import { OnClose } from "contexts/modal/types";

const PRINT_TIMEOUT_MS = 800;

const BoardReportModal: React.FC<{
  hideModal: OnClose;
}> = ({ hideModal }) => {
  const [t] = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const handlePrintPage = useCallback(() => {
    setIsLoading(true);

    setTimeout(hideModal, PRINT_TIMEOUT_MS / 2);

    setTimeout(window.print, PRINT_TIMEOUT_MS);
  }, [hideModal]);

  const form = useForm<BoardReportSchema>({
    resolver: yupResolver(boardReportSchema),
    mode: "onBlur",
    defaultValues: {
      fundraisngTracker: true,
      comparisonByYear: true,
      fundingByType: true,
      grantsAwarded: true,
      grantsPending: true,
      applicationSent: true,
    },
  });

  useBoardReportValidation(form);

  return (
    <>
      <ModalBody p={4}>
        <Text fontFamily="semibold" color="gray.400" fontSize="xs">
          {t(
            "board_report.select_all_the_information_you_would_like_to_be_on_the_report",
          )}
        </Text>

        <Stack spacing={3} mt={4}>
          {options.map((option) => (
            <Checkbox
              ref={form.register}
              name={option.name}
              key={option.name}
              defaultChecked
            >
              {option.title}
            </Checkbox>
          ))}
        </Stack>
      </ModalBody>

      <ModalFooter>
        <Button
          width="full"
          isLoading={isLoading}
          backgroundColor="primary.100"
          onClick={handlePrintPage}
        >
          {t("board_report.download_report_in_pdf")}
        </Button>
      </ModalFooter>
    </>
  );
};

export default BoardReportModal;

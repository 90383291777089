import {
  FragmentType,
  graphql,
  useFragment as readFragment,
} from "generated/graphql/v2";
import create from "zustand";

export const AdminImpersonationFragment = graphql(`
  fragment AdminImpersonationFragment on Organization {
    id
    currentFiscalYear
  }
`);

const useAdminImpersonationStore = create<{
  organization?: FragmentType<typeof AdminImpersonationFragment>;
  isVisible: boolean;
  setOrganization: (
    organization?: FragmentType<typeof AdminImpersonationFragment>,
  ) => void;
  getOrganizationId: (fallback: number) => number;
}>((set, get) => ({
  organization: undefined,
  isVisible: false,
  setOrganization: (organization) => {
    set({ organization: organization ?? undefined });
  },
  getOrganizationId: (fallback) => {
    const org = readFragment(AdminImpersonationFragment, get().organization);

    return org?.id ?? fallback;
  },
}));

export default useAdminImpersonationStore;

export const readOrganization = (
  organization: FragmentType<typeof AdminImpersonationFragment>,
) => readFragment(AdminImpersonationFragment, organization);

import { FunderTypeEnum } from "generated/graphql";
import { FunderTypeEnum as FunderType } from "generated/graphql/v2/graphql";
import yup from "settings/yup";

import { makePhoneNumberTest } from "../tests/phoneNumberTest";

export const defaultUpdateFunderValues = {
  name: "",
  email: "",
  phone: "",
  type: {
    value: undefined,
    name: undefined,
    label: undefined,
  },
  website: "",
  complexityScore: "",
  keyContacts: "",
  location: {
    street_address: "",
    city: "",
    state: "",
    zipcode: "",
    complement: "",
  },
  street_address: "",
  city: "",
  state: "",
  zipcode: "",
  complement: "",
};

const updateFunderSchema = yup.object().shape({
  name: yup.string().default(defaultUpdateFunderValues.name).required(),
  email: yup.string().default(defaultUpdateFunderValues.email).email(),
  phone: yup
    .string()
    .default(defaultUpdateFunderValues.phone)
    .test(makePhoneNumberTest(false)),
  keyContacts: yup.string().default(defaultUpdateFunderValues.keyContacts),
  type: yup.object().default(defaultUpdateFunderValues.type).required(),
  website: yup.string().default(defaultUpdateFunderValues.website),
  complexityScore: yup
    .string()
    .trim()
    .test(
      'valid-complexity-score',
      'Input a number between 0 and 20',
      (value) => {
        if (!value || value.length === 0) return true; // Allow empty values
        const validValues = Array.from({ length: 21 }, (_, i) => String(i)); // Generate ["0", "1", ..., "20"]
        return validValues.includes(value); // Validate only against allowed values
      }
    ),
  street_address: yup.string().default(defaultUpdateFunderValues?.location?.street_address ?? ""),
  city: yup.string().default(defaultUpdateFunderValues?.location?.city ?? ""),
  state: yup.string().default(defaultUpdateFunderValues?.location?.state ?? ""),
  zipcode: yup.string().default(defaultUpdateFunderValues?.location?.zipcode ?? ""),
  complement: yup.string().default(defaultUpdateFunderValues?.location?.complement ?? ""),
});
export interface UpdateFunderSchema {
  name: string;
  email: string;
  phone: string;
  keyContacts: string;
  type: FunderTypeOption;
  website: string;
  complexityScore: string;
  location: FunderLocationOption;
}

export interface UpdateFunderSchemaWSum {
  name: string;
  email: string;
  phone: string;
  keyContacts: string;
  type: {
    name: string;
    value: FunderType;
    label: string;
  };
  website: string;
  complexityScore: string;
  sum: number;
  street_address?: string | null;
  city?: string | null;
  state?: string | null;
  zipcode?: string | null;
  complement?: string | null;
}

export interface FunderTypeOption {
  value: FunderTypeEnum;
  name?: string;
  icon?: unknown;
  label: string;
}

export interface FunderLocationOption {
  street_address?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  complement?: string;
}

export const defaultUpdateFunderLocationValues = {
  street_address: "",
  city: "",
  state: "",
  zipcode: "",
  complement: "",
};

export const updateFunderLocationSchema = yup.object().shape({
  location: yup.object().default(defaultUpdateFunderLocationValues).required(),
});

export interface UpdateFunderLocationSchema {
  location: {
    street_address: string;
    city: string;
    state: string;
    zipcode: string;
    complement: string;
  };
}

export default updateFunderSchema;

import React from "react";
import { Flex, HStack, SimpleGrid, VStack } from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  DatePicker,
  RadioGroup,
  Picklist,
  Option,
  Input,
} from "react-rainbow-components";

import MaskedInput from "components/FormComponents/MaskedInput";
import getErrorMessage from "utils/getErrorMessage";
import { buildCurrencyMask } from "constants/masks";
import { submitOptions } from "constants/submitOptions";
import MoneyBagIcon from "settings/theme/ChakraTheme/icons/MoneyBagIcon";
import { ApplicationStatusEnum } from "generated/graphql";
import { urgenciesSelectOptions } from "constants/urgenciesOptions";
import i18n from "translations/i18n";
import Button from "components/Button";
import { IsSubmittedEnum } from "components/EditReportForm/types";

import { EditGrantFormFieldsProps } from "./types";

const newStatusOptions = [
  { value: ApplicationStatusEnum.Awarded, label: i18n.t("statuses.awarded") },
  { value: ApplicationStatusEnum.Declined, label: i18n.t("statuses.declined") },
  { value: ApplicationStatusEnum.Pending, label: i18n.t("statuses.pending") },
  // removed with this issue
  // https://github.com/orgs/Grantflow2/projects/1/views/1?pane=issue&itemId=36555817
  // {
  //   value: ApplicationStatusEnum.NotApply,
  //   label: i18n.t("statuses.not_apply"),
  // },
];

const EditGrantFormFields: React.FC<EditGrantFormFieldsProps> = ({
  control,
  errors,
  register,
  isSubmitted,
  isAwarded,
  isDeclined,
  isIneligible,
  reportsFieldArray,
  shouldShowDeadlineField = false,
  ineligibleReason = "",
  listPrograms = [],
  ...rest
}) => {
  const [t] = useTranslation();

  const { fields, append, remove } = reportsFieldArray;

  return (
    <VStack spacing={6} width="full" {...rest}>
      
      <Flex width="full">
        <Controller
          as={RadioGroup}
          control={control}
          name="isSubmitted"
          label={t("modals.update_grant.application_submitted")}
          error={getErrorMessage("isSubmitted", errors)}
          orientation="horizontal"
          options={submitOptions}
          labelAlignment="left"
        />
      </Flex>

      {(shouldShowDeadlineField ||
        isSubmitted === IsSubmittedEnum.Ineligible ||
        isIneligible) && (
          <Controller
            as={DatePicker}
            control={control}
            name="deadline"
            label={t("modals.update_grant.application_deadline")}
            error={getErrorMessage("deadline", errors)}
            required
            labelAlignment="left"
          />
        )}

      {isSubmitted === IsSubmittedEnum.Yes && (
        <>
          <Controller
            as={DatePicker}
            control={control}
            name="dateSubmitted"
            label={t("modals.update_grant.date_submitted")}
            error={getErrorMessage("dateSubmitted", errors)}
            required
            labelAlignment="left"
          />

          <MaskedInput
            ref={register}
            title={t("modals.update_grant.amount_requested")}
            name="amountRequested"
            mask={buildCurrencyMask()}
            errors={errors}
            isRequired
            rightElement={<MoneyBagIcon />}
          />

          <VStack spacing={6} width="full">
            <Flex width="full">
              <Controller
                as={RadioGroup}
                control={control}
                label={t("modals.update_grant.current_status")}
                orientation="horizontal"
                name="status"
                options={newStatusOptions}
                labelAlignment="left"
              />
            </Flex>

            {isAwarded && (
              <>
                <Controller
                  as={DatePicker}
                  control={control}
                  name="receivementDate"
                  label={t("modals.update_grant.receivement_date")}
                  error={getErrorMessage("receivementDate", errors)}
                  labelAlignment="left"
                />

                <MaskedInput
                  ref={register}
                  title={t("modals.update_grant.amount_awarded")}
                  name="amountAwarded"
                  mask={buildCurrencyMask()}
                  errors={errors}
                  rightElement={<MoneyBagIcon />}
                />

                {fields.map((field, index) => (
                  <SimpleGrid
                    columns={2}
                    gap={6}
                    alignItems="baseline"
                    key={field.id}
                  >
                    <Controller
                      control={control}
                      name={`reports.${index}.urgency`}
                      render={({ value, onChange, ...fieldProps }) => (
                        <Picklist
                          error={getErrorMessage(
                            `reports.${index}.urgency`,
                            errors,
                          )}
                          label={t("create_grant.steps.deadlines.report_type")}
                          placeholder={t(
                            "create_grant.steps.deadlines.select_a_type",
                          )}
                          labelAlignment="left"
                          value={urgenciesSelectOptions.find(
                            (option) => option.value === value,
                          )}
                          onChange={(evt) => onChange(evt.value)}
                          {...fieldProps}
                        >
                          {urgenciesSelectOptions.map((type) => (
                            <Option
                              name={type.value}
                              value={type.value}
                              label={type.label}
                              key={type.label}
                            />
                          ))}
                        </Picklist>
                      )}
                    />

                    <Controller
                      as={DatePicker}
                      control={control}
                      name={`reports.${index}.deadline`}
                      error={getErrorMessage(
                        `reports.${index}.deadline`,
                        errors,
                      )}
                      label={t("create_grant.steps.deadlines.report_deadline")}
                    />
                  </SimpleGrid>
                ))}
                <HStack>
                  <Button
                    bgColor="#6364ED"
                    onClick={() => append({})}
                    fontSize="xs"
                  >
                    {t("buttons.add_report")}
                  </Button>
                  {fields.length > 0 && (
                    <Button
                      bgColor="#293CFB"
                      onClick={() => remove(fields.length - 1)}
                      fontSize="xs"
                    >
                      {t("buttons.remove_report")}
                    </Button>
                  )}
                </HStack>
              </>
            )}

            {isDeclined && (
              <>
                <Controller
                  as={DatePicker}
                  required
                  control={control}
                  name="declinedDate"
                  label={t("modals.update_grant.declined_date")}
                  error={getErrorMessage("declinedDate", errors)}
                  labelAlignment="left"
                />

                <Controller
                  as={Input}
                  name="declinedReason"
                  type="text"
                  control={control}
                  error={getErrorMessage("name", errors)}
                  label={t("modals.update_grant.declined_reason")}
                />
              </>
            )}
          </VStack>
        </>
      )}

      {(isSubmitted === IsSubmittedEnum.Ineligible) && (
        <VStack spacing={12} width="full">
          <Flex width="full" className="full-width">
            <Controller
              as={Input}
              name="ineligibleReason"
              type="text"
              control={control}
              error={getErrorMessage("name", errors)}
              label={t("modals.update_grant.ineligible_reason")}
              labelAlignment="left"
              required
            />
          </Flex>
        </VStack>
      )}
    </VStack>
  );
};

export default EditGrantFormFields;

import {
  DocumentNode,
  NoInfer,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useLazyQuery,
} from "@apollo/client";
import { DependencyList, useEffect, useState } from "react";
import { useDebounce } from "react-use";

export const useDebouncedQuery = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
  TDeps extends DependencyList = DependencyList,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  debounceOn: TDeps,
  options?: QueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>,
  { runInitial = true, wait = 1000 } = {},
): QueryResult<TData, TVariables> & {
  bouncing: boolean;
  debouncedOn: TDeps;
} => {
  const [exec, res] = useLazyQuery(query);
  const [ranFirst, setRanFirst] = useState(false);
  // this one is useful for setting keys on rendered objects
  const [debouncedOn, setDebouncedOn] = useState(debounceOn);

  useEffect(() => {
    if (runInitial) {
      exec(options);
      setRanFirst(true);
    }
  }, [exec]);

  const [b] = useDebounce(
    () => {
      setRanFirst(false);
      exec(options);
      setDebouncedOn(debouncedOn);
    },
    wait,
    [exec, ...debounceOn],
  );

  return {
    ...res,
    bouncing: ranFirst || !b(),
    debouncedOn,
  };
};

import { Box, BoxProps, Flex } from "@chakra-ui/react";
import React from "react";
import { Header } from "./Header";

// TODO: Move this to ChakraUI's card component when updated
export const Card: React.FC<
  BoxProps & {
    header?: React.ReactNode;
  }
> = ({ header, children, ...props }) => (
  <Box
    width="full"
    boxShadow="none"
    borderRadius="4px"
    background="white"
    padding={5}
    display="flex"
    flexDirection="column"
    gap={6}
    overflowX="auto"
    color="rgba(0,0,0,0.87)"
    border="0.0625rem solid rgba(223,223,223,1)"
    _hover={{
      boxShadow: "0 2px 12px 0 rgba(173,173,173,1)",
      transition: "all 150ms cubic-bezier(0.4,0,0.2,1) 0ms",
    }}
    {...props}
  >
    {header && (
      <Flex direction="row" justifyContent="space-between" alignItems="center">
        {header}
      </Flex>
    )}
    {children}
  </Box>
);

export const CardTitle = ({ children }: { children: string }) => (
  <Header as="h2">{children}</Header>
);

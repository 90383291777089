import { Stack } from "@chakra-ui/react";
import React from "react";

export const FormContainer = (props: {
  onSubmit: React.FormEventHandler;
  children: React.ReactNode;
}) => (
  <Stack
    as="form"
    marginBottom={8}
    padding={4}
    spacing={6}
    onSubmit={props.onSubmit}
  >
    {props.children}
  </Stack>
);

import yup from "settings/yup";

const updateNotesSchema = yup.object().shape({
  notes: yup.string(),
});

export interface UpdateNotesSchema {
  notes: string;
}

export default updateNotesSchema;

import elementIds from "views/Deadlines/elementIds";

import { DeadlineReportOption } from "./types";

const options: DeadlineReportOption[] = [
  {
    name: "grantDeadlines",
    title: "Grant Deadlines",
    elementId: elementIds.grantDeadlines,
  },
  {
    name: "reportDeadlines",
    title:  "Report Deadlines",
    elementId: elementIds.reportDeadlines,
  },
];

export default options;

import React, { useRef, useState, useEffect } from "react";
import { VStack } from "@chakra-ui/react";
import { Flex, ModalBody, ModalFooter } from "@chakra-ui/react";
import Button from "components/Button";
import Loading from "components/Loading";
import { Editor } from '@tinymce/tinymce-react';
import { UlyssesChatSettingModalProps } from "./types";
import { useCurrentUser } from "contexts/currentUser";
import { useOrganization } from "hooks/_new/useOrganization";
import getReactAppBackendURL from "utils/env";
import Checkbox from "components/FormComponents/Checkbox";
import {
    formatDate,
    displayMonthDayYear,
    yearMonthDay,
} from "utils/dateFormats";
import { useToast } from "contexts/toast";

const backendUrl = getReactAppBackendURL();

const UlyssesChatSettingsModal: React.FC<UlyssesChatSettingModalProps> = ({
    hideModal,
    componentProps: { },
}) => {
    const { addToast } = useToast();

    const [user] = useCurrentUser();
    const org = useOrganization();

    const API_KEY = "rczx4fwc4qzoobqcst4k1cahipyn6pazlb400jh6xvxbmfz3";
    const editorRef = useRef(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [viewAdminVersions, setViewAdminVersions] = useState<boolean>(user?.role === "ADMIN");
    const [selectedVersionId, setSelectedVersionId] = useState<number>(0);
    const [versions, setVersions] = useState<any[]>([]);
    const storedLayout = localStorage.getItem("ulysses-layout")
    const [layout, setLayout] = useState<string>(storedLayout ? storedLayout : "row");
    const [showDeletePrompt, setShowDeletePrompt] = useState<boolean>(false);


    // form fields
    const [organizationId, setOrganizationId] = useState<number>(0);
    const [userId, setUserId] = useState<number>(0);
    const datenow = formatDate(new Date(), yearMonthDay);
    const [authoredMonthDate, setAuthoredMonthDate] = useState<string>(formatDate(new Date(), yearMonthDay));
    const [version, setVersion] = useState<string>("");
    const [aboutYouDetails, setAboutYouDetails] = useState<string>("<p></p>");
    const [responseStyleDetails, setResponseStyleDetails] = useState<string>("<p></p>");
    const [remarks, setRemarks] = useState<boolean>(false); // used for setting is active or not
    const [authoredByUserId, setAuthoredByUserId] = useState<number>(user?.id ?? 0);

    const handleVersionInput = async (event: any) => {
        event.preventDefault();
        const input = event.target.value;
        setVersion(input);
    }

    const clearFormFields = async (curentDatenow: string, nextVersionNumber: string) => {
        // for now we are assuming only admin configs
        setOrganizationId(0);
        setUserId(0);
        setVersion(nextVersionNumber);
        setAuthoredMonthDate(curentDatenow);
        setAboutYouDetails("<p></p>");
        setResponseStyleDetails("<p></p>");
        setRemarks(false); // used for setting is active or not
        setAuthoredByUserId(user?.id ?? 0);
    }

    const handleCreateNewChat = async (event: any) => {
        event.preventDefault();

        const curentDatenow = formatDate(new Date(), yearMonthDay);
        const nextVersionNumber = `v${versions.length + 1}@${curentDatenow}`;
        clearFormFields(curentDatenow, nextVersionNumber);

        //auto copy from the most recent active version
        // the versions already come in ordered by date decending
        for (let index = 0; index < versions.length; index++) {
            const configVersion = versions[index];
            if (configVersion.remarks === "active") {
                setAboutYouDetails(configVersion.about_you_details);
                setResponseStyleDetails(configVersion.response_style_details);
                break;
            }
        }
        setSelectedVersionId(0);
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setIsLoading(true);
        const xhr = new XMLHttpRequest();
        const endPoint = `/ai/set-fine-tunning`;
        const link = backendUrl?.replace("/graphql", endPoint) ?? endPoint;
        xhr.open('POST', link, true);
        xhr.setRequestHeader("Content-type", "application/json; charset=UTF-8");
        xhr.responseType = 'json';

        xhr.onload = () => {
            setIsLoading(false);
            if (xhr.status >= 200 && xhr.status < 300) {
                const resp = typeof xhr.response == "string" ? JSON.parse(xhr.response) : xhr.response;
                if (resp.status === 500) {
                    setErrorMessage(resp.error);
                } else {
                    // add this to the versions if it was a new version, other wise update the existing version
                    // and make it the currently selected version if its a new version
                    setVersions(resp.data.list);
                    setSelectedVersionId(resp.data.id);

                    // display a sucess toast
                    addToast({
                        title: `Fine tunning version ${version} was saved successfully`,
                        type: "success",
                    });
                }
            } else {
                setErrorMessage(xhr.statusText);
            }
        };

        xhr.onerror = () => {
            setIsLoading(false);
            console.log("Error settings ulysses settings", xhr.statusText);
            setErrorMessage("Error settings ulysses settings");
        };

        const dataToSend = JSON.stringify({
            organization_id: organizationId,
            user_id: userId,
            version: version,
            authored_month_date: authoredMonthDate,
            about_you_details: aboutYouDetails,
            response_style_details: responseStyleDetails,
            remarks: remarks ? "active" : "disabled",
            authored_by_user_id: authoredByUserId
        });

        xhr.send(dataToSend);
    }

    //   const onHide = (success: boolean) => {
    //     if (success) {
    //       // window.location.reload();
    //     }
    //     hideModal();
    //   };

    //load the users configurations
    const loadSettings = async (callback: any = undefined) => {
        setIsLoading(true);
        const xhr = new XMLHttpRequest();
        const authorId = user?.id ?? 0;
        let orgId = org?.id ?? 0;
        let userId = user?.id ?? 0;
        if (viewAdminVersions) {
            orgId = 0;
            userId = 0;
        }
        // for now fetch only admin versions, when extending remove the two lines below
        orgId = 0;
        userId = 0;
        const endPoint = `/ai/get-fine-tunning/${authorId}/${orgId}/${userId}`;
        const link = backendUrl?.replace("/graphql", endPoint) ?? endPoint;
        xhr.open('GET', link, true);
        xhr.setRequestHeader("Content-type", "application/json; charset=UTF-8");
        xhr.responseType = 'json';

        xhr.onload = () => {
            setIsLoading(false);
            if (xhr.status >= 200 && xhr.status < 300) {
                const resp = typeof xhr.response == "string" ? JSON.parse(xhr.response) : xhr.response;
                if (resp.status === 500) {
                    setErrorMessage(resp.error);
                } else {
                    setVersions(resp.data);
                    if (typeof callback === 'function') {
                        callback(resp.data);
                    }
                }
            } else {
                setErrorMessage(xhr.statusText);
            }
        };

        xhr.onerror = () => {
            setIsLoading(false);
            console.log("Error fetching ulysses settings", xhr.statusText);
            setErrorMessage("Error fetching ulysses settings");
        };

        xhr.send();
    }

    const handleSelectVersion = (event: any, versionClicked: any) => {
        event.preventDefault();
        setSelectedVersionId(versionClicked.id);
        setOrganizationId(versionClicked.organization_id);
        setUserId(versionClicked.user_id);
        setVersion(versionClicked.version);
        setAboutYouDetails(versionClicked.about_you_details);
        setResponseStyleDetails(versionClicked.response_style_details);
        setRemarks(versionClicked.remarks === "active"); // used for setting is active or not
        setAuthoredByUserId(versionClicked.authored_by_user_id);
        setShowDeletePrompt(false);
    }

    const handleToggleLayout = (event: any) => {
        event.preventDefault();
        const layoutToSet = layout === "row" ? "column" : "row";
        setLayout(layoutToSet)
        localStorage.setItem("ulysses-layout", layoutToSet);
        setShowDeletePrompt(false);
    }

    const handlePromptDelete = (event: any) => {
        event.preventDefault();
        setShowDeletePrompt(true);
    }

    const handleCancelDelete = (event: any) => {
        event.preventDefault();
        setShowDeletePrompt(false);
    }

    const handleDeleteSubmit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setIsLoading(true);
        const xhr = new XMLHttpRequest();
        const endPoint = `/ai/delete-fine-tunning`;
        const link = backendUrl?.replace("/graphql", endPoint) ?? endPoint;
        xhr.open('POST', link, true);
        xhr.setRequestHeader("Content-type", "application/json; charset=UTF-8");
        xhr.responseType = 'json';

        xhr.onload = () => {
            setIsLoading(false);
            if (xhr.status >= 200 && xhr.status < 300) {
                const resp = typeof xhr.response == "string" ? JSON.parse(xhr.response) : xhr.response;
                if (resp.status === 500) {
                    setErrorMessage(resp.error);
                } else {
                    settingsLoader();
                    setShowDeletePrompt(false);
                    // display a sucess toast
                    addToast({
                        title: `Fine tunning version ${version} was deleted successfully`,
                        type: "success",
                    });
                }
            } else {
                setErrorMessage(xhr.statusText);
            }
        };

        xhr.onerror = () => {
            setIsLoading(false);
            console.log("Error deleting ulysses settings", xhr.statusText);
            setErrorMessage("Error deleting ulysses settings");
        };

        const dataToSend = JSON.stringify({
            id: selectedVersionId
        });

        xhr.send(dataToSend);
    }

    const settingsLoader = async () => {
        loadSettings((loadedVersions: any[]) => {
            // auto select the active config
            const activeVersion = loadedVersions.find((v) => v.remarks === "active");
            if (activeVersion) {
                handleSelectVersion({ preventDefault: () => false }, activeVersion);
            }else{
                setSelectedVersionId(0);
                // just clear the form
                const curentDatenow = formatDate(new Date(), yearMonthDay);
                clearFormFields(curentDatenow, "");
            }
        });
    }

    useEffect(() => {
        setErrorMessage("");
        settingsLoader();
    }, [org]);

    return (
        <VStack  >
            <ModalBody as="form" width="full">
                <Flex flexDirection="row" justifyContent="start" alignItems={"start"} >
                    <Flex flexDirection="column" justifyContent="start" alignItems={"start"} width="200px" padding="10px" >
                        <Flex flexDirection="row" justifyContent="space-between" alignItems={"center"} width="full" paddingTop="5px" >
                            <h1 style={{ fontWeight: "bolder", fontSize: "16px" }}>Versions</h1>
                            <button className="version-new-btn" disabled={isLoading} type="button" onClick={handleCreateNewChat} >New</button>
                        </Flex>

                        <div style={{ paddingTop: "10px" }}>
                            {user?.role === "ADMIN" &&
                                <div style={{ width: "fit-content" }}>
                                    {/* note, this was isChecked={viewAdminVersions} */}
                                    <Checkbox
                                        name="view_admin_versions"
                                        isChecked={true}
                                        onChange={(e) => setViewAdminVersions(e.target.checked)}
                                    >
                                        View Admin Configs
                                    </Checkbox>
                                </div>
                            }
                        </div>

                        <Flex
                            flexDirection="column"
                            justifyContent="start"
                            alignItems={"start"}
                            width="full"
                            marginTop="10px"
                            paddingTop="10px"
                            borderTop="1px solid #EAEAEA"
                            rowGap="1"
                            maxHeight="500px"
                            overflowY="auto"
                        >
                            {/* {versions.filter((v) => {
                                if(viewAdminVersions === true){
                                    return v.organization_id === 0 && v.user_id === 0
                                }
                                return v.organization_id !== 0 && v.user_id !== 0;                                  
                            }) */}
                            {versions.map((v) => {
                                return <div key={v.id} className={`ulysses-version-item ${selectedVersionId === v.id ? "active" : ""}`} onClick={(e) => handleSelectVersion(e, v)}>
                                    <div className="status">
                                        <span>{v.authored_month_date} { }</span>
                                        {v.remarks === 'active' &&
                                            <span style={{ color: "green" }} >
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" style={{ width: "20px", height: "20px" }}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                </svg>
                                            </span>
                                        }
                                    </div>

                                    <div className="version">
                                        {v.version}
                                    </div>
                                </div>
                            })}
                        </Flex>
                    </Flex>
                    <Flex hidden={showDeletePrompt === true} flexDirection="column" justifyContent="start" alignItems={"start"} width="full" padding="10px" style={{ borderLeft: "1px solid #EAEAEA" }}  >
                        <Flex flexDirection="row" justifyContent="space-between" alignItems={"center"} width="full" marginBottom="20px"  >

                            <Flex flexDirection="row" columnGap={"2"} alignItems={"center"} >

                                <h4 style={{ fontWeight: "bolder", fontSize: "16px" }}>
                                    Custom Instructions
                                </h4>
                                <div onClick={handleToggleLayout} className="change-layout-btn" style={{ position: "relative", transform: `rotate(${layout == "row" ? 0 : 90}deg)` }} >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="black" style={{ width: "14px", height: "14px", position: "absolute", top: "-12px" }}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" style={{ width: "14px", height: "14px" }}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 4.5v15m6-15v15m-10.875 0h15.75c.621 0 1.125-.504 1.125-1.125V5.625c0-.621-.504-1.125-1.125-1.125H4.125C3.504 4.5 3 5.004 3 5.625v12.75c0 .621.504 1.125 1.125 1.125Z" />
                                    </svg>
                                </div>
                            </Flex>

                            <Flex flexDirection="row" justifyContent="space-between" alignItems={"center"} width="50%" paddingLeft="8px"  >
                                <input
                                    disabled={isLoading}
                                    type="text"
                                    id="fineTunningVersionInput"
                                    placeholder="version name"
                                    onChange={handleVersionInput}
                                    value={version}
                                    style={{ maxWidth: "250px" }} />
                                <div style={{ width: "fit-content" }}>
                                    <Checkbox
                                        name="set_as_active"
                                        isChecked={remarks}
                                        onChange={(e) => setRemarks(e.target.checked)}
                                        width=""
                                    >
                                        Apply As Active Version
                                    </Checkbox>
                                </div>
                            </Flex>
                        </Flex>
                        <Flex flexDirection={layout === "row" ? "row" : "column"} justifyContent="space-between" alignItems={"start"} width="full" gap="3"  >
                            <Flex flexDirection="column" justifyContent="start" alignItems={"start"} width="full"  >
                                <div style={{ marginBottom: "10px" }}>
                                    How can Ulysses better understand you to offer more tailored responses?
                                </div>
                                {/* onInit={(evt, editor) => editorRef.current = editor} */}
                                <Editor
                                    key={`render-editor-${layout}`}
                                    apiKey={API_KEY}
                                    onEditorChange={(text) => setAboutYouDetails(text)}
                                    value={aboutYouDetails}
                                    init={{
                                        width: "100%",
                                        height: (layout === "row" ? 450 : 350),
                                        menubar: false,
                                        plugins: [
                                            'advlist autolink lists link  charmap anchor',
                                            'searchreplace visualblocks code ',
                                            'insertdatetime paste wordcount'
                                        ],
                                        toolbar: ' formatselect | ' +
                                            'bold italic | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size: 0.8125rem; }'
                                    }}
                                />
                            </Flex>
                            <Flex flexDirection="column" justifyContent="start" alignItems={"start"} width="full"  >
                                <div style={{ marginBottom: "10px" }}>
                                    How would you like Ulysses to respond?
                                </div>
                                <Editor
                                    key={`render-editor2-${layout}`}
                                    apiKey={API_KEY}
                                    onEditorChange={(text) => setResponseStyleDetails(text)}
                                    value={responseStyleDetails}
                                    init={{
                                        width: "100%",
                                        height: (layout === "row" ? 450 : 350),
                                        menubar: false,
                                        plugins: [
                                            'advlist autolink lists link  charmap anchor',
                                            'searchreplace visualblocks code ',
                                            'insertdatetime paste wordcount'
                                        ],
                                        toolbar: ' formatselect | ' +
                                            'bold italic | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size: 0.8125rem; }'
                                    }}
                                />
                            </Flex>
                        </Flex>
                        <Flex flexDirection="row" justifyContent="space-between" alignItems={"center"} width="100%" marginTop="10px"  >
                            <span>
                                {selectedVersionId > 0 &&
                                    <Button
                                        onClick={handlePromptDelete}
                                        backgroundColor="red.400"
                                    >
                                        Delete
                                    </Button>
                                }
                            </span>

                            <Button
                                onClick={handleSubmit}
                                backgroundColor="secondary.200"
                            >
                                Save Fine Tunning
                            </Button>
                        </Flex>
                    </Flex>
                    <Flex hidden={showDeletePrompt === false} flexDirection="column" justifyContent="space-between" alignItems={"start"} width="full" minHeight={"400px"} alignSelf={"stretch"} padding="10px" style={{ borderLeft: "1px solid #EAEAEA" }}  >
                        <Flex
                            width="full"
                            color={"darkred"}
                            flexDirection={"column"}
                            fontWeight={"bold"}
                            gap={"3"}
                            marginTop={"2.5"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            flexGrow={1}
                        >
                            <h4 style={{ fontSize: "18px" }}>
                                Are you sure that you want to delete the <b style={{ color: "black" }}>{version}</b> fine tunning version ?
                            </h4>
                            <h6 style={{ fontSize: "16px" }}>
                                <b>Please not that this action cannot be undone</b>
                            </h6>
                        </Flex>
                        <Flex flexDirection="row" justifyContent="space-between" alignItems={"center"} width="100%" marginTop="10px"  >
                            <Button
                                onClick={handleCancelDelete}
                                backgroundColor="gray.400"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleDeleteSubmit}
                                backgroundColor="secondary.200"
                            >
                                Comfirm Delete
                            </Button>
                        </Flex>
                    </Flex>
                </Flex>

                {isLoading &&
                    <div className="ulysses-settings-loader">
                        <Loading height="xxs" />
                    </div>
                }
            </ModalBody>
        </VStack>
    );
};

export default UlyssesChatSettingsModal;

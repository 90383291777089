import React from "react";
import { Flex, Text } from "@chakra-ui/react";

import CardContainer from "components/Cards/CardContainer";
import formatCurrency from "utils/formatCurrency";

const Card = ({
  value,
  subtitle,
  icon,
}: {
  value?: string;
  subtitle?: string;
  icon?: React.ReactElement;
}) => (
  <CardContainer>
    <Flex alignItems="center" justifyContent="space-between">
      <Flex flexDirection="column">
        <Text
          fontFamily="heading"
          color="primary.100"
          fontSize={{ base: "xs", md: "xl", lg: "xl" }}
        >
          {formatCurrency(value)}
        </Text>

        <Text
          color="gray.400"
          fontFamily="mono"
          fontSize={{ base: "xxs", md: "xs", lg: "xs" }}
        >
          {subtitle}
        </Text>
      </Flex>

      {icon}
    </Flex>
  </CardContainer>
);

export default Card;

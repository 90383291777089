import { Flex } from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { Option, Picklist } from "react-rainbow-components";
import { PicklistOptionProps } from "react-rainbow-components/components/PicklistOption";

import { FunderTypeEnum } from "generated/graphql/v2/graphql";
import useFilter from "hooks/useFilter";
import i18n from "translations/i18n";

import { Filter } from "hooks/useFilter/types";

const options: {
  label: string;
  value?: FunderTypeEnum;
}[] = [
  {
    value: undefined,
    label: i18n.t("funder_types.all_types"),
  },
  {
    value: "CORPORATE",
    label: i18n.t(`funder_types.CORPORATE`),
  },
  {
    value: "FOUNDATION",
    label: i18n.t(`funder_types.FOUNDATION`),
  },
  {
    value: "GOVERNMENT",
    label: i18n.t(`funder_types.GOVERNMENT`),
  },
  {
    value: "ORGANIZATION",
    label: i18n.t(`funder_types.ORGANIZATION`),
  },
  {
    value: "OTHER",
    label: i18n.t(`funder_types.OTHER`),
  },
];

const FunderTypeFilter: React.FC<{ filter: Filter }> = ({ filter }) => {
  const [typeFilter, setTypeFilter] = useFilter<FunderTypeEnum>(filter);

  const [selectedType, setSelectedType] = useState<PicklistOptionProps>({
    value: {
      name: typeFilter,
      value: typeFilter,
      icon: undefined,
      label: typeFilter
        ? i18n.t(`funder_types.${typeFilter}`)
        : i18n.t("funder_types.all_types"),
    },
  });

  const handleChange = useCallback(
    (option) => {
      setTypeFilter(option?.value);

      setSelectedType({ value: option });
    },
    [setSelectedType, setTypeFilter],
  );

  return (
    <Flex maxWidth="200px">
      <Picklist value={selectedType.value} onChange={handleChange}>
        {options.map((option) => (
          <Option
            value={option.value}
            label={option.label}
            name={option.value}
            key={option.value}
          />
        ))}
      </Picklist>
    </Flex>
  );
};
export default FunderTypeFilter;

import React from "react";
import { Stack } from "@chakra-ui/react";

import Wizard from "components/Wizard";

import FormWizardFooter from "./FormWizardFooter";
import { FormWizardProps } from "./types";

const FormWizard: React.FC<FormWizardProps> = ({
  showContinueButton = true,
  showGoBackButton = true,
  showSubmitButton = true,
  continueButtonDisabled,
  goBackButtonDisabled,
  submitButtonDisabled,
  colorScheme,
  isLoading,
  onContinue,
  onSubmit,
  ...rest
}) => (
  <Stack margin={6}>
    <Wizard colorScheme={colorScheme} {...rest} />

    <FormWizardFooter
      continueButtonDisabled={continueButtonDisabled}
      goBackButtonDisabled={goBackButtonDisabled}
      submitButtonDisabled={submitButtonDisabled}
      showContinueButton={showContinueButton}
      showGoBackButton={showGoBackButton}
      showSubmitButton={showSubmitButton}
      colorScheme={colorScheme}
      isLoading={isLoading}
      onContinue={onContinue}
      onSubmit={onSubmit}
    />
  </Stack>
);

export default FormWizard;

import React from "react";

const NotFound: React.FC = () => {
  // Console error on Not Found so we can catch these errors in the integration test,
  // since SPAs do not return HTTP status code 404
  // eslint-disable-next-line no-console
  console.error("Not found");

  return <p>Not Found</p>;
};

export default NotFound;

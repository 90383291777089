import React from "react";
import { Flex, Stack, Text } from "@chakra-ui/react";

import { CardHeaderProps } from "./types";

const CardHeader: React.FC<CardHeaderProps> = ({
  titleLabel,
  title,
  children,
}) => (
  <Flex alignItems="center" justifyContent="space-between" marginBottom={4}>
    <Flex alignItems="center" width="full">
      <Text fontSize={{ base: "xxs", md: "xs", lg: "xs" }} fontFamily="heading">
        {title}
      </Text>

      {titleLabel && (
        <Text
          color="white"
          fontSize="xxs"
          fontFamily=""
          background="status.success"
          borderRadius="sm"
          textAlign="center"
          marginLeft={4}
          paddingX={1}
        >
          {titleLabel}
        </Text>
      )}
    </Flex>

    {children && (
      <Stack justify="flex-end" align="center" width="full" isInline>
        {children}
      </Stack>
    )}
  </Flex>
);

export default CardHeader;

import React from "react";
import { useTranslation } from "react-i18next";
import { Picklist, PicklistOption as Option } from "react-rainbow-components";
import { PicklistProps } from "react-rainbow-components/components/Picklist";

export const SelectDeadlineType: React.FC<
  Pick<PicklistProps, "error" | "required">
> = (props) => {
  const [t] = useTranslation();

  return (
    <Picklist
      label={t("create_grant.steps.deadlines.application_type")}
      placeholder={t("create_grant.steps.deadlines.select_a_type")}
      labelAlignment="left"
      {...props}
    >
      <Option name={"REQUIRED"} value="REQUIRED" label={t("deadlines.hard")} />
      <Option name={"ROLLING"} value="ROLLING" label={t("deadlines.rolling")} />
    </Picklist>
  );
};
